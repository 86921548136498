<template>
  <BoxPane :title="title" class="table-item">
    <div class="table-wrap" ref="tableWrap">
      <div class="t-head">
        <span v-for="(item, idx) in tHead" :key="idx">{{ item }}</span>
      </div>
      <div class="list-loop" ref="loopBox">
        <ul v-if="data.length" ref="roopUl" class="loop-list">
          <li v-for="(item, idx) in data" :key="item.value + idx">
            <span class="device-name">{{ item.deviceName }}</span>
            <span class="check-val" :style="valueStyle">{{ item.value }}</span>
            <span v-if="tHead.length > 2" class="time">{{ item.time }}</span>
          </li>
        </ul>
        <p class="no-data" v-else>暂无数据</p>
      </div>
    </div>
  </BoxPane>
</template>
<script>
import BoxPane from "./boxPane.vue";

export default {
  name: "MonitorTable",
  components: { BoxPane },
  props: {
    title: String,
    tHeight: Number,
    tHead: {
      typeof: Array,
      default() {
        return [];
      },
    },
    data: {
      typeof: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      timer: null,
      loopBoxH:0,
      loopListH:0
    };
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      setTimeout(function (a) {
        that.doLoop();
      }, 1000)
    });
  },
  computed: {
    valueStyle() {
      if(this.tHead.length == 2) {
        return "width: 140%; font-size: 12px; text-align: left;";
      } else {
        return "width: 32.5%; font-size: 16px;";
      }
    }
  },
  watch: {
    tHeight() {
      //监听表格高度变化，判断是否需要轮播数据
      this.doLoop();
    }
  },
  methods: {
    doLoop() {
      //表单存在数据才执行动作
      if(this.data && this.data.length){
        this.loopBoxH = this.$refs.loopBox == null? 0 : this.$refs.loopBox.clientHeight;
        this.loopListH = this.$refs.roopUl.clientHeight == null? 0 : this.$refs.roopUl.clientHeight;
        if (this.loopBoxH < this.loopListH - 10) {
          //留10的量
          this.clearLoop();
          this.timer = setInterval(() => {
            this.handelMove();
          }, 120);
        } else {
          this.clearLoop();
        }
      }
    },
    handelMove() {
      const ref = this.$refs["roopUl"];
      const top = Math.abs(parseInt(ref["style"]["top"] || 0));
      if (top >= this.loopListH-3*this.loopBoxH/4) {
        ref["style"]["top"] = 0;
      } else {
        ref["style"]["top"] = `${parseInt(ref["style"]["top"] || 0) - 1}px`;
      }
    },
    clearLoop() {
      clearInterval(this.timer);
      this.timer = null;
      const ref = this.$refs["roopUl"];
      ref && (ref["style"]["top"] = 0);
    },
  },

  beforeDestroy() {
    this.clearLoop();
  },
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.table-item {
  width: 32.5%;
}
.table-wrap {
  // height: 240px;
  height: 100%;
  background: rgba(0, 47, 132, 0.8);
  padding: 0 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  .t-head {
    height: 40px;
    line-height: 40px;
    background: #3cabea;
    font-size: 20px;
    padding: 0 20px;
  }
  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 40px;
    width: 30%;
    text-align: left;
    &:first-child {
      width: 38%;
    }
    &:last-child {
      width: 32%;
      text-align: right;
    }
  }
}
.list-loop {
  height: calc(100% - 40px);
  overflow: hidden;
  font-size: 16px;
  padding: 0 20px;
  position: relative;
  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  li {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 0 20px;
    &:nth-child(even) {
      background-color: #3c8dfd;
    }
  }
  .no-data {
    line-height: 120px;
  }
}
</style>