<template>
  <div id="jrxChartView" style="height: 100%; width: 100%" ref="ctnRef"></div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      tdgaocheng: '0', //滩顶高程
      duanmianName: '',
      realMaxMs: 0,     // 真实数据中最大的埋深
      minDiagramMs: 30, // 示意图上高程最小的埋深，用于比例参考，初始值位示意图中的30
      diagramBarLvWidth: 15, // 示意图上坝体平面的宽度
      diagramKongWidth: 10,  // 示意图上孔的宽度
      diagramKongHeightZoom: 1.5, // 示意图上孔高放大倍数

      kushuiwei: 0,
      deviceData: [], // 设备监测数据
      shenyaChart: null,
      shuimianPointData: [],   // 库水区多边形点位
      bartiPointData: [],  // 大坝多边形点位
      devicePointData: [],  // 设备点位
      jrxPointData: [],     // 浸润线点位
      blueAlarmPointData: [], //蓝色预警点位
      yellowAlarmPointData: [], //黄色预警点位
      orangeAlarmPointData: [], //橙色预警点位
      redAlarmPointData: [], //红色预警点位
    };
  },
  created () {

  },
  methods: {
    async loadData (projectCode, duanmianCode, duanmianName) {
      this.duanmianName = duanmianName;
      let requestMerge = new Array();
      let r1 = this.$http.get("device/shenya/show/duanmian/diagram", {
        params: {
          projectCode: projectCode,
          duanmianCode: duanmianCode,
        }
      });
      let r2 = this.$http.get("device/shenya/find/rg/data/duanmian", {
        params: {
          projectCode: projectCode,
          duanmianCode: duanmianCode,
        }
      });
      let r3 = this.$http.get("device/find/shuiwei", {
        params: {
          projectCode: projectCode,
        },
      });
      requestMerge.push(r1);
      requestMerge.push(r2);
      requestMerge.push(r3);
      let that = this;
      Promise.all(requestMerge).then(responseList => {
        let rs1 = responseList[0];
        let rs2 = responseList[1];
        let rs3 = responseList[2];
        if (rs3.data.code == 0) {
          that.tdgaocheng = rs3.data.data.tdcg;
        }
        if (rs1.data.code == 0) {
          that.kushuiwei = rs1.data.data.kushuiwei;
          that.dealData(rs1.data.data.devices, rs2.data.data);
        } else {
          that.$message.error(rs1.data.msg);
        }
      });
    },
    dealData (data, rgData) {
      this.shuimianPointData = [];
      this.devicePointData = [];
      this.jrxPointData = [];
      this.blueAlarmPointData = [];
      this.yellowAlarmPointData = [];
      this.orangeAlarmPointData = [];
      this.redAlarmPointData = [];
      this.bartiPointData = [];
      this.realMaxMs = 0;
      this.minDiagramMs = 30;

      // 设备倒序
      this.deviceData = [];
      for (let index = data.length - 1; index >= 0; index--) {
        this.deviceData.push(data[index]);
      }
      // 计算示意图有几个坝体，最小为3个，为了美观
      var barCount = data.length < 3 ? 3 : data.length;
      var wi = 630 / barCount;        // 设备x轴间隔
      var hi = 28 / (barCount + 1);   // 坝面y轴间隔

      // 补充大坝体动态生成的坝体
      var bartiTemp = [[0, 0], [1500, 15], [1500, 25], [850, 35], [800, 35]];
      for (let index = 0; index < barCount; index++) {
        var len = 800 - (index + 1) * wi;
        var h = 35 - (index + 1) * hi;
        bartiTemp.push([len, h]);
        bartiTemp.push([len - this.diagramBarLvWidth, h]);
        // 保存设备点
        this.devicePointData.push([len, h]);

        // 找出最小的示意图高
        if (h < this.minDiagramMs) {
          this.minDiagramMs = h;
        }
      }
      // 补上最后一个点
      bartiTemp.push([40, 5]);
      this.bartiPointData = bartiTemp;

      //  水面的点集
      this.shuimianPointData.push([1180, 30], [1500, 25], [1500, 30]);

      // 找出最小的埋深
      data.forEach(d => {
        var l = this.common.floatFormat(d.maishen, 2);
        if (l > this.realMaxMs) {
          this.realMaxMs = l;
        }
      });
      
      if (data != null && data.length > 0) {
        this.drawDiagram();
      } else {
        return;
      }
    },
    drawDiagram () {
      let that = this;
      if (this.shenyaChart == null) {
        this.shenyaChart = this.$echarts.init(document.getElementById("jrxChartView"));
      }

      var option = {
        title: {
          text: this.duanmianName
        },
        grid: {
          left: 0,
          top: 30,
          right: 0,
          bottom: 0,
        },
        dataZoom: [
          {
            type: 'inside',
            filterMode: 'none'
          }
        ],
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var info = '';
            params.forEach(p => {
              if (p.seriesName === 'device') {
                that.deviceData.forEach(d => {
                  if (d.deviceCode === p.seriesId) {
                    info = '孔顶高程：' + d.kdgaoceng + '米<br/>埋深：' + d.maishen + '米<br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(48, 49, 51);"></span>空管距离：' + that.getDeviceCurrentValue(d) + '米<br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(25, 165, 255);"></span><span style="color:rgb(25, 165, 255);">蓝色预警：' + that.haveAlarmConfig(d.alarms, 'blue') + '</span><br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 184, 36);"></span><span style="color:rgb(255, 184, 36);">黄色预警：' + that.haveAlarmConfig(d.alarms, 'yellow') + '</span><br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 112, 65);"></span><span style="color:rgb(255, 112, 65);">橙色预警：' + that.haveAlarmConfig(d.alarms, 'orange') + '</span><br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 77, 107);"></span><span style="color:rgb(255, 77, 107);">红色预警：' + that.haveAlarmConfig(d.alarms, 'red') + '</span><br/>' +
                      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(140, 197, 255);"></span>水柱：' + that.common.floatFormat((d.maishen - that.getDeviceCurrentValue(d)), 2) + '米<br/>';
                  }
                });
              }
            });
            return info;
          }
        },
        legend: {
          data: [{
            name: '浸润线',
            itemStyle: {
              color: 'rgb(140, 197, 255)',
              borderColor: 'rgb(140, 197, 255)'
            },
            lineStyle: {
              color: 'rgb(140, 197, 255)'
            }
          },
          {
            name: '蓝色预警',
            itemStyle: {
              color: 'rgb(25, 165, 255)',
              borderColor: 'rgb(25, 165, 255)'
            },
            lineStyle: {
              color: 'rgb(25, 165, 255)'
            }
          },
          {
            name: '黄色预警',
            itemStyle: {
              color: 'rgb(255, 184, 36)',
              borderColor: 'rgb(255, 184, 36)'
            },
            lineStyle: {
              color: 'rgb(255, 184, 36)'
            }
          },
          {
            name: '橙色预警',
            itemStyle: {
              color: 'rgb(255, 112, 65)',
              borderColor: 'rgb(255, 112, 65)'
            },
            lineStyle: {
              color: 'rgb(255, 112, 65)'
            }
          },
          {
            name: '红色预警',
            itemStyle: {
              color: 'rgb(255, 77, 107)',
              borderColor: 'rgb(255, 77, 107)'
            },
            lineStyle: {
              color: 'rgb(255, 77, 107)'
            }
          }]
        },
        xAxis: {
          max: 1500,
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          max: 60,
          axisLabel: {
            show: false
          }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        graphic: [],
        series: [
          {
            id: 'shuimian',
            name: 'shuimian',
            type: 'custom',
            renderItem: function (params, api) {
              if (params.context.rendered) {
                return;
              }
              params.context.rendered = true;
              let points = [];
              for (let i = 0; i < that.shuimianPointData.length; i++) {
                points.push(api.coord(that.shuimianPointData[i]));
              }
              let color = api.visual('color');
              return {
                type: 'polygon',
                transition: ['shape'],
                shape: {
                  points: points
                },
                style: api.style({
                  fill: color,
                  stroke: that.$echarts.color.lift(color, 0.1)
                })
              };
            },
            clip: false,
            data: that.shuimianPointData,
            itemStyle: {
              color: 'rgb(140, 197, 255)'
            },
            label: {
              show: true,
              position: 'outside',
              formatter: function (param) {
                return '库水位：'+ that.kushuiwei +'米';
              }
            },
            tooltip: {
              formatter: function (param) {
                return '';
              }
            }
          },
          {
            id: 'barti',
            name: 'barti',
            type: 'custom',
            z: 1,
            renderItem: function (params, api) {
              if (params.context.rendered) {
                return;
              }
              params.context.rendered = true;
              let points = [];
              for (let i = 0; i < that.bartiPointData.length; i++) {
                points.push(api.coord(that.bartiPointData[i]));
              }
              let color = api.visual('color');
              return {
                type: 'polygon',
                transition: ['shape'],
                shape: {
                  points: points
                },
                style: api.style({
                  fill: color,
                  stroke: that.$echarts.color.lift(color, 0.1)
                })
              };
            },
            data: that.bartiPointData,
            itemStyle: {
              color: 'rgb(145, 102, 64)'
            },
            label: {
              show: true,
              position: 'outside',
              offset: [100, 0],
              formatter: function (param) {
                return '滩顶高程：' + that.tdgaocheng + '米';
              }
            },
            tooltip: {
              formatter: function (param) {
                return '';
              }
            }
          },
        ]
      };

      // 开始构建设备孔
      this.devicePointData.forEach((e, i) => {
        var dev = that.deviceData[i];
        if (dev == null) {
          return;
        }
        // 蓝色预警
        var blh = that.alarmDiagramSize(dev.alarms, dev.mainshen, 'blue');
        if (blh > 0) {
          this.blueAlarmPointData.push([e[0] - (this.diagramKongWidth / 2), e[1] - blh]);
        }
        // 黄色预警
        var ylh = that.alarmDiagramSize(dev.alarms, dev.mainshen, 'yellow');
        if (ylh > 0) {
          this.yellowAlarmPointData.push([e[0] - (this.diagramKongWidth / 2), e[1] - ylh]);
        }
        // 橙色预警
        var orh = that.alarmDiagramSize(dev.alarms, dev.mainshen, 'orange');
        if (orh > 0) {
          this.orangeAlarmPointData.push([e[0] - (this.diagramKongWidth / 2), e[1] - orh]);
        }
        // 红色预警
        var rdh = that.alarmDiagramSize(dev.alarms, dev.mainshen, 'red');
        if (rdh > 0) {
          this.redAlarmPointData.push([e[0] - (this.diagramKongWidth / 2), e[1] - rdh]);
        }

        // 示意图上的空管距离长度
        var kgh = that.realSize2DiagramSize(that.getDeviceCurrentValue(dev));
        // 构成空管的点数组
        var p = [[e[0], e[1]], [e[0], e[1] - kgh], [e[0] - this.diagramKongWidth, e[1] - kgh], [e[0] - this.diagramKongWidth, e[1]]];
        option.series.push({
          id: dev.deviceCode,
          name: 'device',
          type: 'custom',
          z: 10,
          renderItem: function (params, api) {
            if (params.context.rendered) {
              return;
            }
            params.context.rendered = true;
            let points = [];
            for (let i = 0; i < p.length; i++) {
              points.push(api.coord(p[i]));
            }
            let color = api.visual('color');
            return {
              type: 'polygon',
              transition: ['shape'],
              shape: {
                points: points
              },
              style: api.style({
                fill: color,
                stroke: that.$echarts.color.lift(color, 0.1)
              })
            };
          },
          data: p,
          itemStyle: {
            color: that.kongguanColor(dev.alarms, that.getDeviceCurrentValue(dev))//'rgb(48, 49, 51)'
          },
          label: {
            show: true,
            align: 'center',
            fontSize: 12,
            position: 'outside',
            formatter: function (param) {
              return dev.name;// + '\n孔顶高程：' + dev.kdgaoceng + '米\n空管距离：' + dev.currentValue + '米';
            }
          },
        });
      });


      // 构建水柱图
      this.devicePointData.forEach((e, i) => {
        var dev = that.deviceData[i];
        if (dev == null) {
          return;
        }
        // 空管距离
        var kh = that.realSize2DiagramSize(that.getDeviceCurrentValue(dev));
        // 水柱高
        var sh = that.realSize2DiagramSize(that.common.floatFormat(dev.maishen, 2) - that.common.floatFormat(that.getDeviceCurrentValue(dev), 2));
        // 构成水柱的点数组
        var p = [[e[0], e[1] - kh], [e[0], e[1] - kh - sh], [e[0] - this.diagramKongWidth, e[1] - kh - sh], [e[0] - this.diagramKongWidth, e[1] - kh]];

        // 收集浸润线点位
        this.jrxPointData.push([e[0] - (this.diagramKongWidth / 2), e[1] - kh]);
        option.series.push({
          name: 'shuizhu',
          type: 'custom',
          z: 10,
          renderItem: function (params, api) {
            if (params.context.rendered) {
              return;
            }
            params.context.rendered = true;
            let points = [];
            for (let i = 0; i < p.length; i++) {
              points.push(api.coord(p[i]));
            }
            let color = api.visual('color');
            return {
              type: 'polygon',
              transition: ['shape'],
              shape: {
                points: points
              },
              style: api.style({
                fill: color,
                stroke: that.$echarts.color.lift(color, 0.1)
              })
            };
          },
          data: p,
          itemStyle: {
            color: 'rgb(140, 197, 255)'
          },
          // label: {
          //   show: true,
          //   align: 'left',
          //   fontSize: 10,
          //   position: 'outside',
          //   formatter: function (param) {
          //     return '水位：' + (dev.kdgaoceng - dev.currentValue) + '米';
          //   }
          // },
        });
      });

      // 画浸润线
      option.series.push({
        name: '浸润线',
        type: 'line',
        z: 11,
        data: this.jrxPointData,
        smooth: true,
        lineStyle: {
          color: 'rgb(140, 197, 255)',
          opacity: 1,
          width: 1,
          type: 'dashed'
        }
      });

      // 画预警线
      option.series.push({
        name: '蓝色预警',
        type: 'line',
        z: 11,
        data: this.blueAlarmPointData,
        smooth: true,
        lineStyle: {
          color: 'rgb(25, 165, 255)',
          opacity: 1,
          width: 1,
          type: 'dashed'
        }
      });
      option.series.push({
        name: '黄色预警',
        type: 'line',
        z: 11,
        data: this.yellowAlarmPointData,
        smooth: true,
        lineStyle: {
          color: 'rgb(255, 184, 36)',
          opacity: 1,
          width: 1,
          type: 'dashed'
        }
      });
      option.series.push({
        name: '橙色预警',
        type: 'line',
        z: 11,
        data: this.orangeAlarmPointData,
        smooth: true,
        lineStyle: {
          color: 'rgb(255, 112, 65)',
          opacity: 1,
          width: 1,
          type: 'dashed'
        }
      });
      option.series.push({
        name: '红色预警',
        type: 'line',
        z: 11,
        data: this.redAlarmPointData,
        smooth: true,
        lineStyle: {
          color: 'rgb(255, 77, 107)',
          opacity: 1,
          width: 1,
          type: 'dashed'
        }
      });

      this.shenyaChart.clear();
      this.shenyaChart.setOption(option);
    },
    // 将实际距离转为示意图距离
    realSize2DiagramSize(realSize) {
      return (this.common.floatFormat(realSize, 2) * this.minDiagramMs) / (this.realMaxMs * this.diagramKongHeightZoom);
    },
    // 将预警值转为示意图上的距离
    alarmDiagramSize(alarms, mainshen, type) {
      // 默认为埋深
      var result = 0;
      if (type === 'blue') {
        if (alarms != null && alarms.blueAlarmValue != null) {
          result = this.realSize2DiagramSize(alarms.blueAlarmValue);
        }
      } else if (type === 'yellow') {
        if (alarms != null && alarms.yellowAlarmValue != null) {
          result = this.realSize2DiagramSize(alarms.yellowAlarmValue);
        }
      } else if (type === 'orange') {
        if (alarms != null && alarms.orangeAlarmValue != null) {
          result = this.realSize2DiagramSize(alarms.orangeAlarmValue);
        }
      } else if (type === 'red') {
        if (alarms != null && alarms.redAlarmValue != null) {
          result = this.realSize2DiagramSize(alarms.redAlarmValue);
        }
      }

      return result;
    },
    haveAlarmConfig(alarms, type) {
      var result = '未设置';
      if (type === 'blue') {
        if (alarms != null && alarms.blueAlarmValue != null) {
          result = alarms.blueAlarmValue + '米';
        }
      } else if (type === 'yellow') {
        if (alarms != null && alarms.yellowAlarmValue != null) {
          result = alarms.yellowAlarmValue + '米';
        }
      } else if (type === 'orange') {
        if (alarms != null && alarms.orangeAlarmValue != null) {
          result = alarms.orangeAlarmValue + '米';
        }
      } else if (type === 'red') {
        if (alarms != null && alarms.redAlarmValue != null) {
          result = alarms.redAlarmValue + '米';
        }
      }

      return result;
    },
    // 获取空管的颜色
    kongguanColor(alarms, kgvalue) {
      let cl = "rgb(48, 49, 51)";
      if (alarms != null && alarms.redAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.redAlarmValue, 2)) {
        // 红色预警
        cl = "rgb(255, 77, 107)";
      } else if (alarms != null && alarms.orangeAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.orangeAlarmValue, 2)) {
        // 橙色预警
        cl = "rgb(255, 112, 65)";
      } else if (alarms != null && alarms.yellowAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.yellowAlarmValue, 2)) {
        // 黄色预警
        cl = "rgb(255, 184, 36)";
      } else if (alarms != null && alarms.blueAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.blueAlarmValue, 2)) {
        // 蓝色预警
        cl = "rgb(25, 165, 255)";
      }

      return cl;
    },
    // 获取设备的最新值，如果小于0则取埋深
    getDeviceCurrentValue(dev) {
      var cv = this.common.floatFormat(dev.currentValue, 2);
      if (cv <= 0) {
        return dev.maishen;
      } else {
        return dev.currentValue;
      }
    }
  },
};
</script>

<style lang="less" scoped>
</style>