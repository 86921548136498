<template>
  <section class="minitor-wrap" ref="mniWrapRef">
    <div
      ref="scaleWrap"
      class="scale-wrap"
      :style="{transform:'scale('+scaleValue+')'}"
    >
      <TopBar v-model="currentTab" @change="handleTabChange" :projectInfo="projectInfo"/>
      <div class="selector">
        <el-select
          v-model="deviceChecked"
          multiple
          :popper-append-to-body="false"
          @change="deviceChange"
        >
          <el-option
            v-for="item in deviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="select-placeholder">在线设备</div>
        <span class="icon" v-if="!drawer" @click="drawer = true">
          <SvgIcon iconClass="icon_display"></SvgIcon>
        </span>
        <span class="icon" v-else @click="drawer = false">
          <SvgIcon iconClass="icon_hide"></SvgIcon>
        </span>
        <span v-if="showLabel" style="color: white;text-decoration:line-through;" class="icon" @click="hideMarkerLabel">
          标签
        </span>
        <span v-else style="color: white;" class="icon" @click="showMarkerLabel">
          标签
        </span>
        <span
          v-if="fullscreen"
          class="icon unfull-screen"
          @click="handleFullScreen"
        ></span>
        <span v-else class="icon full-screen" @click="handleFullScreen"></span>
      </div>
      <div id="map" :style="{height:`${screenHeight/scaleValue}px`,minHeight:'100vh'}" class="map">
        <v-map-diagram 
          :canEdit="false" 
          :showController="false" 
          @onMarkerClick="onMarkerClick" 
          @onLoadDeviceType="loadMapDeviceType" 
          ref="mapDiagramRef"></v-map-diagram>
      </div>
      <el-drawer
        class="left-drawer"
        :visible.sync="drawer"
        :modal="false"
        :withHeader="false"
        direction="ltr"
        size="100%"
      >
        <div class="module">
          <StatusModule :data="healthData" @click.native="showHealthInfo"/>
          <EarlyWarning :data="alarmStatistics" @on-click="handleClickData" />
          <!-- <RainModule
            :data="yuliangDatas"
            @click.native="showRainInfo"
          /> -->
          <RainModule
            :data="pieConfig"
            @showPieDetail="showErrorDeviceDetail"
          />
        </div>
      </el-drawer>
      <el-drawer
        class="right-drawer"
        :visible.sync="drawer"
        :modal="false"
        :withHeader="false"
        direction="rtl"
        size="100%"
      >
        <div class="module">
          <ProjectModule :data="projectSummary" @click.native="showHistoryData"/>
          <!-- <DeviceModule :data="pieConfig" @showPieDetail="showErrorDeviceDetail"/> -->
          <DeviceModule @showYlDetail="showRainInfo" ref="ylMntRef"/>
        </div>
      </el-drawer>
      <el-drawer
        class="bottom-drawer"
        :visible.sync="drawer"
        :modal="false"
        :withHeader="false"
        direction="btt"
        size="100%"
        ref="bottomDrawer"
        v-if="bottomDrawerH>40"
        :style="{bottom:-drawerBottom+'px',height:bottomDrawerH+'px'}"
      >
        <div class="tables">
          <MonitorTable v-has="'sys:mtr:shenya'"
            title="浸润线监测"
            :tHead="['设备名', '结果值', '监测时间']"
            :data="soakCheck"
            :tHeight="bottomDrawerH"
            ref="table1"
            @click.native="showShenyaRealTime"
          />
          <MonitorTable v-has="'sys:mtr:cexie'"
            title="内部位移监测"
            :tHead="['设备名', '结果值(毫米)', '监测时间']"
            :data="insideCheck"
            :tHeight="bottomDrawerH"
            ref="table2"
            @click.native="showCexieRealTime"
          />
          <MonitorTable v-has="'sys:mtr:quanzhan,sys:mtr:gnss'"
            title="表面位移监测"
            :tHead="['设备名', '结果值(毫米)']"
            :data="surfaceCheck"
            :tHeight="bottomDrawerH"
            ref="table3"
            @click.native="showQuanzhanRealTime"
          />
        </div>
      </el-drawer>
      <TabPopup
        :visible="showPopup"
        :title="popupTitle"
        @close="(currentTab = ''), (showPopup = false)"
      >
        <div style="height: 500px" v-if="currentTab === 'compony'">
          公司简介
        </div>
        <div v-else-if="currentTab === 'organize'">组织架构</div>
        <div v-else-if="currentTab === 'history'">库区历史</div>
        <div v-else>系统设置</div>
      </TabPopup>
      <TabPopup
        v-if="showDeviceDetail"
        :visible="showDeviceDetail"
        title="设备信息"
        @close="showDeviceDetail = false"
      >
        <div style="height: 400px;">
          <v-device-main-info :data="deviceClickInfo"></v-device-main-info>
        </div>
      </TabPopup>
      <TabPopup
        v-if="showCameraPlay"
        :visible="showCameraPlay"
        title="摄像头预览"
        @close="showCameraPlay = false"
        :width="'815px'"
      >
        <div style="height: 480px;">
          <v-video-main :data="currentShowCamera"></v-video-main>
        </div>
      </TabPopup>


      <!-- 预警信息 -->
      <TabPopup
        v-if="showAlarmInfoDialog"
        :visible="showAlarmInfoDialog"
        title="预警列表"
        @close="showAlarmInfoDialog = false"
        :width="'70%'"
      >
        <el-card>
          <div style="height: 600px; overflow-y: auto;overflow-x: hidden;">
            <v-ds-alarm-list :projectInfo="projectInfo" :searchType="alarmSearchType"></v-ds-alarm-list>
          </div>
        </el-card>
      </TabPopup>


      <!-- 雨量详情 -->
      <TabPopup
        v-if="showYuliangInfoDialog"
        :visible="showYuliangInfoDialog"
        title="降雨量监测"
        @close="showYuliangInfoDialog = false"
        :width="'70%'"
      >
        <div style="height: 600px;">
          <v-yl-main :projectInfo="projectInfo"></v-yl-main>
        </div>
      </TabPopup>



      <!-- 实时监测汇总 -->
      <TabPopup
        v-if="showProjectRealTimeDialog"
        :visible="showProjectRealTimeDialog"
        title="实时监测"
        @close="showProjectRealTimeDialog = false"
        :width="'80%'"
      >
        <div style="height: 700px;">
          <v-project-real-time-data :projectInfo="projectInfo"></v-project-real-time-data>
        </div>
      </TabPopup>


      <!-- 实时监测汇总-浸润线 -->
      <el-dialog
        title="浸润线监测"
        :visible.sync="showShenyaMntDialog"
        v-if="showShenyaMntDialog"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showShenyaMntDialog = false">返回数据大屏</el-button>
        <v-shenya-mnt :data="projectInfo"></v-shenya-mnt>
      </el-dialog>
      <TabPopup
        v-if="showShenyaRealTimeDialog"
        :visible="showShenyaRealTimeDialog"
        title="浸润线监测"
        @close="showShenyaRealTimeDialog = false"
        :width="'80%'"
      >
        <el-card>
          <div style="height: 600px; overflow: auto;">
            <v-shenya-mt :data="projectInfo"></v-shenya-mt>
          </div>
        </el-card>
      </TabPopup>

      <!-- 实时监测汇总-测斜仪 -->
      <el-dialog
        title="内部位移监测"
        :visible.sync="showCexieMntDialog"
        v-if="showCexieMntDialog"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showCexieMntDialog = false">返回数据大屏</el-button>
        <v-cexie-mnt :data="projectInfo"></v-cexie-mnt>
      </el-dialog>
      <TabPopup
        v-if="showCexieRealTimeDialog"
        :visible="showCexieRealTimeDialog"
        title="内部位移监测"
        @close="showCexieRealTimeDialog = false"
        :width="'80%'"
      >
        <el-card>
          <div style="height: 600px; overflow: auto;">
            <v-cexie-mt :data="projectInfo"></v-cexie-mt>
          </div>
        </el-card>
      </TabPopup>


      <!-- 实时监测汇总-雨量计 -->
      <TabPopup
        v-if="showYuliangRealTimeDialog"
        :visible="showYuliangRealTimeDialog"
        title="雨量监测"
        @close="showYuliangRealTimeDialog = false"
        :width="'70%'"
      >
        <el-card>
          <div style="height: 500px; overflow: auto;">
            <v-yuliang-mt :data="projectInfo"></v-yuliang-mt>
          </div>
        </el-card>
      </TabPopup>


      <!-- 实时监测汇总-水位计 -->
      <TabPopup
        v-if="showShuiweiRealTimeDialog"
        :visible="showShuiweiRealTimeDialog"
        title="水位监测"
        @close="showShuiweiRealTimeDialog = false"
        :width="'70%'"
      >
        <el-card>
          <div style="height: 500px; overflow: auto;">
            <v-shuiwei-mt :data="projectInfo"></v-shuiwei-mt>
          </div>
        </el-card>
      </TabPopup>


      <!-- 实时监测汇总-浊度仪 -->
      <TabPopup
        v-if="showZhuoduRealTimeDialog"
        :visible="showZhuoduRealTimeDialog"
        title="浑浊度监测"
        @close="showZhuoduRealTimeDialog = false"
        :width="'70%'"
      >
        <el-card>
          <div style="height: 500px; overflow: auto;">
            <v-zhuodu-mt :data="projectInfo"></v-zhuodu-mt>
          </div>
        </el-card>
      </TabPopup>


      <!-- 实时监测汇总-全站仪 -->
      <el-dialog
        title="表面位移监测"
        :visible.sync="showQzMntDialog"
        v-if="showQzMntDialog"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showQzMntDialog = false">返回数据大屏</el-button>
        <v-quanzhan-mnt :data="projectInfo"></v-quanzhan-mnt>
      </el-dialog>
      <TabPopup
        v-if="showQzRealTimeDialog"
        :visible="showQzRealTimeDialog"
        title="表面位移监测"
        @close="showQzRealTimeDialog = false"
        :width="'80%'"
      >
        <el-card>
          <div style="height: 600px; overflow: auto;">
            <v-quanzhan-mt :data="projectInfo"></v-quanzhan-mt>
          </div>
        </el-card>
      </TabPopup>
    </div>
    <v-ws :clientTypeParam="3"></v-ws>
    <audio id="alarmAudio" src="@/assets/alarm.wav" muted="muted" />
  </section>
</template>
<script>
import bus from "../../common/bus";
import vWs from "../../common/webSocket.vue";
import TopBar from "./topBar.vue";
import TabPopup from "./TabPopup.vue";
import MonitorTable from "./monitorTable.vue";
import vMapDiagram from "../project/MapDiagram.vue";
import vDeviceMainInfo from "./device/DeviceMainInfo.vue";
import vDsAlarmList from "./DsAlarmList.vue";
import vYlMain from "./yuliang/YlMain.vue";
import vProjectRealTimeData from "./ProjectRealTimeData.vue";
import vYuliangMt from './projectRealTime/YuliangMt.vue';
import vShuiweiMt from './projectRealTime/ShuiweiMt.vue';
import vZhuoduMt from './projectRealTime/ZhuoduMt.vue';
import vShenyaMnt from './report/ShenyaMnt.vue';
import vCexieMnt from './report/CexieMnt.vue';
import vQuanzhanMnt from './report/QuanzhanMnt.vue';
import vShenyaMt from './projectRealTime/ShenyaMt.vue';
import vCexieMt from './projectRealTime/CexieMt.vue';
import vQuanzhanMt from './projectRealTime/QuanzhanMt.vue';
import vVideoMain from "../video/VideoMain.vue";
import {
  StatusModule,
  EarlyWarning,
  RainModule,
  ProjectModule,
  DeviceModule,
} from "./modules";
var mainTimer;
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Monitor",
  components: {
    TopBar,
    TabPopup,
    MonitorTable,
    StatusModule,
    EarlyWarning,
    RainModule,
    ProjectModule,
    DeviceModule,
    vMapDiagram,
    vWs,
    vDeviceMainInfo,
    vDsAlarmList,
    vYlMain,
    vProjectRealTimeData,
    vYuliangMt,
    vShuiweiMt,
    vZhuoduMt,
    vShenyaMnt,
    vCexieMnt,
    vQuanzhanMnt,
    vShenyaMt,
    vCexieMt,
    vQuanzhanMt,
    vVideoMain
  },
  data() {
    return {
      canEdit: false,
      showPopup: false,
      showDeviceDetail: false,
      showCameraPlay: false,
      showAlarmInfoDialog: false,
      showYuliangInfoDialog: false,
      showProjectRealTimeDialog: false,
      showShenyaRealTimeDialog: false,
      showCexieRealTimeDialog: false,
      showYuliangRealTimeDialog: false,
      showShuiweiRealTimeDialog: false,
      showZhuoduRealTimeDialog: false,
      showQzRealTimeDialog: false,
      showShenyaMntDialog: false,
      showCexieMntDialog: false,
      showQzMntDialog: false,
      drawer: true,
      fullscreen: false,
      showLabel: true,
      screenWidth: 0, //屏幕宽度
      screenHeight: 0, //屏幕高度
      scaleValue:1,//缩放比例
      drawerBottom:0,
      bottomDrawerH:310,
      popupTitle: "",
      currentTab: "", //导航菜单被选中的项:compony | organize | history setting
      deviceChecked: [], //选择设备
      healthData: {
        systemValue: 0, // 系统状态分数
        projectValue: 0,// 项目状态分数
      },
      alarmStatistics: {
        undealTotal: 0,
        tdUndealTotal: 0,
        blueTotal: 0,
        yellowTotal: 0,
        orangeTotal: 0,
        redTotal: 0,
      },
      deviceOptions: [],
      soakCheck: [],
      insideCheck: [],
      surfaceCheck: [],
      projectSummary: {
        projectName: "",
        quanzhanAlarm: 0,
        cexieAlarm: 0,
        shenyaAlarm: 0,
        ylNumDay: "0",
        ylNum: "0",
        gantan: "0",
        gantanH: "0",
        gantanL: "0",
        shuiwei: "0",
        shuiweiH: "0",
        shuiweiL: "0",
        chaogao: "0",
        chaogaoH: "0",
        chaogaoL: "0",
        pb: "0",
        pb1: "0",
        pb2: "0",
      },
      pieConfig: {},
      projectInfo: {},
      deviceClickInfo: {},
      alarmSearchType: 0, // 预警的检索类型
      currentShowCamera: {}
    };
  },
  created() {
    this.bottomPanelWidth();
    this.loadData();
    // 1分钟更新数据
    mainTimer = setInterval(() => {
      this.loadData();
    }, 60000);
    this.onAlarmMsg ();
  },
  mounted() {
    this.showPopup = !!this.currentTab;
    this.screenWidth = document.body.clientWidth;
    this.screenHeight= window.innerHeight;
    this.resetBottomDrawer()
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight= window.innerHeight;
        this.resetBottomDrawer()
      })();
    };
  },
  watch:{
    screenWidth(value){
      this.scaleValue = value/1920
    }
  },
  methods: {
    loadData() {
      this.loadProjectDiagramConfig();
      this.loadHealthStatus();
      this.getAlarmStatis();
      this.getProjectSummary();
      this.loadJinrunxianData ();
      this.loadCexieData ();
      this.loadWeiyiData ();
      this.getExceptionDevice ();
      this.getYuliangDataList ();
    },
    bottomPanelWidth() {
      // 通过权限控制展示模块，滚屏数据
      this.$nextTick(() => {
        let cexie = false;
        let shenya = false;
        let gnss = false;
        let permsStr = localStorage.getItem("user_perms");
        let permsAry = permsStr.split(",");
        let i = 0;
        permsAry.forEach(o => {
          if (o == 'sys:mtr:cexie') {
            cexie = true;
            i++;
          }
          if (o == 'sys:mtr:shenya') {
            shenya = true;
            i++;
          }
          if ((o == 'sys:mtr:gnss' || o == 'sys:mtr:quanzhan') && !gnss) {
            gnss = true;
            i++;
          }
        });
        // 计算滚屏数据宽度
        let widthCss = '0';
        if (i == 1) {
          widthCss = '100%';
        } else if (i == 2) {
          widthCss = '49.5%';
        } else {
          widthCss = '33%';
        }
        setTimeout(() => {
          if (this.$refs.table1) {
            this.$refs.table1.$el.style.width = widthCss;
            this.$refs.table2.$el.style.width = widthCss;
            this.$refs.table3.$el.style.width = widthCss;
          }
        }, 0);
      });
    },
    resetBottomDrawer () {
      const scale = this.screenWidth / 1920
      this.drawerBottom = this.screenWidth < 1920 ? (window.innerHeight - this.$refs.scaleWrap.clientHeight * scale) / scale - 2 : 0
      this.bottomDrawerH = this.screenWidth < 1920 ? (window.innerHeight - (60 + 430 + 300) * scale) / scale : 310//头部+项目详情模块+设备统计模块
    },
    hideMarkerLabel() {
      this.showLabel = false;
      this.$refs.mapDiagramRef.hideMarkerLabel();
    },
    showMarkerLabel() {
      this.showLabel = true;
      this.$refs.mapDiagramRef.showMarkerLabel();
    },
    /**
     * 选择设备
     */
    deviceChange(value) {
      let that = this;
      this.deviceOptions.forEach(o => {
        // console.log(value.includes(o.value));
        if (value.includes(o.value)) {
          // 显示
          var e = {
            target: {
              checked: true
            }
          };
          that.$refs.mapDiagramRef.deviceCheckBoxChange(e, o.value);
        } else {
          // 隐藏
          var e = {
            target: {
              checked: false
            }
          };
          that.$refs.mapDiagramRef.deviceCheckBoxChange(e, o.value);
        }
      });
      // console.log(value);
    },
    handleTabChange(target) {
      if(target.value === 'setting') {
        const routeUrl = this.$router.resolve('/home');
        window.open(routeUrl.href, '_blank');
      } else {
        this.popupTitle = target.label;
        this.showPopup = true;
        this.currentTab = target.value;
      }
    },
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    /**
     * 点击预警统计数据
     * @param {String} type 点击的数字类型：待处理stay|今日新增 newly | 预警. blueWarning,yellowWarning,orangeWarning,redWarning,可根据不同类型转跳不同路由
     */
     handleClickData(type) {
      switch (type) {
        case "stay":
          this.alarmSearchType = '1';
          break;
        case "newly":
          this.alarmSearchType = '5';
          break;
        case "blueWarning":
          this.alarmSearchType = '6';
          break;
        case "yellowWarning":
          this.alarmSearchType = '2';
          break;
        case "orangeWarning":
          this.alarmSearchType = '3';
          break;
        case "redWarning":
          this.alarmSearchType = '4';
          break;
        default:
          break;
      }
      this.showAlarmInfoDialog = true;
    },
    onMarkerClick(data) {
      if(data.type === 30) {
        // 不显示全站仪
        return;
      }
      if (data.type === 95) {
        // 摄像头
        // this.showCameraPlay = true;
        this.deviceClickInfo = data;
        this.playCamera(this.deviceClickInfo.code);
      } else {
        this.showDeviceDetail = true;
        this.deviceClickInfo = data;
      }
    },
    loadMapDeviceType(data) {
      // this.deviceChecked = [];
      this.deviceOptions = [];
      let that = this;
      data.forEach(d => {
        that.deviceOptions.push({
          value: d,
          label: that.getDeviceTypeName(d)
        });

        // that.deviceChecked.push(d);
      });
      // 100毫秒后再隐藏
      setTimeout(() => {
        this.hiddenAllMarker();
      }, 100);
    },
    hiddenAllMarker() {
      this.deviceChange(this.deviceChecked);
    },
    getDeviceTypeName(type) {
      var typeName = "";
      switch (type) {
        case 10:
          typeName = "干滩";
          break;
        case 20:
          typeName = "水位计";
          break;
        case 21:
          typeName = "超高";
          break;
        case 22:
          typeName = "明渠流量计";
          break;
        case 30:
          typeName = "全站仪";
          break;
        case 32:
          typeName = "全站仪点位";
          break;
        case 33:
          typeName = "GNSS";
          break;
        case 40:
          typeName = "内部测斜仪";
          break;
        case 50:
          typeName = "渗压计";
          break;
        case 60:
          typeName = "雨量计";
          break;
        case 91:
          typeName = "浊度仪";
          break;
        case 95:
          typeName = "摄像头";
          break;
        case 98:
          typeName = "中控室";
          break;
        case 99:
          typeName = "水工坝";
          break;
        default:
          break;
      }

      return typeName;
    },
    // 获取项目信息
    async loadProjectDiagramConfig() {
      const { data: res } = await this.$http.get("project/load/for/dataview", {
        showLoading: false
      });
      if (res.code == 0) {
        this.projectInfo = res.data.project;
        this.$refs.mapDiagramRef.setProjectInfo(res.data.project);
      }
    },
    // 获取系统健康情况
    async loadHealthStatus () {
      const { data: res } = await this.$http.get("project/find/health/status", {
        showLoading: false
      });
      if (res.code == 0 && res.data != null) {
        this.healthData.systemValue = res.data.sysCurrent;
        this.healthData.projectValue = res.data.projectCurrent;
      }
    },
    // 获取预警统计
    async getAlarmStatis() {
      const { data: res } = await this.$http.get("alarm/alarmRecord/statis", {
        showLoading: false
      });
      if (res.code == 0) {
        this.alarmStatistics = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取项目信息
    async getProjectSummary () {
      const { data: res } = await this.$http.get("device/project/summary", {
        showLoading: false
      });
      if (res.code == 0) {
        this.projectSummary = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取浸润线数据
    async loadJinrunxianData () {
      const { data: res } = await this.$http.get("device/shenya/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          let v = "";
          if (o.slyali === '1') {
            // 压力值
            v = "压力" + this.common.floatFormat(o.currentValue, 2) + "kPa";
          } else {
            // 空管距离
            v = "空管" + this.common.floatFormat(o.currentValue, 2) + "米";
          }
          d.push({
            deviceName: o.name,
            value: v,
            time: o.collectTime
          });
        });
        this.soakCheck = d;
      }
    },
    // 获取内部位移数据
    async loadCexieData () {
      const { data: res } = await this.$http.get("device/cexie/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          d.push({
            deviceName: o.name,
            value: o.currentValue,
            time: o.collectTime
          });
        });
        this.insideCheck = d;
      }
    },
    // 获取表面位移数据
    async loadWeiyiData () {
      const { data: res } = await this.$http.get("device/weiyi/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          let i = [o.name, o.value];
          d.push({
            deviceName: o.name,
            value: o.value
          });
        });
        this.surfaceCheck = d;
      }
    },
    getYuliangDataList () {
      if (this.$refs.ylMntRef) {
        this.$refs.ylMntRef.reloadData();
      }
    },
    // 获取异常设备信息
    async getExceptionDevice () {
      const { data: res } = await this.$http.get(
        "device/exception/device/statis",
        {
          showLoading: false,
        }
      );
      if (res.code == 0) {
        var exceptionDevice = res.data;
        this.createExceptionData(exceptionDevice);
      } else {
        this.$message.error(res.msg);
      }
    },
    createExceptionData(data) {
      this.pieConfig = {
        gnss: [
          { value: data.gnssTotal-data.gnss, name: "正常", devType: 33 },
          { value: data.gnss, name: "异常", devType: 33 },
        ],
        cexie: [
          { value: data.cexieTotal-data.cexie, name: "正常", devType: 40 },
          { value: data.cexie, name: "异常", devType: 40 },
        ],
        shenya: [
          { value: data.shenyaTotal-data.shenya, name: "正常", devType: 50 },
          { value: data.shenya, name: "异常", devType: 50 },
        ],
        quanzhan: [
          { value: data.quanzhanTotal-data.quanzhan, name: "正常", devType: 30 },
          { value: data.quanzhan, name: "异常", devType: 30 },
        ],
        zhuodu: [
          { value: data.zuoduTotal-data.zuodu, name: "正常", devType: 91 },
          { value: data.zuodu, name: "异常", devType: 91 },
        ],
        yuliang: [
          { value: data.yuliangTotal-data.yuliang, name: "正常", devType: 60 },
          { value: data.yuliang, name: "异常", devType: 60 },
        ],
        wuwei: [
          { value: data.wuweiTotal-data.wuwei, name: "正常", devType: 20 },
          { value: data.wuwei, name: "异常", devType: 20 },
        ],
        mingqu: [
          { value: data.mingquTotal-data.mingqu, name: "正常", devType: 22 },
          { value: data.mingqu, name: "异常", devType: 22 },
        ],
      };
    },
    // 订阅预警消息
    onAlarmMsg () {
      // 订阅预警
      bus.$on("alarm-msg-event", (msg) => {
        this.loadData();
        if (msg.type === '10010101') {
          // 黄色预警
          this.$notify({
            title: msg.data.title,
            dangerouslyUseHTMLString: true,
            message: msg.data.content,
            type: 'warning',
            duration: msg.data.duration * 1000
          });
        } else if (msg.type === '10010001') {
          // console.log("==============");
          // 蓝色预警
          this.$notify({
            title: msg.data.title,
            dangerouslyUseHTMLString: true,
            message: msg.data.content,
            type: 'warning',
            duration: msg.data.duration * 1000
          });
        } else if (msg.type === '10010201') {
          // console.log("==============");
          // 橙色预警
          this.$notify({
            title: msg.data.title,
            dangerouslyUseHTMLString: true,
            message: msg.data.content,
            type: 'warning',
            duration: msg.data.duration * 1000
          });
        } else if (msg.type === '10010301') {
          // 红色预警
          this.$notify.error({
            title: msg.data.title,
            dangerouslyUseHTMLString: true,
            message: msg.data.content,
            duration: msg.data.duration * 1000,
            onClose: function () {
              var audio = document.getElementById("alarmAudio");
              if (!audio.paused) {
                audio.pause();
                // console.log("停止播放.");
              }
            }
          });
          this.alarm();
        } else if (msg.type === '20010000') {
          // 通知
          this.$notify({
            title: msg.data.title,
            dangerouslyUseHTMLString: true,
            message: msg.data.content,
            type: 'warning',
            duration: msg.data.duration * 1000
          });
        }
      });
    },
    // 预警声音播放
    alarm () {
      var audio = document.getElementById("alarmAudio");
      if (audio.paused) {
        audio.play();
        audio.loop = true;
        audio.preload = true;
      } else {
        // console.log("已经在播放.");
      }
    },
    // 显示雨量的统计数据
    showRainInfo() {
      this.showYuliangInfoDialog = true;
    },
    // 显示库区健康状态
    showHealthInfo() {
      this.alarmSearchType = 0;
      this.showAlarmInfoDialog = true;
    },
    // 显示实时监测及历史数据
    showHistoryData() {
      this.showProjectRealTimeDialog = true;
    },
    showShenyaRealTime() {
      this.showShenyaMntDialog = true;
    },
    showCexieRealTime() {
      this.showCexieMntDialog = true;
    },
    showQuanzhanRealTime() {
      this.showQzMntDialog = true;
    },
    showErrorDeviceDetail(devType) {
      // console.log(devType);
      switch (devType) {
        case 40:
          // 测斜仪
          this.showCexieRealTimeDialog = true;
          break;
        case 50:
          // 渗压计
          this.showShenyaRealTimeDialog = true;
          break;
        case 30:
          // 全站仪
          this.showQzRealTimeDialog = true;
          break;
        case 91:
          // 浊度仪
          this.showZhuoduRealTimeDialog = true;
          break;
        case 60:
          // 雨量
          this.showYuliangRealTimeDialog = true;
          break;
        case 22:
          // 明渠流量
          this.showMingquRealTimeDialog = true;
          break;
        case 20:
          // 水位
          this.showShuiweiRealTimeDialog = true;
          break;
        default:
          break;
      }
    },
    // 摄像头相关
    async playCamera(deviceCode) {
      const { data: res } = await this.$http.get("device/camera/pull/stream/" + deviceCode);
      if (res.code == 0) {
        this.currentShowCamera = res.data;
        this.showCameraPlay = true;
      } else {
        this.$message.error("播放失败");
      }
    },
    // async closeStream(deviceCode) {
    //   const { data: res } = await this.$http.get("device/camera/close/stream/" + deviceCode);
    //   if (res.code == 0) {
    //     console.log(res.data);
    //   }
    // },
    // closeVideo(done) {
    //   this.closeStream(this.currentShowCamera.stream_name);
    //   done();
    // }
  },
  destroyed () {
    bus.$off("alarm-msg-event");
  },
  beforeDestroy () {
    clearInterval(mainTimer);
  }
};
</script>
<style lang="less" scoped>
.minitor-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  .scale-wrap {
    position: fixed;
    top: 0;
    left: 0;
    transform-origin: left top;
    z-index: 999;
    width: 1920px;
    height: 1080px;
  }
  .selector {
    position: fixed;
    display: flex;
    top: 96px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    .select-placeholder {
      position: absolute;
      width:128px;
      height: 42px;
      line-height: 42px;
      border-radius: 21px;
      background: rgba(0, 47, 132, 0.6);
      color: #fff;
      font-size: 18px;
      z-index: -1;
      left: 3px;
      padding-right: 22px;
      box-sizing: border-box;
      text-align: center;
    }
    &::v-deep .el-input__inner {
      width: 128px;
      height: 42px;
      background: transparent;
      border-radius: 23px;
      border: none;
      font-size: 18px;
      &::placeholder {
        color: transparent;
      }
    }
    &::v-deep .el-tag.el-tag--info {
      background: rgba(0, 47, 132, 1);
      border: none;
      border-radius: 12px;
      color: #ffffff;
      display: none;
    }
    &::v-deep .el-select__caret {
      color: #ffffff;
      font-size: 18px;
    }
    &::v-deep .el-select-dropdown {
      width: 180px;
      top: 38px !important;
      left: 0 !important;
      border: 1px solid;
      padding: 1px;
      border-radius: 6px;
      box-shadow: none;
      background-image: linear-gradient(
        197deg,
        rgba(62, 201, 255, 1),
        rgba(55, 190, 255, 1),
        rgba(38, 165, 255, 0.16),
        rgba(29, 152, 255, 1)
      );
      .el-scrollbar {
        background: rgba(0, 47, 132, 0.9);
        border-radius: 4px;
      }
      .el-select-dropdown__item {
        color: #ffffff;
        text-align: left;
      }
      .el-select-dropdown__item.hover,
      .el-select-dropdown__item.selected {
        background-color: rgba(0, 47, 132, 1);
      }
    }
    &::v-deep .popper__arrow {
      border-bottom-color: rgba(62, 201, 255, 1);
      &::after {
        border-bottom-color: rgba(0, 47, 132, 0.8);
        top: 1px;
      }
    }
    &::v-deep .el-tag__close.el-icon-close {
      background-color: #ffffff;
      color: rgba(0, 47, 132, 1);
      &:hover {
        color: #ffffff;
        background-color: rgba(0, 47, 132, 1);
      }
    }
    .icon {
      display: inline-block;
      background-color: rgba(0, 47, 132, 0.6);
      width: 42px;
      height: 42px;
      margin-left: 20px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.full-screen {
        background: rgba(0, 47, 132, 0.6) url(~@/assets/images/full_screen.png)
          center no-repeat;
        background-size: 20px 20px;
      }
      &.unfull-screen {
        background: rgba(0, 47, 132, 0.6) url(~@/assets/images/unfull_screen.png)
          center no-repeat;
        background-size: 21px 21px;
      }

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
  .map {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .tables {
    display: flex;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: calc(100% - 25px);
    padding: 0 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #ffffff;
  }
  .left-drawer {
    width: 570px;
    right: auto;
    min-width: 540px;
    transform: scale(0.9);
    transform-origin: left top;
    &::v-deep .el-drawer__body {
      padding-top: 75px;
      padding-left: 30px;
      box-sizing: border-box;
    }
  }
  .right-drawer {
    left: auto;
    width: 590px;
    transform: scale(0.9);
    transform-origin: right top;
    &::v-deep .el-drawer__body {
      padding-top: 78px;
      padding-right: 30px;
      box-sizing: border-box;
    }
  }
  .bottom-drawer {
    height: 310px;
    top: auto;
  }

  &::v-deep .el-drawer {
    background-color: transparent;
    box-shadow: none !important;
  }
  &::v-deep .el-drawer__wrapper {
    position: absolute;
  }
}
</style>
<style lang="less">
.module {
  width: 100%;
  height: 100%;
}
.el-drawer__body {
  overflow: hidden;
}
</style>