<template>
  <div>
    <el-row>
      <el-col :span="8" v-has="'sys:mtr:gnss'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(33)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.gnss }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.gnssTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">GNSS</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:cexie'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(40)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.cexie }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.cexieTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">测斜仪</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:wuwei'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(20)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.wuwei }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.wuweiTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">水位计</div>
      </el-col>
      <!-- </el-row>
    <el-row> -->
      <el-col :span="8" v-has="'sys:mtr:shenya'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(50)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.shenya }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.shenyaTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">渗压计</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:gantan'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(10)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.gantan }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.gantanTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">干滩</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:chaogao'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(21)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.chaogao }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.chaogaoTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">超高</div>
      </el-col>
      <!-- </el-row>
    <el-row> -->
      <el-col :span="8" v-has="'sys:mtr:yuliang'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(60)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.yuliang }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.yuliangTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">雨量计</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:zhuodu'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(91)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.zuodu }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.zuoduTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">浊度仪</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:mingqu'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(22)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
          <div class="er-detail-num" slot="reference">
            {{ exceptionDevice.mingqu }}<span style="color: #fff">/</span>
            <span style="color: aqua">{{ exceptionDevice.mingquTotal }}</span>
          </div>
        </el-popover>
        <div class="er-detail-title">明渠流量</div>
      </el-col>
      <el-col :span="8" v-has="'sys:mtr:quanzhan'">
        <el-popover
          placement="right"
          width="150"
          trigger="hover"
          @show="showExceptionDevice(30)"
        >
          <div v-for="n in deviceNames" :key="n">{{ n }}</div>
        <div class="er-detail-num" slot="reference">
          {{ exceptionDevice.quanzhan }}<span style="color: #fff">/</span>
          <span style="color: aqua">{{ exceptionDevice.quanzhanTotal }}</span>
        </div>
        </el-popover>
        <div class="er-detail-title">全站仪点位</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      exceptionDevice: {
        total: 0,
        quanzhan: 0,
        cexie: 0,
        shenya: 0,
        yuliang: 0,
        zuodu: 0,
        wuwei: 0,
        gantan: 0,
        chaogao: 0,
        gnss: 0,
        quanzhanTotal: 0,
        cexieTotal: 0,
        shenyaTotal: 0,
        yuliangTotal: 0,
        zuoduTotal: 0,
        wuweiTotal: 0,
        gantanTotal: 0,
        chaogaoTotal: 0,
        gnssTotal: 0,
      },
      deviceNames: [],
    };
  },
  created () {
    this.getExceptionDevice();
  },
  methods: {
    async getExceptionDevice () {
      const { data: res } = await this.$http.get(
        "device/exception/device/statis",
        {
          showLoading: false,
        }
      );
      if (res.code == 0) {
        this.exceptionDevice = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showExceptionDevice (type) {
      const { data: res } = await this.$http.get(
        "device/exception/device/names",
        {
          params: {
            deviceType: type,
          },
          showLoading: false,
        }
      );
      if (res.code == 0) {
        this.deviceNames = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.er-detail-num {
  margin-top: 12px;
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-align: center;
  width: 100%;
  height: 25px;
}
.er-detail-title {
  font-size: 14px;
  text-align: center;
  color: aqua;
  width: 100%;
  height: 25px;
}
</style>