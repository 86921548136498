<template>
  <div class="viewContainer">
    <div class="systemStatus" id="systemCharts"></div>
    <div class="projectStatus" id="projectCharts"></div>
    <div class="des">
      <el-tooltip class="item" effect="dark" placement="top">
        <div slot="content">系统状态：反应监测设备运行状况，有异常设备则影响该数值；<br/>
          库区状态：反应尾矿库监测情况，受预警数量影响。
        </div>
        <i class="el-icon-warning"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      systemChartView: null,
      projectChartView: null,
      systemValue: 100,
      projectValue: 100
    };
  },
  created () {
    
  },
  mounted() {
    this.systemChart();
    this.projectChart();
    this.loadHealthStatus();
  },
  methods: {
    async loadHealthStatus () {
      const { data: res } = await this.$http.get("project/find/health/status", {
        showLoading: false
      });
      if (res.code == 0 && res.data != null) {
        this.systemValue = res.data.sysCurrent;
        this.projectValue = res.data.projectCurrent;
        this.systemChart();
        this.projectChart();
      }
    },
    systemChart () {
      if (this.systemChartView == null) {
        this.systemChartView = this.$echarts.init(document.getElementById('systemCharts'), null, {
          renderer: 'canvas',
          useDirtyRect: false
        });
      }

      var option;

      option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.6, '#FF6E76'],
                  [0.9, '#FDDD60'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 15,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 0,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            splitLine: {
              length: 0,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            axisLabel: {
              color: 'white',
              fontSize: 12,
              distance: -30,
              formatter: function (value) {
                if (value === 100) {
                  return '良好';
                } else if (value === 80) {
                  return '异常';
                } else if (value === 40) {
                  return '危险';
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '50%'],
              color: 'aqua',
              fontSize: 12
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return value + '分';
              },
              color: 'auto'
            },
            data: [
              {
                value: this.systemValue,
                name: '系统状态'
              }
            ]
          }
        ]
      };

      this.systemChartView.clear();
      this.systemChartView.setOption(option);
    },
    projectChart() {
      if (this.projectChartView == null) {
        this.projectChartView = this.$echarts.init(document.getElementById('projectCharts'), null, {
          renderer: 'canvas',
          useDirtyRect: false
        });
      }

      var option;

      option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, '#FF6E76'],
                  [0.6, '#FDDD60'],
                  [0.9, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 15,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 0,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            splitLine: {
              length: 0,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            axisLabel: {
              color: 'white',
              fontSize: 12,
              distance: -30,
              formatter: function (value) {
                if (value === 100) {
                  return '良好';
                } else if (value === 80) {
                  return '低风险';
                } else if (value === 40) {
                  return '中风险';
                } else if (value === 10) {
                  return '高风险';
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '50%'],
              color: 'aqua',
              fontSize: 12
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return value + '分';
              },
              color: 'auto'
            },
            data: [
              {
                value: this.projectValue,
                name: '库区状态'
              }
            ]
          }
        ]
      };

      this.projectChartView.clear();
      this.projectChartView.setOption(option);
    }
  },
};
</script>

<style lang="less" scoped>
.viewContainer {
  width: 100%;
  height: 100%;
}
.systemStatus {
  float: left;
  width: 50%;
  height: 100%;
  margin-top: 30px;
}
.projectStatus {
  float: left;
  width: 50%;
  height: 100%;
  margin-top: 30px;
}
.des {
  position:absolute;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
  padding: 10px;
  color: white;
}
</style>