<template>
  <div style="height:100%;width:100%">
    <baidu-map ak=COMMON_CONFIG.BAIDU_MAP_AK :scroll-wheel-zoom="true" @ready="mapReady"
      :center="center" :zoom="zoom" style="height:calc(100%);">
      <!--地图视图-->
      <bm-view class="map" ref="mapRootRef"></bm-view>
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView"; //地图视图
import BmMarker from "vue-baidu-map/components/overlays/Marker"; //点标注
import BmNavigation from "vue-baidu-map/components/controls/Navigation"; //点标注
import BmMapType from "vue-baidu-map/components/controls/MapType"; //点标注
import BmScale from "vue-baidu-map/components/controls/Scale"; //点标注
import checkMarker from "@/assets/img/marker/check-marker.png";
import normalMarker from "@/assets/img/marker/normal-marker.png";
export default {
  components: {
    BaiduMap,
    BmView,
    BmMarker,
    BmNavigation,
    BmMapType,
    BmScale
  },
  props: {
    data: Object
  },
  inheritAttrs: false,
  data() {
    return {
      center: {
        //经纬度
        lng: 116.417,
        lat: 39.909,
      },
      zoom: 17, //地图展示级别
      dbMap: null,
      checkNormalItems: [],
      currentNormalItemsMarker: [],
      currentCheckLogMarker: [],
      checkMarkerIcon: {
        checkMarker: checkMarker,
        normalMarker: normalMarker
      },
      projectInfo: {}
    }
  },
  created() {

  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data != null) {
          this.projectInfo = res.data[0];
          this.changeCenter(this.projectInfo);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    mapReady({ BMap, map }) {
      map.setMapType(BMAP_HYBRID_MAP);
      this.dbMap = map;
      this.getAllProject();
    },
    changeCenter(projectInfo) {
      if (projectInfo == null) {
        return;
      }

      this.dbMap.panTo(new BMap.Point(projectInfo.lng, projectInfo.lat));
      this.loadAllNormalItems(projectInfo.projectCode);
    },
    async loadAllNormalItems(projectCode) {
      const { data: res } = await this.$http.get("check/all/items", {
        params: {
          projectCode: projectCode
        }
      });
      if (res.code == 0) {
        this.checkNormalItems = res.data;
        this.createNormalItemMarker();
        this.loadCheckLine();
      } else {
        this.$message.error(res.msg);
      }
    },
    createNormalItemMarker() {
      this.clearOldNormalMarker();
      this.checkNormalItems.forEach(i => {
        var icon = new BMap.Icon(this.checkMarkerIcon.normalMarker, new BMap.Size(20, 26));
        var point = new BMap.Point(i.itemLng, i.itemLat);
        var marker = new BMap.Marker(point);
        marker.setIcon(icon);
        var lb = new BMap.Label("【参考点】" + i.itemName);
        lb.setOffset(new BMap.Size(18, 0));
        lb.setStyle({ color : "black", fontSize : "10px", border: 0, background: "rgba(255,255,255,0.8)"});
        marker.setLabel(lb);
        this.dbMap.addOverlay(marker);
        this.currentNormalItemsMarker.push(marker);
      });
    },
    createCheckLogMarker(loglist) {
      let that = this;
      this.clearOldCheckLogMarker();
      loglist.forEach(i => {
        var icon = new BMap.Icon(this.checkMarkerIcon.checkMarker, new BMap.Size(20, 26));
        var lc = this.convertLocaiton(i.checkLng, i.checkLat);
        var point = new BMap.Point(lc[0], lc[1]);
        var marker = new BMap.Marker(point);
        marker.setIcon(icon);
        var lb = new BMap.Label("【已巡检】" + i.checkItemName + "<br/>" + i.checkTime);
        lb.setOffset(new BMap.Size(18, -30));
        lb.setStyle({ color : "black", fontSize : "10px", border: 0, background: "rgba(255,255,255,0.9)"});
        marker.setLabel(lb);
        marker.addEventListener('click', function () {
          that.showLogDetail(i);
        });
        this.dbMap.addOverlay(marker);
        this.currentCheckLogMarker.push(marker);
      });
    },
    clearOldNormalMarker() {
      this.currentNormalItemsMarker.forEach(m => {
        this.dbMap.removeOverlay(m);
      });
      this.currentNormalItemsMarker = [];
    },
    clearOldCheckLogMarker() {
      this.currentCheckLogMarker.forEach(m => {
        this.dbMap.removeOverlay(m);
      });
      this.currentCheckLogMarker = [];
    },
    showLogMarker(loglist) {
      this.createCheckLogMarker(loglist);
    },
    movetocenter(data) {
      var lc = this.convertLocaiton(data.checkLng, data.checkLat);
      this.dbMap.panTo(new BMap.Point(lc[0], lc[1]));
    },
    showLogDetail(data) {
      this.$emit("onShowLogDetail", data);
    },
    // 转换坐标系
    convertLocaiton(lng, lat) {
      var bdco = this.mapUtil.wgs84tobd09(Number(lng), Number(lat));
      return bdco;
    },
    loadCheckLine() {
      var lineMap = new Map();
      this.checkNormalItems.forEach(e => {
        if (e.lineCode === null || e.lineCode === '0' || e.lineCode === '') {
          return;
        }

        var line = lineMap.get(e.lineCode);
        if (!line) {
          line = [];
        }

        line.push(e);
        lineMap.set(e.lineCode, line);
      });

      for (var [key, value] of lineMap) {
        if (value.length > 1) {
          this.arraySort(value);
          this.createLine(value);
        }
      }
    },
    createLine(points) {
      var ps = [];
      points.forEach(p => {
        ps.push(new BMap.Point(p.itemLng, p.itemLat));
      });
      var polyline = new BMap.Polyline(ps, {
          strokeColor: 'rgb('+parseInt(Math.random()*255)+','+parseInt(Math.random()*255)+','+parseInt(Math.random()*255)+')',
          strokeWeight: 4
      });
      this.dbMap.addOverlay(polyline);
    },
    arraySort(list) {
      for (let i = 0; i < list.length - 1; i++) {
        for (let j = 0; j < list.length - 1 - i; j++) {
          if (list[j].lineSort > list[j+1].lineSort) {
            var temp = list[j];
            list[j] = list[j+1];
            list[j+1] = temp;
          }
        }
      }
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height:calc(100%);
}
</style>