<template>
    <div ref="shenyaChartView" style="height: 100%; width: 100%"></div>
</template>
  
<script>
export default {
    inheritAttrs: false,
    data() {
        return {
            shenyaChart: null,
        };
    },
    methods: {
        async drawDiagram(deviceInfo) {
            if (deviceInfo === null) {
                return;
            }

            const { data: res } = await this.$http.get("alarm/find/config", {
                params: {
                    deviceCode: deviceInfo.deviceCode
                }
            });

            var redValue = 0;
            var orangeValue = 0;
            var yellowValue = 0;
            if (res.code == 0 && res.data != null) {
                console.log(res.data);
                redValue = this.common.floatFormat(res.data.redAlarmValue, 2);
                orangeValue = this.common.floatFormat(res.data.orangeAlarmValue, 2);
                yellowValue = this.common.floatFormat(res.data.yellowAlarmValue, 2);
            }

            let that = this;
            if (this.shenyaChart == null) {
                this.shenyaChart = this.$echarts.init(this.$refs.shenyaChartView);
            }
            
            var maishen = this.common.floatFormat(deviceInfo.maishen, 2); // 埋深
            var kgvalue = this.common.floatFormat(deviceInfo.currentValue, 2); // 空管距离
            var kdgaocheng = this.common.floatFormat(deviceInfo.kdgaoceng, 2); // 孔顶高程

            var shuizhu = this.common.floatFormat(this.common.floatFormat(maishen, 2) - this.common.floatFormat(kgvalue, 2), 2); // 水柱高度
            const kongtiData = [[45, 20], [45, 180], [55, 180], [55, 20]];  // 孔体点位
            const bgData = [[0, 0], [0, 200], [100, 200], [100, 0]];  // 背景点位
            const deviceBodyData = [[49, 24], [49, 40], [51, 40], [51, 24]];   // 设备点位
            const deviceLineData = [[50, 40], [50, 190], [55, 195], [55, 195], [50, 190], [50, 40]];   // 设备线点位

            var gtSize = 180 - 20;  // 管体长度
            var shuiwei = this.common.floatFormat((shuizhu/maishen) * gtSize, 2);
            const kongguanLineData = [[44, 20 + shuiwei], [42, 20 + shuiwei], [42, 180], [44, 180], [44, 180], [42, 180], [42, 20 + shuiwei]];   // 空管距离线点位
            const shuizhuData = [[45, 20], [45, 20 + shuiwei], [55, 20 + shuiwei], [55, 20]];  // 水柱点位

            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return '';
                    }
                },
                grid: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                },
                legend: {
                    data: []
                },
                xAxis: {
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    show: false,
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                graphic: [],
                series: [
                    {
                        id: 'top',
                        name: 'top',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: params.coordSys.height * 0.32,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: 'gray',
                                    opacity: 1,
                                    lineWidth: 5,
                                    stroke: 'gray'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#fff',
                            offset: [80, 0],
                            formatter: function (param) {
                                return '管口高程：' + kdgaocheng + 'm';
                            }
                        },
                    },
                    {
                        id: 'bottom',
                        name: 'bottom',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: params.coordSys.height * 3,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: '#000',
                                    opacity: 1,
                                    lineWidth: 4,
                                    stroke: '#000'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                    },
                    {
                        id: 'redAlarm',
                        name: 'redAlarm',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            var y = that.getCircleLocation(maishen, maishen - redValue, params.coordSys.height);
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: y,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: '#ff3c5d',
                                    opacity: 1,
                                    lineWidth: 0,
                                    stroke: '#ff3c5d'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#ff3c5d',
                            offset: [80, 0],
                            formatter: function (param) {
                                return '红色预警：'+ redValue +'m';
                            }
                        },
                    },
                    {
                        id: 'orangeAlarm',
                        name: 'orangeAlarm',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            var y = that.getCircleLocation(maishen, maishen - orangeValue, params.coordSys.height);
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: y,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: '#ff6541',
                                    opacity: 1,
                                    lineWidth: 0,
                                    stroke: '#ff6541'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#ff6541',
                            offset: [80, 0],
                            formatter: function (param) {
                                return '橙色预警：'+ orangeValue +'m';
                            }
                        },
                    },
                    {
                        id: 'yellowAlarm',
                        name: 'yellowAlarm',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            var y = that.getCircleLocation(maishen, maishen - yellowValue, params.coordSys.height);
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: y,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: '#ffdd5f',
                                    opacity: 1,
                                    lineWidth: 0,
                                    stroke: '#ffdd5f'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#ffdd5f',
                            offset: [80, 0],
                            formatter: function (param) {
                                return '黄色预警：'+ yellowValue +'m';
                            }
                        },
                    },
                    {
                        id: 'waterLine',
                        name: 'waterLine',
                        type: 'custom',
                        z: 101,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            var y = that.getCircleLocation(maishen, shuizhu, params.coordSys.height);
                            params.context.rendered = true;
                            return {
                                type: 'circle',
                                transition: ['shape'],
                                scaleY: 0.3,
                                shape: {
                                    cx: params.coordSys.width / 2,
                                    cy: y,
                                    r: params.coordSys.width * 0.049
                                },
                                style: api.style({
                                    fill: 'rgb(121, 187, 255)',
                                    opacity: 1,
                                    lineWidth: 0,
                                    stroke: 'rgb(121, 187, 255)'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: 'rgb(121, 187, 255)',
                            offset: [80, 0],
                            formatter: function (param) {
                                return '水柱高度：'+ shuizhu +'m';
                            }
                        },
                    },
                    {
                        id: 'kongti',
                        name: 'kongti',
                        type: 'custom',
                        z: 100,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < kongtiData.length; i++) {
                                points.push(api.coord(kongtiData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: 'rgba(0, 0, 0, 0)',
                                    opacity: 1,
                                    lineWidth: 4,
                                    stroke: '#000'
                                })
                            };
                        },
                        clip: false,
                        data: kongtiData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                    },
                    {
                        id: 'shuizhu',
                        name: 'shuizhu',
                        type: 'custom',
                        z: 90,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < shuizhuData.length; i++) {
                                points.push(api.coord(shuizhuData[i]));
                            }
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: 'rgb(121, 187, 255)',
                                    opacity: 0.6,
                                    lineWidth: 0,
                                    stroke: '#000'
                                })
                            };
                        },
                        clip: false,
                        data: shuizhuData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                    },
                    {
                        id: 'device',
                        name: 'device',
                        type: 'custom',
                        z: 102,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < deviceBodyData.length; i++) {
                                points.push(api.coord(deviceBodyData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: '#67C23A',
                                    opacity: 1,
                                    lineWidth: 10,
                                    stroke: '#67C23A'
                                })
                            };
                        },
                        clip: false,
                        data: deviceBodyData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#000',
                            rotate: 90,
                            offset: [0, 0],
                            formatter: function (param) {
                                return '渗压计';
                            }
                        },
                    },
                    {
                        id: 'deviceLine',
                        name: 'deviceLine',
                        type: 'custom',
                        z: 102,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < deviceLineData.length; i++) {
                                points.push(api.coord(deviceLineData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: '#67C23A',
                                    opacity: 1,
                                    lineWidth: 3,
                                    stroke: '#67C23A'
                                })
                            };
                        },
                        clip: false,
                        data: deviceLineData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                    },
                    {
                        id: 'kongguanLine',
                        name: 'kongguanLine',
                        type: 'custom',
                        z: 102,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < kongguanLineData.length; i++) {
                                points.push(api.coord(kongguanLineData[i]));
                            }
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: 'gray',
                                    opacity: 1,
                                    lineWidth: 3,
                                    stroke: 'gray'
                                })
                            };
                        },
                        clip: false,
                        data: kongguanLineData,
                        itemStyle: {
                            color: '#fff',
                            borderType: 'dashed',
                            borderDashOffset: 300
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'inside',
                            color: '#fff',
                            rotate: 90,
                            offset: [0, -30],
                            formatter: function (param) {
                                return '空管距离：'+ kgvalue +'m';
                            }
                        },
                    },
                    {
                        id: 'bg',
                        name: 'bg',
                        type: 'custom',
                        z: 99,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < bgData.length; i++) {
                                points.push(api.coord(bgData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: '#fff',
                                    opacity: 0,
                                    lineWidth: 10,
                                    stroke: '#000'
                                })
                            };
                        },
                        clip: false,
                        data: bgData,
                        itemStyle: {
                            color: "rgb(192, 196, 204)"
                        },
                    }
                ]
            };

            this.shenyaChart.clear();
            this.shenyaChart.setOption(option);
        },
        // 获取椭圆的定位
        getCircleLocation(maishen, size, height) {
            var topY = height * 3 * 0.109;
            var bottomY = height * 3;
            var gtY = bottomY - topY;
            return bottomY - this.common.floatFormat((size/maishen) * gtY, 2);
        }
    },
};
</script>