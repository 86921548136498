<template>
  <div>
    <div class="mbtitle">
      <label> 地址：<input v-model="address" disabled /></label>
      <label> 经度：<input v-model="markerPoit.lat" disabled /></label>
      <label> 纬度：<input v-model="markerPoit.lng" disabled /></label>
      <span v-if="canEdit" style="color: red">说明：请用鼠标右键点选坐标</span>
    </div>

    <baidu-map
      ak= COMMON_CONFIG.BAIDU_MAP_AK
      :scroll-wheel-zoom="true"
      @rightclick="clickBmap"
      @ready="handler"
      :center="center"
      :zoom="zoom"
    >
      <!--地图视图-->
      <bm-view class="map"></bm-view>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-marker :position="markerPoit" :dragging="false"> </bm-marker>
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
        anchor="BMAP_ANCHOR_TOP_LEFT"
      ></bm-map-type>
    </baidu-map>
  </div>
</template>
 
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView"; //地图视图
import BmMarker from "vue-baidu-map/components/overlays/Marker"; //点标注
import BmNavigation from "vue-baidu-map/components/controls/Navigation"; //点标注
import BmMapType from "vue-baidu-map/components/controls/MapType"; //点标注
export default {
  components: {
    BaiduMap,
    BmView,
    BmMarker,
    BmNavigation,
    BmMapType,
  },
  props: {
    canEdit: Boolean,
    defaultPoit: Object,
    defaultAddress: String,
  },
  inheritAttrs: false,
  data() {
    return {
      storeName: "",
      center: {
        //经纬度
        lng: 0,
        lat: 0,
      },
      zoom: 5, //地图展示级别
      markerPoit: {
        lng: 0,
        lat: 0,
      },
      address: "",
    };
  },
  created() {
    // console.log("----------------");
    this.showPoit();
  },
  methods: {
    showPoit() {
      this.markerPoit = this.defaultPoit;
      this.address = this.defaultAddress;
    },
    clickBmap(val) {
      if (!this.canEdit) {
        return;
      }

      //右击鼠标放标注
      this.markerPoit = val.point;
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(val.point, (rs) => {
        this.address = rs.address;
        this.handleLocation({
          address: rs.address,
          point: val.point,
        });
      });
    },
    handler({ BMap, map }) {
      map.setMapType(BMAP_HYBRID_MAP);
      if (this.defaultPoit) {
        this.center.lng = this.defaultPoit.lng;
        this.center.lat = this.defaultPoit.lat;
      } else {
        this.center.lng = 116.404;
        this.center.lat = 39.915;
      }
      this.zoom = 16;
    },
    handleLocation(data) {
      this.$emit("onLocation", data);
    },
  },
};
</script>
 
<style scoped>
.map {
  width: 100%;
  height: 400px;
}
.mbtitle {
  margin-bottom: 10px;
}
</style>