<template>
  <div>
    <el-row>
      <el-col :span="6">
        <div class="form-title" style="margin-top: 15px">项目名称</div>
      </el-col>
      <el-col :span="18">
        <router-link
          :to="{ path: '/projectDetail', query: {} }"
          target="_blank"
        >
          <el-tooltip effect="dark" content="点击查看详情" placement="top">
            <div class="form-title-v">{{ projectSummary.projectName }}</div>
          </el-tooltip>
        </router-link>
      </el-col>
    </el-row>
    <el-row v-has="'sys:mtr:gantan'">
      <el-col :span="6">
        <div class="form-title">干滩长度</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          {{ projectSummary.gantan
          }}<span style="font-size: 12px; color: #fff"> 米</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-des">
          <div class="form-v-des-up">
            <span class="el-icon-caret-top"
              >今日最高
              {{ this.common.floatFormat(projectSummary.gantanH, 2) }}</span
            >
          </div>
          <div class="form-v-des-dw">
            <span class="el-icon-caret-bottom"
              >今日最低
              {{ this.common.floatFormat(projectSummary.gantanL, 2) }}</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-has="'sys:mtr:gantan'">
      <el-col :span="6">
        <div class="form-title">干滩坡度</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          {{ projectSummary.pb }}
        </div>
      </el-col>
      <el-col :span="9"></el-col>
    </el-row>
    <el-row v-has="'sys:mtr:wuwei'">
      <el-col :span="6">
        <div class="form-title">库水位</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          {{ projectSummary.shuiwei
          }}<span style="font-size: 12px; color: #fff"> 米</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-des">
          <div class="form-v-des-up">
            <span class="el-icon-caret-top"
              >今日最高
              {{ this.common.floatFormat(projectSummary.shuiweiH, 2) }}</span
            >
          </div>
          <div class="form-v-des-dw">
            <span class="el-icon-caret-bottom"
              >今日最低
              {{ this.common.floatFormat(projectSummary.shuiweiL, 2) }}</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-has="'sys:mtr:chaogao'">
      <el-col :span="6">
        <div class="form-title">安全超高</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          {{ projectSummary.chaogao
          }}<span style="font-size: 12px; color: #fff"> 米</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-des">
          <div class="form-v-des-up">
            <span class="el-icon-caret-top"
              >今日最高
              {{ this.common.floatFormat(projectSummary.chaogaoH, 2) }}</span
            >
          </div>
          <div class="form-v-des-dw">
            <span class="el-icon-caret-bottom"
              >今日最低
              {{ this.common.floatFormat(projectSummary.chaogaoL, 2) }}</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-has="'sys:mtr:yuliang'">
      <el-col :span="6">
        <div class="form-title">降雨量</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          <span style="font-size: 12px; color: #fff">当日 </span
          >{{ projectSummary.ylNumDay
          }}<span style="font-size: 12px; color: #fff"> 毫米</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          <span style="font-size: 12px; color: #fff">最新 </span
          >{{ projectSummary.ylNum
          }}<span style="font-size: 12px; color: #fff"> 毫米</span>
        </div>
      </el-col>
    </el-row>
    <el-row v-has="'sys:mtr:mingqu'">
      <el-col :span="6">
        <div class="form-title">明渠流量</div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          <span style="font-size: 12px; color: #fff">当日 </span
          >{{ projectSummary.mqNumDay
          }}<span style="font-size: 12px; color: #fff"> m³</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="form-v-1">
          <span style="font-size: 12px; color: #fff">最新 </span
          >{{ projectSummary.mqNum
          }}<span style="font-size: 12px; color: #fff"> m³</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      projectSummary: {
        projectName: "",
        quanzhanAlarm: 0,
        cexieAlarm: 0,
        shenyaAlarm: 0,
        ylNumDay: "0",
        ylNum: "0",
        gantan: "0",
        gantanH: "0",
        gantanL: "0",
        shuiwei: "0",
        shuiweiH: "0",
        shuiweiL: "0",
        chaogao: "0",
        chaogaoH: "0",
        chaogaoL: "0",
        pb1: "0",
        pb2: "0",
      },
    }
  },
  created () {
    this.getProjectSummary();
  },
  methods: {
    async getProjectSummary () {
      const { data: res } = await this.$http.get("device/project/summary", {
        showLoading: false
      });
      if (res.code == 0) {
        this.projectSummary = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.form-title {
  font-size: 14px;
  text-align: right;
  color: #fff;
  height: 40px;
}
.form-title-v {
  font-size: 18px;
  text-align: left;
  color: aqua;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  overflow: hidden;
}
.form-v-1 {
  font-size: 18px;
  text-align: left;
  color: aqua;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  line-height: 110%;
}
.form-v-des {
  width: 90%;
  height: 45px;
  margin-top: -10px;
}
.form-v-des-up {
  width: 100%;
  height: 18px;
  color: red;
  font-size: 12px;
}
.form-v-des-dw {
  width: 100%;
  height: 18px;
  color: greenyellow;
  font-size: 12px;
}
</style>