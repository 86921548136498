<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>实时监测</el-breadcrumb-item>
      <el-breadcrumb-item>内部位移监测</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="4">
        <!-- 断面 -->
        <el-card>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-select
                v-model="queryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getKonghaoList"
                @change="getKonghaoList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-tree
            :data="konghaoData"
            node-key="khCode"
            @node-expand="showChildren"
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadCexieData"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <!-- 浸润线数据 -->
        <el-row :gutter="5">
          <el-col :span="16">
            <el-row>
              <el-col :span="24">
                <el-card>
                  <el-divider content-position="left">
                    <span style="color: rgb(158 158 158)">示意图</span>
                  </el-divider>
                  <div style="height: 440px; overflow: hidden">
                    <v-cexie-diagram ref="cxDiagram"></v-cexie-diagram>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-card>
                  <el-divider content-position="left">
                    <span style="color: rgb(158 158 158)">动态曲线图</span>
                  </el-divider>
                  <div style="height: 220px">
                    <div
                      id="cexieChart"
                      class="chart"
                      style="width: 100%; height: 100%"
                    ></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-card>
              <el-divider content-position="left">
                <span style="color: rgb(158 158 158)">设备及预警信息</span>
              </el-divider>
              <div style="height: 730px; overflow: auto">
                <el-table :data="deviceInfoList" style="width: 100%">
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <el-form
                        label-position="left"
                        inline
                        class="demo-table-expand"
                      >
                        <el-form-item label="蓝色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.blueAlarmValue,
                                props.row.alarms.blueComparisonOperator
                              )
                            }}毫米</span
                          > </el-form-item
                        ><br />
                        <el-form-item label="黄色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.yellowAlarmValue,
                                props.row.alarms.yellowComparisonOperator
                              )
                            }}毫米</span
                          > </el-form-item
                        ><br />
                        <el-form-item label="橙色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.orangeAlarmValue,
                                props.row.alarms.orangeComparisonOperator
                              )
                            }}毫米</span
                          > </el-form-item
                        ><br />
                        <el-form-item label="红色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.redAlarmValue,
                                props.row.alarms.redComparisonOperator
                              )
                            }}毫米</span
                          >
                        </el-form-item>
                      </el-form>
                    </template>
                  </el-table-column>
                  <el-table-column label="名称" prop="name"> </el-table-column>
                  <el-table-column label="备注" prop="remark"></el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vCexieDiagram from "./CexieDiagram.vue";
export default {
  components: {
    vCexieDiagram,
  },
  props: {
    hiddenPath: Boolean
  },
  data () {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryParams: {
        projectCode: "",
        konghao: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      konghaoData: [],
      deviceInfoList: [],
      cexieChart: null,
    };
  },
  created () {
    this.getAllProject();
  },
  methods: {
    // 由程序处罚的加载
    async defaultLoadData(deviceInfo) {
      this.queryParams.projectCode = deviceInfo.projectCode;
      this.queryParams.konghao = deviceInfo.konghao;
      this.getKonghaoList();
      // 孔号维度
      this.loadCexieData({
        khCode: deviceInfo.konghao
      });
      // 设备维度
      this.loadCexieData({
        deviceCode: deviceInfo.deviceCode,
        konghao: deviceInfo.konghao
      });
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getKonghaoList () {
      const { data: res } = await this.$http.get("device/cexie/find/konghao", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.konghaoData = [];
          return;
        }

        this.konghaoData = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    loadCexieData (data) {
      if (data.khCode == null) {
        // 点击的是设备节点
        this.getDataList(data.deviceCode);
        this.getDeviceList(data.konghao);
      } else {
        // 点击的是孔号节点
        this.getDeviceList(data.khCode);
        // this.getProjectShuiwei(data.children);
      }
    },
    showChildren (data) {
      if (data.khCode != null) {
        // 展开节点的时候
        this.getDeviceList(data.khCode);
        // this.getProjectShuiwei(data.children);
      }
    },
    async getProjectShuiwei (deviceList) {
      const { data: res } = await this.$http.get("device/find/shuiwei", {
        params: {
          projectCode: this.queryParams.projectCode,
        },
      });
      if (res.code == 0) {
        this.$refs.cxDiagram.drawDiagram(deviceList, res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList (konghao) {
      const { data: res } = await this.$http.get(
        "device/cexie/find/devices/bykh",
        {
          params: {
            projectCode: this.queryParams.projectCode,
            konghao: konghao,
          },
        }
      );
      if (res.code == 0) {
        this.deviceInfoList = res.data;
        this.getProjectShuiwei(this.deviceInfoList);
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDataList (deviceCode) {
      // 获取最新的20条数据
      const { data: res } = await this.$http.post("device/cexie/history/data/img", {
        projectCode: this.queryParams.projectCode,
        deviceCode: deviceCode,
        pageNo: 1,
        pageSize: 100
      });
      if (res.code == 0) {
        this.drawData(res.data.list, res.data.alarmConfig);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.cexieChart == null) {
        this.cexieChart = this.$echarts.init(
          document.getElementById("cexieChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.cexieChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = dataList.length - 1; i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 4);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.blueAlarmValue != null && alarmConfig.blueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.blueAlarmValue, 2));
          }
          if (alarmConfig.yellowAlarmValue != null && alarmConfig.yellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.yellowAlarmValue, 2));
          }
          if (alarmConfig.orangeAlarmValue != null && alarmConfig.orangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.orangeAlarmValue, 2));
          }
          if (alarmConfig.redAlarmValue != null && alarmConfig.redAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.redAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "内部位移实时监测动态图（单位：mm）（上下游向下为正）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        series: [
          {
            name: "内部位移",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.cexieChart.clear();
      this.cexieChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
  font-size: 14px;
}
</style>