<template>
  <div>
    <el-form
      ref="addCertForm"
      :model="addCertInfoForm"
      :rules="formRules"
      label-width="140px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="尾矿库名称">
            <el-input v-model="addCertInfoForm.projectName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"> </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="尾矿库企业统一社会信用代码"
            prop="unifCreditCode"
            label-width="220px"
          >
            <el-input v-model="addCertInfoForm.unifCreditCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="尾矿库编号" prop="no">
            <el-input
              v-model="addCertInfoForm.no"
              placeholder="对外使用"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="安全负责人" prop="securityofficer">
            <el-input v-model="addCertInfoForm.securityofficer"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安全负责人电话" prop="securityofficertel">
            <el-input v-model="addCertInfoForm.securityofficertel"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="值班电话" prop="ondutytel">
            <el-input v-model="addCertInfoForm.ondutytel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="投入使用日期" prop="usedate">
            <el-date-picker
              v-model="addCertInfoForm.usedate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计服务年限" prop="useyear">
            <el-input
              v-model="addCertInfoForm.useyear"
              placeholder="单位：年"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运行状况" prop="operatingstatus">
            <el-select
              v-model="addCertInfoForm.operatingstatus"
              placeholder="请选择运行状况"
            >
              <el-option label="在建" :value="1"></el-option>
              <el-option label="在用" :value="2"></el-option>
              <el-option label="停用" :value="3"></el-option>
              <el-option label="废弃" :value="4"></el-option>
              <el-option label="已闭库" :value="5"></el-option>
              <el-option label="闭库后再利用" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="安全度" prop="safetytypeno">
            <el-select
              v-model="addCertInfoForm.safetytypeno"
              placeholder="请选择安全度"
            >
              <el-option label="危库" :value="1"></el-option>
              <el-option label="险库" :value="2"></el-option>
              <el-option label="病库" :value="3"></el-option>
              <el-option label="正常库" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属行业" prop="industrytypeno">
            <el-select
              v-model="addCertInfoForm.industrytypeno"
              placeholder="请选择所属行业"
            >
              <el-option label="有色" value="200000000"></el-option>
              <el-option label="黑色" value="100000000"></el-option>
              <el-option label="化工" value="302000000"></el-option>
              <el-option label="建材" value="301020000"></el-option>
              <el-option label="其他" value="9900000000"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属矿种" prop="mineralspecies">
            <el-select
              v-model="addCertInfoForm.mineralspecies"
              placeholder="请选择所属矿种"
            >
              <el-option label="黄金" value="202010000"></el-option>
              <el-option label="铁" value="100010000"></el-option>
              <el-option label="铜" value="201010000"></el-option>
              <el-option label="铅" value="201020000"></el-option>
              <el-option label="锌" value="201030000"></el-option>
              <el-option label="磷" value="302030000"></el-option>
              <el-option label="其他" value="300000000"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否头顶库" prop="top">
            <el-radio-group v-model="addCertInfoForm.top">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="是否安装在线监测系统"
            label-width="170px"
            prop="onlinemonitoringsys"
          >
            <el-radio-group v-model="addCertInfoForm.onlinemonitoringsys">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地质构造及条件" prop="geologicalstructure">
            <el-input v-model="addCertInfoForm.geologicalstructure"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="地形坡度" prop="topotraphicgradient">
            <el-input
              v-model="addCertInfoForm.topotraphicgradient"
              placeholder="单位：度"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下垫面类型" prop="underlyingsurfacetype">
            <el-input
              v-model="addCertInfoForm.underlyingsurfacetype"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标准化等级" prop="standardizationlevel">
            <el-select
              v-model="addCertInfoForm.standardizationlevel"
              placeholder="请选择标准化等级"
            >
              <el-option label="一级" :value="10"></el-option>
              <el-option label="二级" :value="20"></el-option>
              <el-option label="三级" :value="30"></el-option>
              <el-option label="未评定" :value="90"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="标准化等级证书取证日期"
            label-width="180px"
            prop="standardforensicsdate"
          >
            <el-date-picker
              v-model="addCertInfoForm.standardforensicsdate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item
            label="安全生产许可证编号"
            prop="safetypermitno"
            label-width="170px"
          >
            <el-input v-model="addCertInfoForm.safetypermitno"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item
            label="安全生产许可证有效期"
            label-width="170px"
            prop="safetypermitdateArray"
          >
            <el-date-picker
              v-model="addCertInfoForm.safetypermitdateArray"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="安全生产许可证发证机关"
            label-width="180px"
            prop="safetypermitunit"
          >
            <el-input v-model="addCertInfoForm.safetypermitunit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="尾矿库类型" prop="storetypeno">
            <el-select
              v-model="addCertInfoForm.storetypeno"
              placeholder="请选择尾矿库类型"
            >
              <el-option label="山谷型" :value="1"></el-option>
              <el-option label="傍山型" :value="2"></el-option>
              <el-option label="平地型" :value="3"></el-option>
              <el-option label="截河型" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计等别" prop="designlevel">
            <el-select
              v-model="addCertInfoForm.designlevel"
              placeholder="请选择设计等别"
            >
              <el-option label="一等" :value="1"></el-option>
              <el-option label="二等" :value="2"></el-option>
              <el-option label="三等" :value="3"></el-option>
              <el-option label="四等" :value="4"></el-option>
              <el-option label="五等" :value="5"></el-option>
              <el-option label="不明" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="现状等别" prop="grade">
            <el-select
              v-model="addCertInfoForm.grade"
              placeholder="请选择现状等别"
            >
              <el-option label="一等" :value="1"></el-option>
              <el-option label="二等" :value="2"></el-option>
              <el-option label="三等" :value="3"></el-option>
              <el-option label="四等" :value="4"></el-option>
              <el-option label="五等" :value="5"></el-option>
              <el-option label="不明" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计总坝高" prop="designheight">
            <el-input
              v-model="addCertInfoForm.designheight"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="现状总坝高" prop="stackheight">
            <el-input
              v-model="addCertInfoForm.stackheight"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计库容" prop="plancapability">
            <el-input
              v-model="addCertInfoForm.plancapability"
              placeholder="单位：万立方米"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="现状库容" prop="nowvolume">
            <el-input
              v-model="addCertInfoForm.nowvolume"
              placeholder="单位：万立方米"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="筑坝方式" prop="damstocktypeno">
            <el-select
              v-model="addCertInfoForm.damstocktypeno"
              placeholder="请选择筑坝方式"
            >
              <el-option label="上游式" :value="1"></el-option>
              <el-option label="中线式" :value="2"></el-option>
              <el-option label="下游式" :value="3"></el-option>
              <el-option label="一次性筑坝" :value="4"></el-option>
              <el-option label="其他" :value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排洪设施型式" prop="floodequipment">
            <el-input v-model="addCertInfoForm.floodequipment"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="库外排洪型式" prop="floodmethod">
            <el-input v-model="addCertInfoForm.floodmethod"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排洪设施泄流量" prop="floodfacilitydischarge">
            <el-input
              v-model="addCertInfoForm.floodfacilitydischarge"
              placeholder="单位：立方米/秒"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="汇水面积" prop="waterarea">
            <el-input
              v-model="addCertInfoForm.waterarea"
              placeholder="单位：平方公里"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="尾矿库面积" prop="area">
            <el-input
              v-model="addCertInfoForm.area"
              placeholder="单位：平方公里"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计防洪标准" prop="floodcontrolstandard">
            <el-input
              v-model="addCertInfoForm.floodcontrolstandard"
              placeholder="单位：年"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="初期坝类型" prop="damtype">
            <el-select
              v-model="addCertInfoForm.damtype"
              placeholder="请选择初期坝类型"
            >
              <el-option label="透水坝" :value="1"></el-option>
              <el-option label="不透水坝" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="初期坝坝型" prop="initialdamtype">
            <el-select
              v-model="addCertInfoForm.initialdamtype"
              placeholder="请选择初期坝坝型"
            >
              <el-option label="均质土坝" :value="1"></el-option>
              <el-option label="堆石坝" :value="2"></el-option>
              <el-option label="土石坝" :value="3"></el-option>
              <el-option label="其他" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设计初期坝高" prop="designinitialdamheight">
            <el-input
              v-model="addCertInfoForm.designinitialdamheight"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="现状初期坝高" prop="currentinitialdamheight">
            <el-input
              v-model="addCertInfoForm.currentinitialdamheight"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="初期坝长" prop="initialdamlongness">
            <el-input
              v-model="addCertInfoForm.initialdamlongness"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="初期坝坡度" prop="initialdamsloperatio">
            <el-input
              v-model="addCertInfoForm.initialdamsloperatio"
              placeholder="例如：1：2"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="堆积坝外坡度" prop="accumulationdamratio">
            <el-input
              v-model="addCertInfoForm.accumulationdamratio"
              placeholder="例如：1：2"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="设计子坝高度" prop="designsubdamheight">
            <el-input
              v-model="addCertInfoForm.designsubdamheight"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设计子坝坝顶宽度" prop="designsubdamtopwidth">
            <el-input
              v-model="addCertInfoForm.designsubdamtopwidth"
              placeholder="单位：米"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="已堆积子坝数" prop="accumulateddamsnumber">
            <el-input
              v-model="addCertInfoForm.accumulateddamsnumber"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设防地震烈度" prop="earthquake">
            <el-input
              v-model="addCertInfoForm.earthquake"
              placeholder="单位：度"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="尾矿堆存方式" prop="tailingsstoragemethod">
            <el-select
              v-model="addCertInfoForm.tailingsstoragemethod"
              placeholder="请选择尾矿堆存方式"
            >
              <el-option label="湿式堆存" :value="1"></el-option>
              <el-option label="干式堆存" :value="2"></el-option>
              <el-option label="膏体堆存" :value="3"></el-option>
              <el-option label="其他" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="湿式放矿位置" prop="minelaytypeno">
            <el-select
              v-model="addCertInfoForm.minelaytypeno"
              placeholder="请选择湿式放矿位置"
            >
              <el-option label="坝前放矿" :value="1"></el-option>
              <el-option label="库尾放矿" :value="2"></el-option>
              <el-option label="周边放矿" :value="3"></el-option>
              <el-option label="其他" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="上游式尾矿堆筑方法"
            prop="upstreamtailings"
            label-width="160px"
          >
            <el-select
              v-model="addCertInfoForm.upstreamtailings"
              placeholder="请选择上游式尾矿堆筑方法"
            >
              <el-option label="冲积法" :value="1"></el-option>
              <el-option label="渠槽法" :value="2"></el-option>
              <el-option label="池填法" :value="3"></el-option>
              <el-option label="旋流器法" :value="4"></el-option>
              <el-option label="模袋法" :value="5"></el-option>
              <el-option label="其他" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="干式堆存方式" prop="drystorage">
            <el-select
              v-model="addCertInfoForm.drystorage"
              placeholder="请选择干式堆存方式"
            >
              <el-option label="库前" :value="1"></el-option>
              <el-option label="库尾" :value="2"></el-option>
              <el-option label="库中" :value="3"></el-option>
              <el-option label="周边" :value="4"></el-option>
              <el-option label="其他" :value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="干式堆存入库尾矿含水率"
            label-width="170px"
            prop="drystoragewaterratio"
          >
            <el-input
              v-model="addCertInfoForm.drystoragewaterratio"
              placeholder="单位：%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="尾矿粒度" prop="tailingsparticlesize">
            <el-input
              v-model="addCertInfoForm.tailingsparticlesize"
              placeholder="单位：%（-200目占比）"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="尾矿平均粒度" prop="tailingsaveparticlesize">
            <el-input
              v-model="addCertInfoForm.tailingsaveparticlesize"
              placeholder="单位：毫米"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="沉积干滩平均坡度" prop="depositiondrybeachslope">
            <el-input
              v-model="addCertInfoForm.depositiondrybeachslope"
              placeholder="单位：%"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="下游1公里内总人数"
            prop="personnum"
            label-width="160px"
          >
            <el-input
              v-model="addCertInfoForm.personnum"
              placeholder="单位：人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="下游1公里内建筑物数量"
            label-width="180px"
            prop="buildingnum"
          >
            <el-input
              v-model="addCertInfoForm.buildingnum"
              placeholder="单位：栋"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="应急预案是否备案" prop="emergencyplanfiled">
            <el-radio-group v-model="addCertInfoForm.emergencyplanfiled">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安全预评价单位" prop="primarysafetyunit">
            <el-input v-model="addCertInfoForm.primarysafetyunit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="安全预评价单位资质" prop="primarysafetylevel">
            <el-input v-model="addCertInfoForm.primarysafetylevel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安全设施设计单位" prop="designunit">
            <el-input v-model="addCertInfoForm.designunit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="安全设施设计单位资质"
            label-width="170px"
            prop="designunitlevel"
          >
            <el-input v-model="addCertInfoForm.designunitlevel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="施工单位名称" prop="constructionunit">
            <el-input v-model="addCertInfoForm.constructionunit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="施工单位资质等级" prop="constructionlevel">
            <el-input v-model="addCertInfoForm.constructionlevel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工程监理单位名称" prop="supervisoryunit">
            <el-input v-model="addCertInfoForm.supervisoryunit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="工程监理单位资质等级"
            label-width="170px"
            prop="supervisorylevel"
          >
            <el-input v-model="addCertInfoForm.supervisorylevel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安全验收评价单位" prop="safetycheckunit">
            <el-input v-model="addCertInfoForm.safetycheckunit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="安全验收评价单位资质"
            label-width="170px"
            prop="safetychecklevel"
          >
            <el-input v-model="addCertInfoForm.safetychecklevel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="安全设施竣工验收时间"
            label-width="170px"
            prop="safetyacceptancetime"
          >
            <el-date-picker
              v-model="addCertInfoForm.safetyacceptancetime"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="安全现状评价单位" prop="safetyevaluationunit">
            <el-input v-model="addCertInfoForm.safetyevaluationunit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="安全现状评价单位资质等级"
            label-width="180px"
            prop="safetyevaluationlevel"
          >
            <el-input
              v-model="addCertInfoForm.safetyevaluationlevel"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="填写企业（填写人）"
            prop="filingperson"
            label-width="160px"
          >
            <el-input
              v-model="addCertInfoForm.filingperson"
              placeholder="例如：XXX公司（张三）"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="filingdate">
            <el-date-picker
              v-model="addCertInfoForm.filingdate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="填表人电话" prop="fillerphone">
            <el-input v-model="addCertInfoForm.fillerphone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="在用状态" prop="inUse">
            <el-radio-group v-model="addCertInfoForm.inUse">
              <el-radio :label="1">在用</el-radio>
              <el-radio :label="0">不在用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注" prop="note">
            <el-input type="textarea" v-model="addCertInfoForm.note"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button v-has="'sys:project:edit'" type="primary" @click="onSubmit"
          >保存</el-button
        >
        <el-button v-has="'sys:project:edit'" @click="addCancel"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    projectInfo: Object,
  },
  inheritAttrs: false,
  data () {
    return {
      formRules: {
        no: [{ required: true, message: "请输入尾矿库编号", trigger: "blur" }],
        unifCreditCode: [{ required: true, message: "请输入统一社会信用代码", trigger: "blur" }],
        securityofficer: [{ required: true, message: "请输入安全负责人", trigger: "blur" }],
        securityofficertel: [{ required: true, message: "请输入安全负责人电话", trigger: "blur" }],
        ondutytel: [{ required: true, message: "请输入值班电话", trigger: "blur" }],
        usedate: [{ required: true, message: "请输入投入使用日期", trigger: "blur" }],
        useyear: [{ required: true, message: "请输入设计服务年限", trigger: "blur" }],
        operatingstatus: [{ required: true, message: "请输入运行状况", trigger: "blur" }],
        safetytypeno: [{ required: true, message: "请输入安全度", trigger: "blur" }],
        industrytypeno: [{ required: true, message: "请输入所属行业", trigger: "blur" }],
        mineralspecies: [{ required: true, message: "请输入所属矿种", trigger: "blur" }],
        top: [{ required: true, message: "请输入头顶库", trigger: "blur" }],
        onlinemonitoringsys: [{ required: true, message: "请输入在线监测系统", trigger: "blur" }],
        underlyingsurfacetype: [{ required: true, message: "请输入下垫面类型", trigger: "blur" }],
        safetypermitno: [{ required: true, message: "请输入安全生产许可证编号", trigger: "blur" }],
        safetypermitdateArray: [{ required: true, message: "请输入安全生产许可证有效期", trigger: "blur" }],
        safetypermitunit: [{ required: true, message: "请输入安全生产许可证发证机关", trigger: "blur" }],
        storetypeno: [{ required: true, message: "请输入尾矿库类型", trigger: "blur" }],
        designlevel: [{ required: true, message: "请输入设计等别", trigger: "blur" }],
        grade: [{ required: true, message: "请输入现状等别", trigger: "blur" }],
        designheight: [{ required: true, message: "请输入设计总坝高", trigger: "blur" }],
        stackheight: [{ required: true, message: "请输入现状总坝高", trigger: "blur" }],
        plancapability: [{ required: true, message: "请输入设计库容", trigger: "blur" }],
        nowvolume: [{ required: true, message: "请输入现状库容", trigger: "blur" }],
        damstocktypeno: [{ required: true, message: "请输入筑坝方式", trigger: "blur" }],
        floodequipment: [{ required: true, message: "请输入排洪设施型式", trigger: "blur" }],
        floodmethod: [{ required: true, message: "请输入库外排洪型式", trigger: "blur" }],
        waterarea: [{ required: true, message: "请输入汇水面积", trigger: "blur" }],
        area: [{ required: true, message: "请输入尾矿库面积", trigger: "blur" }],
        floodcontrolstandard: [{ required: true, message: "请输入设计防洪标准", trigger: "blur" }],
        damtype: [{ required: true, message: "请输入初期坝类型", trigger: "blur" }],
        initialdamtype: [{ required: true, message: "请输入初期坝坝型", trigger: "blur" }],
        designinitialdamheight: [{ required: true, message: "请输入设计初期坝高", trigger: "blur" }],
        currentinitialdamheight: [{ required: true, message: "请输入现状初期坝高", trigger: "blur" }],
        accumulationdamratio: [{ required: true, message: "请输入堆积坝外坡度", trigger: "blur" }],
        designsubdamheight: [{ required: true, message: "请输入设计子坝高度", trigger: "blur" }],
        earthquake: [{ required: true, message: "请输入设防地震烈度", trigger: "blur" }],
        tailingsstoragemethod: [{ required: true, message: "请输入尾矿堆存方式", trigger: "blur" }],
        minelaytypeno: [{ required: true, message: "请输入湿式放矿位置", trigger: "blur" }],
        upstreamtailings: [{ required: true, message: "请输入上游式尾矿堆筑方法", trigger: "blur" }],
        drystorage: [{ required: true, message: "请输入干式堆存方式", trigger: "blur" }],
        tailingsparticlesize: [{ required: true, message: "请输入尾矿粒度", trigger: "blur" }],
        depositiondrybeachslope: [{ required: true, message: "请输入沉积干滩平均坡度", trigger: "blur" }],
        personnum: [{ required: true, message: "请输入下游1公里内总人数", trigger: "blur" }],
        buildingnum: [{ required: true, message: "请输入下游1公里内建筑物数量", trigger: "blur" }],
        emergencyplanfiled: [{ required: true, message: "请输入应急预案是否备案", trigger: "blur" }],
        filingperson: [{ required: true, message: "请输入填写企业", trigger: "blur" }],
        filingdate: [{ required: true, message: "请输入填报日期", trigger: "blur" }],
        fillerphone: [{ required: true, message: "请输入填表人电话", trigger: "blur" }],
        inUse: [{ required: true, message: "请输入在用状态", trigger: "blur" }],
      },
      addCertInfoForm: {
        projectCode: "",
      },
    };
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      this.addCertInfoForm = this.projectInfo;
    },
    onSubmit () {
      this.$refs.addCertForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "project/update/cert",
            this.addCertInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.$refs.addCertForm.resetFields();
            this.handleAddSubmit();
          } else {
            this.$message.error(res.msg);
            return false;
          }
        } else {
          return false;
        }
      });
    },
    addCancel () {
      this.handleAddCancel();
    },
    handleAddSubmit () {
      this.$emit('dataSubmit')
    },
    handleAddCancel () {
      this.$emit('dataCancel')
    }
  },
};
</script>

<style lang="less" scoped>
</style>