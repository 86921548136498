<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>图片-示意图</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span class="span-title">项目列表</span>
          </div>
          <el-tree
            :data="projectList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadProjectDiagram"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header">
            <span class="span-title">示意图</span>
          </div>
          <div style="width: 670px; height: 550px">
            <v-diagram-view
              @showDeviceInfo="showDeviceInfo"
              ref="imgDiagramView"
            ></v-diagram-view>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      title="设备信息"
      :visible.sync="deviceDialogVisible"
      width="40%"
      v-if="deviceDialogVisible"
    >
      <el-row>
        <el-col :span="8">
          <img
            :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + deviceInfo.deviceImg"
            height="200"
            width="200"
            border="1"
          />
        </el-col>
        <el-col :span="16">
          <el-form :model="deviceInfo" disabled label-width="120px">
            <el-form-item label="设备名称：" prop="name">
              {{ deviceInfo.name }}
            </el-form-item>
            <el-form-item label="监测类型：" prop="deviceType">
              <span v-if="deviceInfo.deviceType == 10">干滩</span>
              <span v-if="deviceInfo.deviceType == 21">超高</span>
              <span v-if="deviceInfo.deviceType == 20">库水位</span>
              <span v-if="deviceInfo.deviceType == 30">表面位移</span>
              <span v-if="deviceInfo.deviceType == 33">表面位移</span>
              <span v-if="deviceInfo.deviceType == 40">内部测斜</span>
              <span v-if="deviceInfo.deviceType == 50">浸润线</span>
              <span v-if="deviceInfo.deviceType == 60">降雨量</span>
              <span v-if="deviceInfo.deviceType == 91">混浊度</span>
              <span v-if="deviceInfo.deviceType == 22">明渠流量</span>
            </el-form-item>
            <el-form-item label="" prop="remark" label-width="50px">
              {{ deviceInfo.remark }}
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import vDiagramView from "../dataView/DiagramView.vue";
export default {
  components: {
    vDiagramView
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      projectList: [],
      deviceDialogVisible: false,
      deviceInfo: {},
    }
  },
  created () {
    this.getProjectList();
  },
  methods: {
    async getProjectList () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data == null) {
          this.projectList = [];
          return;
        }
        this.projectList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    loadProjectDiagram (data) {
      this.$refs.imgDiagramView.getDiagramInfoByProjectCode(data.projectCode);
    },
    // 展示设备信息
    async showDeviceInfo (data) {
      // console.log(data);
      const { data: res } = await this.$http.get("device/find/simple/info", {
        params: {
          deviceCode: data
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.$message.error('找不到该设备信息.');
          return;
        }

        // console.log(res.data);
        this.deviceInfo = res.data;
        this.deviceDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>