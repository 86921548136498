<template>
  <div class="top-bar">
    <div class="top-text">
      <div class="top-left">
        <span class="date-str">{{dateStr}}</span>
        <span>{{timeStr}}</span>
      </div>
      <div class="top-middle">{{ projectInfo.projectName }}监测平台</div>
      <div class="top-right">
        <span @click="handleClick(tab)" :class="{active:value===tab.value}" v-for="tab in tabs" :key="tab.value">{{tab.label}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'TopBar',
  props:['value', 'projectInfo'],
  data(){
    return {
      timeStr:'',
      dateStr:'',
      timer:null,
      activeItem:'compony',
      tabs:[
        // {label:'公司简介',value:'compony'},
        // {label:'组织架构',value:'organize'},
        // {label:'库区历史',value:'history'},
        {label:'系统设置',value:'setting'},
      ]
    }
  },
  created(){
    this.dateStr = this.getDateStr()
    this.timeStr = this.getDateStr('{h}:{i}:{s}')
    this.getTimeStr();
  },
  beforeDestroy(){
    //页面销毁，停止定时器
    clearInterval(this.timer)
    this.timer = null
  },
  methods:{
    /**
     * 定时器获取时分秒
     */
    getTimeStr(){
      clearInterval(this.timer)
      this.timer = setInterval(()=>{
        if(this.timeStr === '23:59:59'){
          this.dateStr = this.getDateStr()
        }
        this.timeStr = this.getDateStr('{h}:{i}:{s}')
      },1000)
    },
    /**
     * 获取日期 | 时间
     */
    getDateStr(pattern){
      const format = pattern || '{y}年{m}月{d}日 · 周{a}'
      const date = new Date()
      const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
          a: date.getDay()
      }
      const dateStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key]
          if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
          if (result.length > 0 && value < 10) {
              value = '0' + value
          }
          return value || 0
      })
      return dateStr
    },
    handleClick(e){
      this.$emit('change',e)
    }
  }
}
</script>
<style lang="less" scoped>
*{box-sizing: border-box;}
.top-bar {
  position: fixed;
  width: 100%;
  min-width: 1200px;
  height: 80px;
  background: url(~@/assets/images/top_bar@2x.png) center no-repeat;
  background-size: 100% 80px;
  color: #ffffff;
  z-index: 99999;
  ul,li {
    outline-style: none;
    padding: 0;
    margin: 0;
  }
  .top-text {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
  }
  .top-left,.top-right {
    font-size: 21px;
    flex: 1;
  }
  .top-left{
    line-height: 56px;
    text-align: left;
    padding-left: 55px;
    .date-str {
      margin-right: 25px;
    }
  }
  .top-right {
    display: flex;
    padding-right:50px;
    justify-content: flex-end;
    span {
      padding: 0 12px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      margin: 13px 0px 0;
      cursor: pointer;
      &.active {
        color: #27FFBC;
        background-color:#093278;
      }
    }
  }
  .top-middle {
    width: 20%;
    flex-shrink: 1;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 68px;
    text-align: center;
  }
}
</style>