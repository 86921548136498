<template>
    <div>
        <el-tabs type="border-card">
            <el-tab-pane label="实时监测值">
                <v-shenya-mt :data="data"></v-shenya-mt>
            </el-tab-pane>
            <el-tab-pane label="实时示意图">
                <v-shenya-rt :hiddenPath="true"></v-shenya-rt>
            </el-tab-pane>
            <el-tab-pane label="历史数据">
                <v-shenya-ht></v-shenya-ht>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import vShenyaMt from '../projectRealTime/ShenyaMt.vue';
import vShenyaRt from '../../datacenter/realtime/ShenyaRt.vue';
import vShenyaHt from '../../datacenter/history/ShenyaHt.vue';
export default {
    components: {
        vShenyaMt,
        vShenyaRt,
        vShenyaHt
    },
    props: {
        data: Object
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style>

</style>