<template>
  <div>
    <el-row>
      <el-card>
        <el-divider content-position="left">
          <span style="color: rgb(158 158 158)">降雨量动态柱状图</span>
        </el-divider>
        <div style="height: 290px; overflow: hidden">
          <div id="ylChartsHour" class="chart" style="width: 99%; height: 99%"></div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <el-card>
        <el-divider content-position="left">
          <span style="color: rgb(158 158 158)">设备及预警信息</span>
        </el-divider>
        <div style="font-size: 14px; line-height: 25px">
          <el-row>
            <el-col :span="12">设备名称：{{ currentSelectNode.name }}</el-col>
            <el-col :span="12">采集间隔：{{
                currentSelectNode.collectInterval
            }}
              分钟</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">蓝色预警：{{ alarmConfig.blueRange }}小时内{{
                this.common.alarmTypeToString(
                  alarmConfig.blueAlarmValue,
                  alarmConfig.blueComparisonOperator
                )
            }}（mm）</el-col>
            <el-col :span="6">黄色预警：{{ alarmConfig.yellowRange }}小时内{{
                this.common.alarmTypeToString(
                  alarmConfig.yellowAlarmValue,
                  alarmConfig.yellowComparisonOperator
                )
            }}（mm）</el-col>
            <el-col :span="6">橙色预警：{{ alarmConfig.orangeRange }}小时内{{
                this.common.alarmTypeToString(
                  alarmConfig.orangeAlarmValue,
                  alarmConfig.orangeComparisonOperator
                )
            }}（mm）</el-col>
            <el-col :span="6">红色预警：{{ alarmConfig.redRange }}小时内{{
                this.common.alarmTypeToString(
                  alarmConfig.redAlarmValue,
                  alarmConfig.redComparisonOperator
                )
            }}（mm）</el-col>
          </el-row>
        </div>
      </el-card>
    </el-row>
  </div>
</template>
  
<script>
  export default {
    props: ["data"],
    data () {
      return {
        queryParams: {
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        deviceData: [],
        currentSelectNode: {},
        alarmConfig: {
          yellowAlarmValue: "",
          yellowComparisonOperator: null,
          orangeAlarmValue: "",
          orangeComparisonOperator: null,
          redAlarmValue: "",
          redComparisonOperator: null,
        },
        diagramChart: null,
        currentProjectInfo: {}
      }
    },
    created () {
    },
    mounted() {
        this.currentProjectInfo = this.data;
        this.queryParams.projectCode = this.data.projectCode;
        this.getDeviceList();
    },
    methods: {
      async getDeviceList () {
        const { data: res } = await this.$http.get("device/yuliang/find/page", {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: this.queryParams.projectCode
          }
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.deviceData = [];
            return;
          }
          this.deviceData = res.data.list;
          this.loadDeviceRealTimeData(this.deviceData[0]);
        } else {
          this.$message.error(res.msg);
        }
      },
      loadDeviceRealTimeData (data) {
        this.currentSelectNode = data;
        this.loadStatData(data.deviceCode);
        this.loadAlarmConfig(data.deviceCode);
      },
      loadStatData (deviceCode) {
        // 加载报表数据
        this.getDataList(deviceCode);
      },
      async loadAlarmConfig (deviceCode) {
        const { data: res } = await this.$http.get("alarm/find/config", {
          params: {
            deviceCode: deviceCode
          }
        });
        if (res.code == 0) {
          this.alarmConfig = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
      async getDataList (deviceCode) {
        const { data: res } = await this.$http.post("device/yuliang/history/data/diagram", {
          projectCode: this.queryParams.projectCode,
          deviceCode: deviceCode
        });
        if (res.code == 0) {
          this.drawData(res.data.list, res.data.alarmConfig);
        } else {
          this.$message.error(res.msg);
        }
      },
      drawData (dataList, alarmConfig) {
        // 基于准备好的dom，初始化echarts实例
        if (this.diagramChart == null) {
          this.diagramChart = this.$echarts.init(
            document.getElementById("ylChartsHour")
          );
        }
  
        if (dataList == null || dataList.length == 0) {
          this.diagramChart.clear();
          return;
        }
  
        let yellowData = [];
        let orangeData = [];
        let redData = [];
  
        // 无限接近0的值
        let valueData = [];
        let timeData = [];
        let devName = this.currentSelectNode.name;
        let devTime = "";
        let yltotal = 0;
        for (let i = 0; i < dataList.length; i++) {
          let v = this.common.floatFormat(dataList[i].totalValue, 4);
  
          valueData.push(v);
          yltotal += v;
          timeData.push(dataList[i].timeText);
          devTime = dataList[i].timeText;
        }
  
        // Y轴类型
        let yAxisType = '';
        if (yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
          // 有预警线的类型
          yAxisType = 'log';
        } else {
          yAxisType = 'value';
        }
  
        let option = {
          title: {
            text: "降雨量实时监测柱状图（单位：mm）",
            textStyle: {
              fontSize: 14,
            },
            subtext: "设备：" + devName + ", 总量：" + this.common.floatFormat(yltotal, 2) + "（mm）, 更新时间：" + devTime,
            subtextStyle: {
              fontSize: 12,
            }
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [],
          },
          grid: {
            top: "90",
            bottom: "30",
            left: "60",
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            data: timeData,
          },
          yAxis: {
            type: yAxisType,
            axisLabel: {
              formatter: '{value} mm'
            },
          },
          series: [
            {
              name: "降雨量",
              type: "bar",
              data: valueData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [
                  { type: 'average', name: '平均值' }
                ]
              }
            },
          ],
        };
  
        // 绘制图表
        this.diagramChart.clear();
        this.diagramChart.setOption(option);
      },
    }
  }
  </script>
<style lang="less" scoped>
  </style>