<template>
  <div>
    <el-card>
        <div style="height: 660px; overflow: auto;">
            <el-divider content-position="left">干滩监测</el-divider>
            <v-gantan-mt :data="projectInfo"></v-gantan-mt>
            <el-divider content-position="left">水位监测</el-divider>
            <v-shuiwei-mt :data="projectInfo"></v-shuiwei-mt>
            <el-divider content-position="left">超高监测</el-divider>
            <v-chaogao-mt :data="projectInfo"></v-chaogao-mt>
            <el-divider content-position="left">雨量监测</el-divider>
            <v-yuliang-mt :data="projectInfo"></v-yuliang-mt>
            <el-divider content-position="left">浸润线监测</el-divider>
            <v-shenya-mt :data="projectInfo"></v-shenya-mt>
            <el-divider content-position="left">浊度监测</el-divider>
            <v-zhuodu-mt :data="projectInfo"></v-zhuodu-mt>
            <el-divider content-position="left">内部位移监测</el-divider>
            <v-cexie-mt :data="projectInfo"></v-cexie-mt>
            <el-divider content-position="left">表面位移监测</el-divider>
            <v-quanzhan-mt :data="projectInfo"></v-quanzhan-mt>
        </div>
    </el-card>
  </div>
</template>

<script>
import vGantanMt from './projectRealTime/GantanMt.vue';
import vYuliangMt from './projectRealTime/YuliangMt.vue';
import vShuiweiMt from './projectRealTime/ShuiweiMt.vue';
import vShenyaMt from './projectRealTime/ShenyaMt.vue';
import vChaogaoMt from './projectRealTime/ChaogaoMt.vue';
import vZhuoduMt from './projectRealTime/ZhuoduMt.vue';
import vCexieMt from './projectRealTime/CexieMt.vue';
import vQuanzhanMt from './projectRealTime/QuanzhanMt.vue';
export default {
    components: {
        vGantanMt,
        vYuliangMt,
        vShuiweiMt,
        vShenyaMt,
        vChaogaoMt,
        vZhuoduMt,
        vCexieMt,
        vQuanzhanMt
    },
    props:{
        projectInfo: Object
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style>

</style>