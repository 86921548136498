<template>
  <div class="wrapper">
    <v-head></v-head>
    <v-sidebar></v-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <v-tags></v-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <keep-alive :include="tagsList">
            <router-view></router-view>
          </keep-alive>
        </transition>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
    <v-ws :clientTypeParam="1"></v-ws>
    <audio id="alarmAudio" src="../../assets/alarm.wav" muted="muted" />
  </div>
</template>

<script>
import vHead from "./Header.vue";
import vSidebar from "./Sidebar.vue";
import vTags from "./Tags.vue";
import vWs from "./webSocket.vue";
import bus from "./bus";
export default {
  data () {
    return {
      tagsList: [],
      collapse: false,
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags,
    vWs,
  },
  destroyed () {
    bus.$off("alarm-msg-event");
    bus.$off("collapse-content");
    bus.$off("tags");
  },
  created () {
    bus.$on("collapse-content", (msg) => {
      this.collapse = msg;
    });

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on("tags", (msg) => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });

    bus.$on("alarm-msg-event", (msg) => {
      console.log(msg);
      if (msg.type === '10010101') {
        // 黄色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010001') {
        // console.log("==============");
        // 蓝色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010201') {
        // console.log("==============");
        // 橙色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010301') {
        // 红色预警
        this.$notify.error({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          duration: msg.data.duration * 1000,
          onClose: function () {
            var audio = document.getElementById("alarmAudio");
            if (!audio.paused) {
              audio.pause();
              // console.log("停止播放.");
            }
          }
        });
        this.alarm();
      } else if (msg.type === '20010000') {
        // 通知
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      }
    });
  },
  methods: {
    alarm () {
      var audio = document.getElementById("alarmAudio");
      if (audio.paused) {
        audio.play();
        audio.loop = true;
        audio.preload = true;
      } else {
        // console.log("已经在播放.");
      }
    },
  }
};
</script>
<style lang="less" scoped>
</style>
