<template>
  <div>
    <v-cexie-main v-if="data.type === 40" :data="data"></v-cexie-main>
    <v-gnss-main v-else-if="data.type === 33" :data="data"></v-gnss-main>
    <v-mingqu-main v-else-if="data.type === 22" :data="data"></v-mingqu-main>
    <v-quanzhan-main v-else-if="data.type === 32" :data="data"></v-quanzhan-main>
    <v-shenya-main v-else-if="data.type === 50" :data="data"></v-shenya-main>
    <v-wuwei-main v-else-if="data.type === 20" :data="data"></v-wuwei-main>
    <v-yuliang-main v-else-if="data.type === 60" :data="data"></v-yuliang-main>
    <v-zhuodu-main v-else-if="data.type === 91" :data="data"></v-zhuodu-main>
    <v-gantan-main v-else-if="data.type === 10" :data="data"></v-gantan-main>
    <v-chaogao-main v-else-if="data.type === 21" :data="data"></v-chaogao-main>
  </div>
</template>

<script>
import vCexieMain from "./cexie/CexieMain.vue";
import vGnssMain from "./gnss/GnssMain.vue";
import vMingquMain from "./mingqu/MingquMain.vue";
import vQuanzhanMain from "./quanzhan/QuanzhanMain.vue";
import vShenyaMain from "./shenya/ShenyaMain.vue";
import vWuweiMain from "./wuwei/WuweiMain.vue";
import vYuliangMain from "./yuliang/YuliangMain.vue";
import vZhuoduMain from "./zhuodu/ZhuoduMain.vue";
import vGantanMain from "./gantan/GantanMain.vue";
import vChaogaoMain from "./chaogao/ChaogaoMain.vue";
export default {
  props:['data'],
  components: {
    vCexieMain,
    vGnssMain,
    vMingquMain,
    vQuanzhanMain,
    vShenyaMain,
    vWuweiMain,
    vYuliangMain,
    vZhuoduMain,
    vGantanMain,
    vChaogaoMain
  },
  data() {
    return {

    }
  },
  created() {
    this.showInfo(this.data);
  },
  watch: {
    data(value) {
      this.showInfo(value);
    }
  },
  methods: {
    showInfo(data) {
      // console.log(data);
    }
  }
}
</script>

<style>

</style>