<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>智能巡检</el-breadcrumb-item>
      <el-breadcrumb-item>巡检记录</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getCheckLogsDate"
            @change="getCheckLogsDate"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="18">
          <el-date-picker
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate">
          </el-date-picker>
          <el-button
            @click="exportDevices"
            style="margin-left: 10px"
            type="success"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="5" style="margin-top: 10px">
      <el-col :span="3">
        <el-card>
          <div slot="header">
            <span>巡检日志</span>
          </div>
          <div class="logs-list">
            <div v-for="item in checkDateList" :key="item.id" class="date-record" @click="loadCheckItems(item)">
              {{item.checkDate}}
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card>
          <div slot="header">
            <span>巡检项（{{checkRecords.length}}/{{normalItemSize}}）</span>
          </div>
          <div class="check-list">
            <div v-for="item in checkRecords" :key="item.id" class="item-record" @click="centerthis(item)">
              <div style="width:100%;height: 28px; font-size: 18px;font-weight: bold;color: #409EFF;overflow: hidden;">{{item.checkItemName}}</div>
              <div style="width:100%;height: 16px; font-size: 12px;color: #909399;overflow: hidden;">{{item.checkTypeName}}</div>
              <div style="width:100%;height: 16px; font-size: 12px;color: #909399;overflow: hidden;">{{item.checkTime}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div class="marker-map">
            <v-check-log-map ref="logMapRef" @onShowLogDetail="showLogDetail"></v-check-log-map>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-drawer
      v-if="checkLogDetailDrawer"
      title="巡检记录详情"
      :visible.sync="checkLogDetailDrawer"
      size="40%">
      <v-check-detail ref="logdetailRef" :logdetail="selectLogdetail"></v-check-detail>
    </el-drawer>
  </div>
</template>

<script>
import vCheckLogMap from "./CheckLogMap.vue";
import vCheckDetail from "./CheckDetail.vue";
export default {
  components: {
    vCheckLogMap,
    vCheckDetail
  },
  data () {
    return {
      showDownloadVisible: false,
      checkLogDetailDrawer: false,
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
      },
      projectInfoList: [],
      timeRange: [],
      checkDateList: [],
      checkRecords: [],
      normalItemSize: 0,
      selectLogdetail: {}
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getCheckLogsDate() {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("check/data/date", this.queryParams);
      if (res.code == 0) {
        this.checkDateList = res.data;
        this.changeProjectSelect();
        this.loadAllNormalItems(this.queryParams.projectCode);
      } else {
        this.$message.error(res.msg);
      }
    },
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getCheckLogsDate();
      }
    },
    exportDevices() {

    },
    async loadCheckItems(data) {
      const { data: res } = await this.$http.get("check/records", {
        params: {
          checkGroupCode: data.checkGroupCode
        }
      });
      if (res.code == 0) {
        this.checkRecords = res.data;
        this.showCheckLogsInMap();
      } else {
        this.$message.error(res.msg);
      }
    },
    getProject(code) {
        var pro = null;
        this.projectInfoList.forEach(p => {
          if (code === p.projectCode) {
            pro = p;
          }
        });
        return pro;
    },
    async loadAllNormalItems(projectCode) {
      const { data: res } = await this.$http.get("check/all/items", {
        params: {
          projectCode: projectCode
        }
      });
      if (res.code == 0) {
        this.normalItemSize = res.data.length;
      } else {
        this.$message.error(res.msg);
      }
    },
    changeProjectSelect() {
      var pro = this.getProject(this.queryParams.projectCode);
      this.$refs.logMapRef.changeCenter(pro);
    },
    showCheckLogsInMap() {
      this.$refs.logMapRef.showLogMarker(this.checkRecords);
    },
    centerthis(item) {
      this.$refs.logMapRef.movetocenter(item);
    },
    showLogDetail(data) {
      this.selectLogdetail = data;
      this.checkLogDetailDrawer = true;
    }
  }
}
</script>

<style lang="less" scoped>
.logs-list {
  height: calc(100vh - 335px);
  overflow-y: auto;
  overflow-x: hidden;
}
.check-list {
  height: calc(100vh - 335px);
  overflow-y: auto;
  overflow-x: hidden;
}
.marker-map {
  height: calc(100vh - 275px);
}
.date-record {
  width: 100%;
  height: 40px;
  padding: 5px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
  cursor: pointer;
  color: #409EFF;
}
.date-record:hover {
  background-color: #EBEEF5;
}
.item-record {
  width: 100%;
  height: 60px;
  padding: 5px;
  margin-bottom: 3px;
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
}
.item-record:hover {
  background-color: #EBEEF5;
}
</style>