<template>
    <div>
    <el-table :data="dataInfoList" border>
        <el-table-column label="监测点位" prop="dwName"></el-table-column>
        <el-table-column label="△X（mm）" prop="gapx"></el-table-column>
        <el-table-column label="△Y（mm）" prop="gapy"></el-table-column>
        <el-table-column label="△Z（mm）" prop="gapz"></el-table-column>
        <el-table-column label="水平矢量（mm）">
            <template slot-scope="scope">
                {{common.weiyiSl(scope.row.gapx, scope.row.gapy)}}
            </template>
        </el-table-column>
        <el-table-column label="变化方向">
            <template slot-scope="scope">
                {{common.weiyisp(scope.row.gapx, scope.row.gapy)}},
                {{common.weiyicz(scope.row.gapz)}}
            </template>
        </el-table-column>
        <!-- <el-table-column label="蓝色预警">
            <template slot-scope="scope">
                <span v-if="scope.row.alarms === null || scope.row.alarms.blueAlarmValue === null">未设置</span>
                <span v-else>
                    {{
                    common.alarmTypeToString(
                    scope.row.alarms.blueAlarmValue,
                    scope.row.alarms.blueComparisonOperator
                    )
                    }}（m）
                </span>
            </template>
        </el-table-column>
        <el-table-column label="黄色预警">
            <template slot-scope="scope">
                <span v-if="scope.row.alarms === null || scope.row.alarms.yellowAlarmValue === null">未设置</span>
                <span v-else>
                    {{
                    common.alarmTypeToString(
                    scope.row.alarms.yellowAlarmValue,
                    scope.row.alarms.yellowComparisonOperator
                    )
                    }}（m）
                </span>
            </template>
        </el-table-column>
        <el-table-column label="橙色预警">
            <template slot-scope="scope">
                <span v-if="scope.row.alarms === null || scope.row.alarms.orangeAlarmValue === null">未设置</span>
                <span v-else>
                    {{
                    common.alarmTypeToString(
                    scope.row.alarms.orangeAlarmValue,
                    scope.row.alarms.orangeComparisonOperator
                    )
                    }}（m）
                </span>
            </template>
        </el-table-column>
        <el-table-column label="红色预警">
            <template slot-scope="scope">
                <span v-if="scope.row.alarms === null || scope.row.alarms.redAlarmValue === null">未设置</span>
                <span v-else>
                    {{
                    common.alarmTypeToString(
                    scope.row.alarms.redAlarmValue,
                    scope.row.alarms.redComparisonOperator
                    )
                    }}（m）
                </span>
            </template>
        </el-table-column> -->
        <el-table-column label="设备状态" width="80px">
            <template slot-scope="scope">
                <el-tag v-if="common.outOfTime(scope.row.collectTime)" type="danger">异常</el-tag>
                <el-tag v-else type="success">正常</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="监测时间" prop="collectTime" width="160px"></el-table-column>
      </el-table>
  </div>
  </template>
  
  <script>
  export default {
      props: ["data"],
      data() {
          return {
              dataInfoList: []
          }
      },
      created() {
  
      },
      mounted() {
          this.getDataList();
      },
      methods: {
          async getDataList() {
              const { data: res } = await this.$http.get("device/qz/dw/find/latest/dwdata", {
                  params: {
                    projectCode: this.data.projectCode
                  }
              });
              if (res.code == 0) {
                  this.dataInfoList = res.data;
              } else {
                  this.$message.error(res.msg);
              }
          },
      }
  }
  </script>
  
  <style>
  
  </style>