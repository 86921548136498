<template>
  <div class="module-item rain_bg">
    <SvgIcon iconClass="rainfall_module_bkg" />
    <div class="module-title rain">
      <span>设备异常统计</span>
      <SvgIcon iconClass="monitoring_module_b_title" />
    </div>
    <div class="pies">
      <PieChart v-has="'sys:mtr:cexie'"
        :color="['#27FFBC', '#ff6541']"
        title="内部位移"
        :pieData="data.cexie"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
      <PieChart v-has="'sys:mtr:shenya'"
        :color="['#27FFBC', '#ff6541']"
        title="浸润线"
        :pieData="data.shenya"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
      <PieChart v-has="'sys:mtr:quanzhan'"
        :color="['#27FFBC', '#ff6541']"
        title="表面位移"
        :pieData="data.quanzhan"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
    </div>
    <div class="pies2">
      <PieChart v-has="'sys:mtr:zhuodu'"
        :color="['#27FFBC', '#ff6541']"
        title="浑浊度"
        :pieData="data.zhuodu"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
      <PieChart v-has="'sys:mtr:yuliang'"
        :color="['#27FFBC', '#ff6541']"
        title="雨量"
        :pieData="data.yuliang"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
      <PieChart v-has="'sys:mtr:wuwei'"
        :color="['#27FFBC', '#ff6541']"
        title="库水位"
        :pieData="data.wuwei"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
      <PieChart v-has="'sys:mtr:mingqu'"
        :color="['#27FFBC', '#ff6541']"
        title="明渠流量"
        :pieData="data.mingqu"
        :titleColor="'#FFFFFF'"
        :textColor="'#FFFFFF'"
        @showPieDetail="showDeviceDetail"
      />
    </div>
  </div>
</template>
<script>
import PieChart from "../charts/pieChart.vue";
export default {
  name: "DeviceModule",
  components: { PieChart },
  props: ["data"],
  data() {
    return {};
  },
  methods: {
    showDeviceDetail(data) {
      this.$emit("showPieDetail", data);
    }
  }
};
</script>
<style lang="less" scoped>
.module-item {
  // padding: 0 40px 0 20px;
  box-sizing: border-box;
  height: 250px;
  margin-bottom: 10px;
  position: relative;
  svg {
    position: absolute;
    width: 100%;
    height: 250px;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .module-title {
    position: absolute;
    top: -23px;
    left: 97px;
    width: 248px;
    height: 50px;
    text-align: center;
    svg {
      position: absolute;
      height: 50px;
      z-index: 0;
    }
    span {
      font-size: 23px;
      line-height: 50px;
      color: #ffffff;
      position: relative;
      padding-left: 30px;
      z-index: 2;
      &::before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        background: url(~@/assets/images/icon_equipment.png) center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -3px;
        top: 0px;
      }
    }
  }

  .pies {
    width: 90%;
    padding-left: 20px;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
  }
  .pies2 {
    width: 100%;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
  }
}
</style>