<template>
  <div>
    <v-duanmian-info :data="data" v-if="data.groupShow === 1"></v-duanmian-info>
    <v-tab-device-info :data="data" v-else></v-tab-device-info>
  </div>
</template>

<script>
import vTabDeviceInfo from "./TabDeviceInfo.vue";
import vDuanmianInfo from "./DuanmianInfo.vue";
export default {
  props:['data'],
  components: {
    vTabDeviceInfo,
    vDuanmianInfo
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    
  }
}
</script>

<style>

</style>