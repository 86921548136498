<template>
    <div ref="gantanChartView" style="height: 100%; width: 100%"></div>
</template>
  
<script>
export default {
    inheritAttrs: false,
    data() {
        return {
            gantanChart: null,
        };
    },
    methods: {
        async drawDiagram(projectCode, deviceCode) {
            const { data: res } = await this.$http.get("device/gantan/find/mnr/data", {
                params: {
                    projectCode: projectCode,
                    deviceCode: deviceCode
                }
            });
            if (res.code != 0) {
                this.$message.error(res.msg);
                return;
            }

            var rsData = res.data;

            let that = this;
            if (this.gantanChart == null) {
                // this.gantanChart = this.$echarts.init(document.getElementById("gantanChartView"));
                this.gantanChart = this.$echarts.init(this.$refs.gantanChartView);
            }

            const shuimianPointData = [];  // 库水区点位
            const tanmianPointData = [[850, 34.5], [1180, 34.5], [1180, 35], [850, 35]]; // 滩面点位
            const tanmianLinePointData = [[1180, 35], [1180, 30], [1180, 30], [1180, 35]]; // 滩面线条
            const bartiPointData = [[0, 0], [1500, 15], [1500, 25], [850, 35], [800, 35]];  // 大坝点位

            // 构建坝面
            var size = 8;
            var diagramBarLvWidth = 15;// 坝面宽度

            var wi = 630 / size; // 设备x轴间隔
            var hi = 28 / (size + 1);  // 坝面y轴间隔
            for (let index = 0; index < size; index++) {
                var len = 800 - (index + 1) * wi;
                var h = 35 - (index + 1) * hi;
                bartiPointData.push([len, h]);
                bartiPointData.push([len - diagramBarLvWidth, h]);
            }

            bartiPointData.push([40, 5]);
            shuimianPointData.push([1180, 30], [1500, 25], [1500, 30]);

            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return '';
                    }
                },
                grid: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                },
                legend: {
                    data: []
                },
                xAxis: {
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    show: false,
                    max: 60,
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                graphic: [],
                series: [
                    {
                        id: 'shuimian',
                        name: 'shuimian',
                        type: 'custom',
                        z: 1,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < shuimianPointData.length; i++) {
                                points.push(api.coord(shuimianPointData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: color,
                                    stroke: that.$echarts.color.lift(color, 0.1)
                                })
                            };
                        },
                        clip: false,
                        data: shuimianPointData,
                        itemStyle: {
                            color: 'rgb(140, 197, 255)'
                        },
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: function (param) {
                                return '库水位：' + rsData.shuiwei + 'm';
                            }
                        },
                        tooltip: {
                            formatter: function (param) {
                                return '';
                            }
                        }
                    },
                    {
                        id: 'tanmian',
                        name: 'tanmian',
                        type: 'custom',
                        z: 99,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < tanmianPointData.length; i++) {
                                points.push(api.coord(tanmianPointData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: color,
                                    stroke: that.$echarts.color.lift(color, 0.1)
                                })
                            };
                        },
                        clip: false,
                        data: tanmianPointData,
                        itemStyle: {
                            color: that.gantanColor(rsData.alarmConfig, rsData.gantan)
                        },
                        tooltip: {
                            formatter: function (param) {
                                var info = '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 184, 36);"></span><span style="color:rgb(255, 184, 36);">黄色报警：' + that.haveAlarmConfig(rsData.alarmConfig, 'yellow') + '</span><br/>' +
                                    '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 112, 65);"></span><span style="color:rgb(255, 112, 65);">橙色报警：' + that.haveAlarmConfig(rsData.alarmConfig, 'orange') + '</span><br/>' +
                                    '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 77, 107);"></span><span style="color:rgb(255, 77, 107);">红色报警：' + that.haveAlarmConfig(rsData.alarmConfig, 'red') + '</span>';
                                return info;
                            }
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            fontSize: 12,
                            offset: [0, -20],
                            color: '#E6A23C',
                            formatter: function (param) {
                                return '干滩长度：' + rsData.gantan + 'm \n坡比：' + rsData.mpobi;
                            }
                        },
                    },
                    {
                        id: 'tanmian2',
                        name: 'tanmian2',
                        type: 'custom',
                        z: 99,
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < tanmianLinePointData.length; i++) {
                                points.push(api.coord(tanmianLinePointData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: color,
                                    stroke: that.$echarts.color.lift(color, 0.1)
                                })
                            };
                        },
                        clip: false,
                        data: tanmianLinePointData,
                        itemStyle: {
                            color: that.gantanColor(rsData.alarmConfig, rsData.gantan),
                            borderType: 'dashed',
                            borderDashOffset: 5
                        },
                    },
                    {
                        id: 'barti',
                        name: 'barti',
                        type: 'custom',
                        renderItem: function (params, api) {
                            if (params.context.rendered) {
                                return;
                            }
                            params.context.rendered = true;
                            let points = [];
                            for (let i = 0; i < bartiPointData.length; i++) {
                                points.push(api.coord(bartiPointData[i]));
                            }
                            let color = api.visual('color');
                            return {
                                type: 'polygon',
                                transition: ['shape'],
                                shape: {
                                    points: points
                                },
                                style: api.style({
                                    fill: color,
                                    stroke: that.$echarts.color.lift(color, 0.1)
                                })
                            };
                        },
                        data: bartiPointData,
                        itemStyle: {
                            color: 'rgb(145, 102, 64)'
                        },
                        label: {
                            show: true,
                            fontSize: 12,
                            position: 'outside',
                            color: '#ffdd5f',
                            offset: [-30, 0],
                            formatter: function (param) {
                                return '滩顶高程：' + rsData.gaocheng + 'm';
                            }
                        },
                        tooltip: {
                            formatter: function (param) {
                                return '';
                            }
                        }
                    },
                ]
            };

            this.gantanChart.clear();
            this.gantanChart.setOption(option);
        },
        haveAlarmConfig(alarms, type) {
            var result = '未设置';
            if (type === 'blue') {
                if (alarms != null && alarms.blueAlarmValue != null) {
                    result = alarms.blueAlarmValue + 'm';
                }
            } else if (type === 'yellow') {
                if (alarms != null && alarms.yellowAlarmValue != null) {
                    result = alarms.yellowAlarmValue + 'm';
                }
            } else if (type === 'orange') {
                if (alarms != null && alarms.orangeAlarmValue != null) {
                    result = alarms.orangeAlarmValue + 'm';
                }
            } else if (type === 'red') {
                if (alarms != null && alarms.redAlarmValue != null) {
                    result = alarms.redAlarmValue + 'm';
                }
            }

            return result;
        },
        // 获取干滩的颜色
        gantanColor(alarms, kgvalue) {
            let cl = "rgb(192, 196, 204)";
            if (alarms != null && alarms.redAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.redAlarmValue, 2)) {
                // 红色报警
                cl = "rgb(255, 77, 107)";
            } else if (alarms != null && alarms.orangeAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.orangeAlarmValue, 2)) {
                // 橙色报警
                cl = "rgb(255, 112, 65)";
            } else if (alarms != null && alarms.yellowAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.yellowAlarmValue, 2)) {
                // 黄色报警
                cl = "rgb(255, 184, 36)";
            } else if (alarms != null && alarms.blueAlarmValue != null && this.common.floatFormat(kgvalue, 2) <= this.common.floatFormat(alarms.blueAlarmValue, 2)) {
                // 蓝色报警
                cl = "rgb(25, 165, 255)";
            }

            return cl;
        }
    },

};
</script>