<template>
  <div class="ws"></div>
</template>

<script>
import bus from "./bus";
export default {
  props: {
    clientTypeParam: Number
  },
  data() {
    return {
      websock: null,
      heartbeatTimer: null,
      helloMsg: {
        clientType: this.clientType,
        type: "90000",
      },
    };
  },
  created() {
    this.initWebSocket();
    // 1分钟发送一次心跳
    this.heartbeatTimer = setInterval(this.wsHeartbeat, 1000 * 60);
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
    clearInterval(this.heartbeatTimer);
  },
  methods: {
    initWebSocket() {
      //初始化weosocket
      const wsuri = this.COMMON_CONFIG.WS_HOST + this.clientTypeParam;
      let token = localStorage.getItem('token');
      this.websock = new WebSocket(wsuri + '?token=' + token);
      // this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      this.websock.onmessage = this.websocketonmessage;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.websocketsend(JSON.stringify(this.helloMsg));
      console.log("ws初始化...");
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      // console.log('接收到的');
      // console.log(redata);
      if (redata.type === '10090000') {
        // 消息总数，通知header
        this.totalMsgHandler(redata);
      } else if (redata.type === '10010001' || redata.type === '10010101' || redata.type === '10010201' || redata.type === '10010301') {
        // 通知预警
        this.alarmMsgHandler(redata);
      } else if (redata.type === '20010000') {
        this.alarmMsgHandler(redata);
      }
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
    wsHeartbeat() {
      if (this.websock) {
        // console.log("发起心跳.");
        this.websocketsend(JSON.stringify(this.helloMsg));
      }
    },
    totalMsgHandler(msg) {
      bus.$emit("total-msg-event", msg);
    },
    alarmMsgHandler(msg) {
      bus.$emit("alarm-msg-event", msg);
    }
  },
};
</script>
<style lang='less'>
</style>
