<template>
    <div>
        <el-row>
            <el-card>
                <el-divider content-position="left">
                    <span style="color: rgb(158 158 158)">降雨量分析（分析时间为T+1）</span>
                </el-divider>
                <div style="height: 330px; overflow: hidden">
                    <div id="ylChartsMonth" class="chart" style="width: 100%; height: 330px"></div>
                </div>
            </el-card>
        </el-row>
        <el-row>
            <el-card>
                <el-row :gutter="5">
                    <el-col :span="12">
                        年份：<br />
                        <el-checkbox-group v-model="checkYearAry" @change="loadStatisData">
                            <el-checkbox checked v-for="item in dataYears" :label="item" :key="item">{{ item }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="12">
                        <div style="font-size: 14px; line-height: 30px">
                            统计维度：<br />
                            <el-radio v-model="statisDate" :label="1" @change="loadStatisData">年</el-radio>
                            <el-radio v-model="statisDate" :label="2" @change="loadStatisData">月</el-radio>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </el-row>
    </div>
</template>
  
<script>
  export default {
    props: ["data"],
    data () {
      return {
        queryParams: {
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        projectInfoList: [],
        deviceData: [],
        currentSelectNode: {},
        statisDate: 1,
        statisDatas: [],
        dataYears: [],
        checkYearAry: [],
        currentProjectInfo: {}
      }
    },
    created () {
      this.getDataYears();
    },
    mounted() {
        this.currentProjectInfo = this.data;
        this.queryParams.projectCode = this.data.projectCode;
        // this.getDeviceList();
    },
    methods: {
      async getDataYears () {
        const { data: res } = await this.$http.get("device/yuliang/find/years/list");
        if (res.code == 0) {
          this.dataYears = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
      async getDeviceList () {
        const { data: res } = await this.$http.get("device/yuliang/find/page", {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: this.queryParams.projectCode
          }
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.deviceData = [];
            return;
          }
          this.deviceData = res.data.list;
          this.changeDeviceTree(this.deviceData[0]);
        } else {
          this.$message.error(res.msg);
        }
      },
      changeDeviceTree (data) {
        this.currentSelectNode = data;
        this.loadStatisData();
      },
      loadStatisData() {
        if (this.statisDate == 1) {
          // 年的数据
          this.loadYearData();
        } else {
          // 月的数据
          this.loadMonthData();
        }
      },
      async loadYearData() {
        if(this.currentSelectNode.deviceCode == null) {
          this.$message.error('请选择一个设备');
          return;
        }
        const { data: res } = await this.$http.post("device/yuliang/statis/year", {
          deviceCode: this.currentSelectNode.deviceCode,
          years: this.checkYearAry
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.statisDatas = [];
            return;
          }
          this.statisDatas = [];
          this.statisDatas = res.data;
          this.drawYearData(res.data);
        } else {
          this.$message.error(res.msg);
        }
      },
      async loadMonthData() {
        if(this.currentSelectNode.deviceCode == null) {
          this.$message.error('请选择一个设备');
          return;
        }
        const { data: res } = await this.$http.post("device/yuliang/statis/month", {
          deviceCode: this.currentSelectNode.deviceCode,
          years: this.checkYearAry
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.statisDatas = [];
            return;
          }
          this.statisDatas = [];
          this.statisDatas = res.data;
          this.drawMonthData(res.data);
        } else {
          this.$message.error(res.msg);
        }
      },
      drawYearData (dataList) {
        // 基于准备好的dom，初始化echarts实例
        if (this.diagramChart == null) {
          this.diagramChart = this.$echarts.init(
            document.getElementById("ylChartsMonth")
          );
        }
  
        if (dataList == null || dataList.length == 0) {
          this.diagramChart.clear();
          return;
        }
  
        // 无限接近0的值
        let valueData = [];
        let timeData = [];
        let devName = this.currentSelectNode.name;
        for (let i = 0; i < dataList.length; i++) {
          let v = this.common.floatFormat(dataList[i].totalValue, 4);
          if (v == 0) {
            // 过滤0的值
            continue;
          } else {
            valueData.push(v);
          }
  
          timeData.push(dataList[i].stYear);
        }
  
        // Y轴类型
        let yAxisType = 'value';
        let option = {
          title: {
            text: "降雨量柱状图（单位：mm）",
            textStyle: {
              fontSize: 14,
            },
            subtext: "设备：" + devName,
            subtextStyle: {
              fontSize: 12,
            }
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [],
          },
          grid: {
            top: "90",
            bottom: "50",
            left: "50",
            right: "0",
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            data: timeData,
          },
          yAxis: {
            type: yAxisType,
            axisLabel: {
              formatter: '{value} mm'
            },
          },
          series: [
            {
              name: "降雨量",
              type: "bar",
              data: valueData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [
                  { type: 'average', name: '平均值' }
                ]
              }
            },
          ],
        };
  
        // 绘制图表
        this.diagramChart.clear();
        this.diagramChart.setOption(option);
      },
      drawMonthData (dataMap) {
        // 获取年份
        var yary = Object.keys(dataMap);
        if (this.diagramChart == null) {
          this.diagramChart = this.$echarts.init(
            document.getElementById("diagramChart")
          );
        }
  
        var seriesAry = [];
        for (let index = 0; index < yary.length; index++) {
          const y = yary[index];
          var data = dataMap[y];
          var month = new Array(12).fill(0);
          for (let iy = 0; iy < data.length; iy++) {
            const d = data[iy];
            month[d.stMonth - 1] = d.totalValue;
          }
          seriesAry.push({
              name: y,
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              data: month
          });
        }
  
        let devName = this.currentSelectNode.name;
        var option;
        option = {
          title: {
            text: "降雨量柱状图（单位：mm）",
            textStyle: {
              fontSize: 14,
            },
            subtext: "设备：" + devName,
            subtextStyle: {
              fontSize: 12,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: yary
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            top: "90",
            bottom: "50",
            left: "50",
            right: "30",
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                formatter: '{value} mm'
              },
            }
          ],
          series: seriesAry
        };
  
        // 绘制图表
        this.diagramChart.clear();
        this.diagramChart.setOption(option);
      }
    }
  }
  </script>
  
<style lang="less" scoped>
  </style>