<template>
  <div>
    <el-descriptions :column="1" size="medium" border labelClassName="label-class">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-menu"></i>
          巡检类型
        </template>
        {{checklogInfo.checkTypeName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-grid"></i>
          巡检项
        </template>
        {{checklogInfo.checkItemName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-document"></i>
          巡检内容
        </template>
        {{checkItemInfo.checkContent}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          巡检员
        </template>
        {{checklogInfo.userName}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-time"></i>
          巡检时间
        </template>
        {{checklogInfo.checkTime}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-medal"></i>
          巡检结果
        </template>
        {{checklogInfo.checkResult}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-odometer"></i>
          巡检情况
        </template>
        {{checklogInfo.checkContent}}
      </el-descriptions-item>
    </el-descriptions>
    <el-carousel :interval="5000" arrow="always" v-if="imageUrls.length > 0">
      <el-carousel-item v-for="item in imageUrls" :key="item">
        <el-image :src="item" fit="cover" :preview-src-list="imageUrls"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    logdetail: Object
  },
  inheritAttrs: false,
  data() {
    return {
      checklogInfo: {},
      checkItemInfo: {},
      imageUrls: []
    }
  },
  created() {
    this.showCheckLogDetail(this.logdetail);
  },
  methods: {
    showCheckLogDetail(data) {
      if (data == null) {
        return;
      }

      this.findItemInfo(data);
      this.findLogInfo(data);
    },
    async findLogInfo(data) {
      const { data: res } = await this.$http.get("check/find/bycode", {
        params: {
          checkCode: data.checkCode
        }
      });
      if (res.code == 0) {
        this.checklogInfo = res.data;
        this.createImageUrls();
      } else {
        this.$message.error(res.msg);
      }
    },
    async findItemInfo(data) {
      const { data: res } = await this.$http.get("check/find/item/code", {
        params: {
          itemCode: data.checkItemCode
        }
      });
      if (res.code == 0) {
        this.checkItemInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    createImageUrls() {
      var im = (this.checklogInfo.imgUrl).split(",");
      this.imageUrls = im;
    }
  }
}
</script>

<style>
/* .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
.label-class {
  width: 120px;
}
</style>