<template>
    <div class="gantan-chart-v">
      <v-gantan-diagram ref="gantanChartRef"></v-gantan-diagram>
    </div>
  </template>
  
  <script>
  import vGantanDiagram from "../../datacenter/realtime/GantanDiagram.vue";
  export default {
      components: {
          vGantanDiagram,
      },
      data() {
          return {
              projectInfo: null,
              deviceInfo: null
          }
      },
      created() {
  
      },
      mounted() {
          this.loadData();
      },
      methods: {
          loadData() {
              this.getAllProject();
          },
          async getAllProject() {
              const { data: res } = await this.$http.get("project/find/all");
              if (res.code == 0 && res.data != null) {
                  this.projectInfo = res.data[0];
                  this.getMainGantan();
              }
          },
          async getDeviceList() {
              const { data: res } = await this.$http.get("device/gantan/find/page", {
                  params: {
                      pageNo: 1,
                      pageSize: 1,
                      projectCode: this.projectInfo.projectCode
                  }
              });
              if (res.code == 0 && res.data != null) {
                  this.deviceInfo = res.data.list[0];
                  console.log(this.deviceInfo);
                  this.$refs.gantanChartRef.drawDiagram(this.deviceInfo.projectCode, this.deviceInfo.deviceCode);
              }
          },
          async getMainGantan() {
            const { data: res } = await this.$http.get("device/gantan/find/main/device", {
                params: {
                    projectCode: this.projectInfo.projectCode
                }
            });
            if (res.code == 0 && res.data != null) {
                this.deviceInfo = res.data;
                // console.log(this.deviceInfo);
                this.$refs.gantanChartRef.drawDiagram(this.projectInfo.projectCode, this.deviceInfo.deviceCode);
            }
        },
      }
  }
  </script>
  
  <style>
  .gantan-chart-v {
      float: right;
      width: 95%;
      height: 280px;
      margin-right: 15px;
      /* background-color: aliceblue; */
  }
  </style>