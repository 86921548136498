<template>
  <div>
    <canvas id="canvas"></canvas>
    <div class="imgDes">
      提示：[鼠标滚轮]可放大或缩小卫星图，[按alt]可拖动卫星图。[F10]全屏，[esc]退出全屏。
    </div>
  </div>
</template>

<script>
var canvas;
export default {
  inheritAttrs: false,
  data () {
    return {
      projectDiagramInfo: {},
      nameLabelAry: [],
    }
  },
  created () {
  },
  mounted () {
    fabric.Object.prototype.cornerSize = 0;
    canvas = new fabric.Canvas('canvas');
    this.getDiagramInfo();
    canvas.on('mouse:wheel', function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.setZoom(zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    canvas.on('mouse:down', function (opt) {
      var evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    canvas.on('mouse:move', function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
    canvas.on('mouse:up', function (opt) {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
    });
  },
  methods: {
    showDeviceInfo (deviceCode) {
      this.handleSelect(deviceCode);
    },
    async getDiagramInfo () {
      const { data: res } = await this.$http.get("project/find/diagram", {
        showLoading: false
      });
      if (res.code == 0 && res.data != null) {
        this.projectDiagramInfo = res.data;
        this.initCanvasObjects(this.projectDiagramInfo.diagramContent);
      }
    },
    async getDiagramInfoByProjectCode (projectCode) {
      const { data: res } = await this.$http.get("project/find/diagram", {
        params: {
          projectCode: projectCode
        }
      });
      if (res.code == 0 && res.data != null) {
        this.projectDiagramInfo = res.data;
        this.initCanvasObjects(this.projectDiagramInfo.diagramContent);
      } else {
        this.$message.error('该项目没有示意图');
      }
    },
    initCanvasObjects (content) {
      var w = this.$parent.width;
      var h = this.$parent.height;
      if (this.$parent.width == null) {
        w = 670;
        h = 550;
      }
      canvas.setWidth(w * 0.96);
      canvas.setHeight(h * 0.9);
      let that = this;
      canvas.loadFromJSON(content, canvas.renderAll.bind(canvas), function (o, object) {
        that.bindEvent(object);
      });
    },
    bindEvent (object) {
      if (object == null) {
        return;
      }
      let that = this;
      object.lockMovementX = true;
      object.lockMovementY = true;
      object.lockRotation = true;
      object.lockScalingFlip = true;
      object.lockScalingX = true;
      object.lockScalingY = true;
      if (object.type == 'rect') {
        object.on('selected', function () {
          that.showDeviceInfo(object.backgroundColor);
        });
        object.on('mouseover', function () {
          that.showDeviceName(object.backgroundColor, object);
        });
        object.on('mouseout', function () {
          that.hiddenDeviceName(object.backgroundColor);
        });
      }
      // 不允许选择的类型
      if (object.type == 'image' || object.type == 'text'
        || object.type == 'group' || object.type == 'polygon') {
        object.on('selected', function () {
          canvas.discardActiveObject();
        });
      }
    },

    handleSelect (data) {
      this.$emit('showDeviceInfo', data);
      canvas.discardActiveObject();
    },
    async showDeviceName (deviceCode, viewObj) {
      const { data: res } = await this.$http.get("device/find/simple/info", {
        params: {
          deviceCode: deviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        var name = new fabric.Text(res.data.name, {
          __uid: deviceCode,
          left: viewObj.left + 10,
          top: viewObj.top,
          fill: 'white',
          fontSize: 10
        });
        canvas.add(name);
        this.nameLabelAry.push(name);
      } else {
        this.$message.error(res.msg);
      }
    },
    hiddenDeviceName (deviceCode) {
      this.nameLabelAry.forEach(o => {
        canvas.remove(o);
      });
    }
  }
}
</script>

<style lang="less" scoped>
#canvas {
  margin-top: 15px;
  margin-left: 15px;
}
.imgDes {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  font-size: 14px;
  color: white;
  text-align: center;
}
</style>