<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>实时监测</el-breadcrumb-item>
      <el-breadcrumb-item>浸润线监测</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="4">
        <!-- 断面 -->
        <el-card>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-select
                v-model="queryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getDuanmianList"
                @change="getDuanmianList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-tree
            :data="duanmianData"
            node-key="dmCode"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadShenyaData"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <!-- 浸润线数据 -->
        <el-row :gutter="5">
          <el-col :span="16">
            <el-row>
              <el-card>
                <el-divider content-position="left">
                  <span style="color: rgb(158 158 158)">示意图</span>
                </el-divider>
                <div :style="showHeight">
                  <v-shenya-diagram v-if="yaliShow == false" ref="syDiagram"></v-shenya-diagram>
                  <v-shenya-yl-diagram v-if="yaliShow" ref="syylDiagram"></v-shenya-yl-diagram>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card v-if="yaliShow == false">
                <el-divider content-position="left">
                  <span style="color: rgb(158 158 158)"
                    >动态柱状图，无水：黑色，预警：黄色、橙色、红色，正常：灰色</span
                  >
                </el-divider>
                <div style="height: 240px; overflow-y: hidden;">
                  <div
                    id="shenyaChart"
                    class="chart"
                    style="width: 95%; height: 90%; margin-left: 0px;"
                  ></div>
                </div>
              </el-card>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-card>
              <el-divider content-position="left">
                <span style="color: rgb(158 158 158)">设备及预警信息</span>
              </el-divider>
              <div style="height: 730px; overflow: auto">
                <el-table :data="deviceInfoList" style="width: 100%">
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <el-form
                        label-position="left"
                        inline
                        class="demo-table-expand"
                      >
                        <el-form-item label="蓝色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.blueAlarmValue,
                                props.row.alarms.blueComparisonOperator
                              )
                            }}</span
                          >
                            <span v-if="props.row.slyali === '0'">米</span>
                            <span v-else>kPa</span>
                          </el-form-item
                        ><br />
                        <el-form-item label="黄色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.yellowAlarmValue,
                                props.row.alarms.yellowComparisonOperator
                              )
                            }}</span
                          > 
                          <span v-if="props.row.slyali === '0'">米</span>
                            <span v-else>kPa</span>
                            </el-form-item
                        ><br />
                        <el-form-item label="橙色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.orangeAlarmValue,
                                props.row.alarms.orangeComparisonOperator
                              )
                            }}</span
                          > 
                          <span v-if="props.row.slyali === '0'">米</span>
                            <span v-else>kPa</span>
                            </el-form-item
                        ><br />
                        <el-form-item label="红色预警">
                          <span v-if="props.row.alarms != null"
                            >{{
                              common.alarmTypeToString(
                                props.row.alarms.redAlarmValue,
                                props.row.alarms.redComparisonOperator
                              )
                            }}</span
                          >
                          <span v-if="props.row.slyali === '0'">米</span>
                            <span v-else>kPa</span>
                        </el-form-item>
                      </el-form>
                    </template>
                  </el-table-column>
                  <el-table-column label="名称" prop="name"></el-table-column>
                  <el-table-column label="备注" prop="remark"></el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vShenyaDiagram from "./ShenyaDiagramV2.vue";
import vShenyaYlDiagram from "./ShenyaYlDiagram.vue";
export default {
  components: {
    vShenyaDiagram,
    vShenyaYlDiagram,
  },
  props: {
    hiddenPath: Boolean
  },
  data () {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryParams: {
        projectCode: "",
        duanmianCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      duanmianData: [],
      deviceInfoList: [],
      dataChart: {
        xAxisData: [],
        shuiwei: [],
        jinrunxian: [],
      },
      shenyaChart: null,
      yaliShow: false,
      showHeight: 'height: 400px; overflow: hidden',
    };
  },
  created () {
    this.getAllProject();
  },
  methods: {
    // 由程序处罚的加载
    async defaultLoadData(deviceInfo) {
      let that = this;
      this.queryParams.projectCode = deviceInfo.projectCode;
      this.queryParams.duanmianCode = deviceInfo.duanmianCode;
      this.getDuanmianList();
      const { data: res } = await this.$http.get("dm/find/bycode/" + deviceInfo.duanmianCode);
      if (res.code == 0) {
        that.loadShenyaData(res.data);
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDuanmianList () {
      const { data: res } = await this.$http.get(
        "device/shenya/find/duanmian",
        {
          params: this.queryParams,
        }
      );
      if (res.code == 0) {
        if (res.data == null) {
          this.duanmianData = [];
          return;
        }

        var dmAry = [];
        res.data.forEach(d => {
          // console.log(dmAry);
          if (d.slyali == 1) {
            d.name = '[压力值]' + d.name;
          }
          dmAry.push(d);
        });
        this.duanmianData = dmAry;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadShenyaData (data) {
      if (data.slyali == 1) {
        this.yaliShow = true;
        this.shenyaChart = null;
        this.showHeight = 'height: 760px; overflow: hidden';
      } else {
        this.yaliShow = false;
        this.showHeight = 'height: 400px; overflow: hidden';
      }
      this.queryParams.duanmianCode = data.dmCode;
      const { data: res } = await this.$http.get(
        "device/shenya/find/devices/byduanmian",
        {
          params: this.queryParams,
        }
      );
      if (res.code == 0) {
        this.deviceInfoList = res.data;
        if (data.slyali == 0) {
          this.drawData();
          this.$refs.syDiagram.loadData(this.queryParams.projectCode, data.dmCode, data.name);
        } else {
          this.$refs.syylDiagram.loadData(this.queryParams.projectCode, data.dmCode);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData () {
      let collectTime = "";
      if (this.deviceInfoList != null) {
        this.dataChart.xAxisData = [];
        this.dataChart.jinrunxian = [];
        this.dataChart.shuiwei = [];
        this.deviceInfoList.forEach((el) => {
          collectTime = el.collectTime;
          this.dataChart.xAxisData.push(el.name);

          let jrx = 0;  // 空管距离
          let sw = 0; // 水位
          if (parseFloat(el.currentValue) > 0) {
            jrx = this.common.floatFormat(el.currentValue, 2);
            sw = this.common.floatFormat(parseFloat(el.maishen) - parseFloat(el.currentValue), 2);
          } else {
            jrx = parseFloat(el.maishen);
          }

          // 浸润线颜色
          let cl = "rgb(116, 116, 116)";
          if (sw <= 0) {
            cl = "black";
          } else if (el.alarms != null && el.alarms.redAlarmValue != null && jrx <= this.common.floatFormat(el.alarms.redAlarmValue, 2)) {
            // 红色预警
            cl = "red";
          } else if (el.alarms != null && el.alarms.orangeAlarmValue != null && jrx <= this.common.floatFormat(el.alarms.orangeAlarmValue, 2)) {
            // 橙色预警
            cl = "orange";
          } else if (el.alarms != null && el.alarms.yellowAlarmValue != null && jrx <= this.common.floatFormat(el.alarms.yellowAlarmValue, 2)) {
            // 黄色预警
            cl = "yellow";
          } else {
            cl = "rgb(116, 116, 116)";
          }
          this.dataChart.jinrunxian.push({
            value: jrx,
            itemStyle: {
              color: cl,
            },
          });
          this.dataChart.shuiwei.push(sw);
        });
      } else {
        this.dataChart = {
          xAxisData: [],
          shuiwei: [],
          jinrunxian: [],
        };
      }
      // 基于准备好的dom，初始化echarts实例
      if (this.shenyaChart == null) {
        this.shenyaChart = this.$echarts.init(document.getElementById("shenyaChart"));
      }
      // 绘制图表
      this.shenyaChart.clear();
      this.shenyaChart.setOption({
        title: {
          text: "浸润线实时监测动态图（单位：米）",
          subtext: "采集时间：" + collectTime,
          left: "left",
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          top: 20,
          data: ["设备水位", "空管距离"],
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: 20,
          top: 60,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.dataChart.xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "设备水位",
            type: "bar",
            stack: "shenya",
            barWidth: 30,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
            itemStyle: {
              color: "rgb(62, 200, 255)",
            },
            data: this.dataChart.shuiwei,
          },
          {
            name: "空管距离",
            type: "bar",
            stack: "shenya",
            barWidth: 30,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
            itemStyle: {
              color: "rgb(116, 116, 116)",
            },
            data: this.dataChart.jinrunxian,
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
  font-size: 14px;
}
</style>