<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="10">
        <div class="device-img">
          <img
            class="image-size"
            :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + deviceInfo.deviceImg"
          />
        </div>
      </el-col>
      <el-col :span="14">
        <div class="device-info">
          <el-row>
            <div class="dev-title">
              {{deviceInfo.name}}
            </div>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">设备类型：</div>
                <div class="d-value">雨量计</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">采集间隔：</div>
                <div class="d-value">{{deviceInfo.collectInterval}}min</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">采集时间：</div>
                <div class="d-value" style="font-size: 14px;">{{deviceInfo.collectTime}}</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label"></div>
                <div class="d-value"></div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">当日总量：</div>
                <div class="d-value">{{totayTotal}}mm</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">最新变化：</div>
                <div class="d-value">{{deviceInfo.currentValue}}mm</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-1">蓝色预警：
                <span v-if="alarmConfig.blueAlarmValue !== null">
                监测值{{ alarmConfig.blueRange }}小时内{{
                common.alarmTypeToString(
                alarmConfig.blueAlarmValue,
                alarmConfig.blueComparisonOperator
                )
                }}mm
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-2">黄色预警：
                <span v-if="alarmConfig.yellowAlarmValue !== null">
                监测值{{ alarmConfig.yellowRange }}小时内{{
                common.alarmTypeToString(
                alarmConfig.yellowAlarmValue,
                alarmConfig.yellowComparisonOperator
                )
                }}mm
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-3">橙色预警：
                <span v-if="alarmConfig.orangeAlarmValue !== null">
                监测值{{ alarmConfig.orangeRange }}小时内{{
                common.alarmTypeToString(
                alarmConfig.orangeAlarmValue,
                alarmConfig.orangeComparisonOperator
                )
                }}mm
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-4">红色预警：
                <span v-if="alarmConfig.redAlarmValue !== null">
                监测值{{ alarmConfig.redRange }}小时内{{
                common.alarmTypeToString(
                alarmConfig.redAlarmValue,
                alarmConfig.redComparisonOperator
                )
                }}mm
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props:['data'],
  data() {
    return {
      deviceInfo: {},
      alarmConfig: {},
      totayTotal: 0
    }
  },
  created() {
    this.loadData(this.data);
  },
  watch: {
    data(value) {
      this.loadData(value);
    }
  },
  methods: {
    async loadData(data) {
      this.loadDeviceInfo(data);
      this.loadAlarmConfig(data);
    },
    async loadDeviceInfo(data) {
      const { data: res } = await this.$http.get("device/yuliang/find/bycode/" + data.code);
      if (res.code == 0) {
        this.deviceInfo = res.data;
        this.loadTodayTotal(this.deviceInfo.projectCode, this.deviceInfo.deviceCode);
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadAlarmConfig(data) {
      const { data: res } = await this.$http.get("alarm/find/config", {
        params: {
          deviceCode: data.code
        }
      });
      if (res.code == 0 && res.data != null) {
        this.alarmConfig = res.data;
      }
    },
    async loadTodayTotal(projectCode, deviceCode) {
      if (deviceCode === '' || projectCode === '') {
        return
      }

      const { data: res } = await this.$http.get("device/yuliang/find/totay/total", {
        params: {
          projectCode: projectCode,
          deviceCode: deviceCode
        }
      });
      if (res.code == 0) {
        this.totayTotal = res.data == null ? 0 : res.data;
      }
    }
  }
}
</script>

<style scoped>
.device-img {
  width: 100%;  
  height: 400px;
}

.image-size {
  width: 100%;
  height: 400px;
  object-fit: fill;
}

.device-info {
  width: 100%;
  height: 400px;
}

.dev-title {
  width: 100%;
  height: 80px;
  font-size: 36px;
  font-weight: bold;
  line-height: 80px;
  color: #ffdd5f;
  margin-left: 15px;
}

.dev-text {
  width: 100%;
  height: 40px;
  color: white;
}
.d-label {
  float: left;
  width: 35%;
  height: 40px;
  text-align: right;
  font-size: 16px;
  line-height: 40px;
}
.d-value {
  float: left;
  width: 65%;
  height: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 40px;
}
.alarm-tag {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 14px;
}
.alarm-info-1 {
  width: 100%;
  height: 50px;
  background-color: #00c2ff;
  border-color: #00c2ff;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-2 {
  width: 100%;
  height: 50px;
  background-color: #ffdd5f;
  border-color: #ffdd5f;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-3 {
  width: 100%;
  height: 50px;
  background-color: #ff6541;
  border-color: #ff6541;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-4 {
  width: 100%;
  height: 50px;
  background-color: #ff3c5d;
  border-color: #ff3c5d;
  opacity: 0.8;
  text-indent: 10px;
}
</style>