<template>
  <div class="module-item status">
    <SvgIcon iconClass="status_module_bkg"/>
    <ul class="color">
      <li v-for="item in statusList" :key="item[1]">
        <i :style="{ backgroundColor: item[1] }"></i>
        <span>{{ item[2] }}</span>
      </li>
    </ul>
    <div class="chart">
      <DashChart :value="data.systemValue" :axisLineColor="statusList" name="系统状态" ref="svChartsRef"/>
      <DashChart :value="data.projectValue" :axisLineColor="statusList" name="库区状态" ref="avChartsRef"/>
    </div>
  </div>
</template>
<script>
import DashChart from '../charts/dashBord.vue'
export default {
  name: "StatusModule",
  components:{DashChart},
  props:{
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      statusList:[
        [0.3,'#FF3C5D','危险'],
        [0.6,'#FFDD5F','异常'],
        [0.99,'#18B0F9','低风险'],
        [1,'#50CFA3','良好']
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.module-item {
  width: 100%;
  position: relative;
  width: 540px;
  height: 190px;
  svg {
    position: absolute;
    width: 100%;
    height: 190px;
    z-index: 0;
    left: 0;
    top:0;
  }
  .color {
    color: #fff;
    font-size: 14px;
    padding: 25px 0 0 20px;
    position: absolute;
    li {
      list-style: none;
      text-align: left;
      margin-bottom: 6px;
    }
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
  .chart {
    padding-left: 20px;
    display: flex;
    padding-right: 40px;
  }
}
</style>