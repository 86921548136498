<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>实时监测</el-breadcrumb-item>
      <el-breadcrumb-item>浊度监测</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="4">
        <!-- 断面 -->
        <el-card>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-select
                v-model="queryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getDeviceList"
                @change="getDeviceList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-tree
            :data="deviceData"
            node-key="deviceCode"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadDeviceRealTimeData"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-card>
            <el-divider content-position="left">
              <span style="color: rgb(158 158 158)">浊度动态曲线图</span>
            </el-divider>
            <div style="height: 340px; overflow: auto">
              <div
                id="diagramChart"
                class="chart"
                style="width: 99%; height: 99%; margin-left: 0px;"
              ></div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card>
            <el-divider content-position="left">
              <span style="color: rgb(158 158 158)">设备及预警信息</span>
            </el-divider>
            <div style="height: 100px; font-size: 14px; line-height: 30px">
              <el-row>
                <el-col :span="8"
                  >设备名称：{{ currentSelectNode.name }}</el-col
                >
                <el-col :span="8"
                  >设备行政编号：{{ currentSelectNode.deviceGovCode }}</el-col
                >
                <el-col :span="8"
                  >通道号：{{ currentSelectNode.channelNo }}</el-col
                >
              </el-row>
              <el-row>
                <el-col :span="8"
                  >输入类型：{{
                    this.common.zhuoduInput(currentSelectNode.inType)
                  }}</el-col
                >
                <el-col :span="8"
                  >初始值：{{ currentSelectNode.initValue }} 米</el-col
                >
                <el-col :span="8"
                  >采集间隔：{{
                    currentSelectNode.collectInterval
                  }}
                  分钟</el-col
                >
              </el-row>
              <el-row>
                <el-col :span="6"
                  >蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.blueAlarmValue,
                      alarmConfig.blueComparisonOperator
                    )
                  }}</el-col
                >
                <el-col :span="6"
                  >黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.yellowAlarmValue,
                      alarmConfig.yellowComparisonOperator
                    )
                  }}</el-col
                >
                <el-col :span="6"
                  >橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.orangeAlarmValue,
                      alarmConfig.orangeComparisonOperator
                    )
                  }}</el-col
                >
                <el-col :span="6"
                  >红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.redAlarmValue,
                      alarmConfig.redComparisonOperator
                    )
                  }}</el-col
                >
              </el-row>
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    hiddenPath: Boolean
  },
  data () {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      deviceData: [],
      currentSelectNode: {},
      alarmConfig: {
        yellowAlarmValue: "",
        yellowComparisonOperator: null,
        orangeAlarmValue: "",
        orangeComparisonOperator: null,
        redAlarmValue: "",
        redComparisonOperator: null,
      },
      diagramChart: null,
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    // 由程序处罚的加载
    async defaultLoadData(deviceInfo) {
      this.queryParams.projectCode = deviceInfo.projectCode;
      this.getDeviceList();
      this.loadDeviceRealTimeData(deviceInfo);
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/zhuodu/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceData = [];
          return;
        }

        this.deviceData = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    loadDeviceRealTimeData (data) {
      this.currentSelectNode = data;
      this.loadStatData(data.deviceCode);
      this.loadAlarmConfig(data.deviceCode);
    },
    loadStatData (deviceCode) {
      // 加载报表数据
      this.getDataList(deviceCode);
    },
    async loadAlarmConfig (deviceCode) {
      const { data: res } = await this.$http.get("alarm/find/config", {
        params: {
          deviceCode: deviceCode
        }
      });
      if (res.code == 0) {
        this.alarmConfig = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDataList (deviceCode) {
      // 获取最新的20条数据
      const { data: res } = await this.$http.post("device/zhuodu/history/data/diagram", {
        projectCode: this.queryParams.projectCode,
        deviceCode: deviceCode,
        pageNo: 1,
        pageSize: 100
      });
      if (res.code == 0) {
        this.drawData(res.data.list, res.data.alarmConfig);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 4);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.blueAlarmValue != null && alarmConfig.blueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.blueAlarmValue, 2));
          }
          if (alarmConfig.yellowAlarmValue != null && alarmConfig.yellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.yellowAlarmValue, 2));
          }
          if (alarmConfig.orangeAlarmValue != null && alarmConfig.orangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.orangeAlarmValue, 2));
          }
          if (alarmConfig.redAlarmValue != null && alarmConfig.redAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.redAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        yAxisType = 'log';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "浊度仪实时监测曲线图（单位：NTU）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} NTU'
          },
        },
        series: [
          {
            name: "混浊度",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      if (blueData.length > 0) {
        option.series.push({
          name: "蓝色预警线",
          type: "line",
          data: blueData,
          lineStyle: {
            color: 'blue',
            type: 'dashed'
          },
          itemStyle: {
            color: 'blue',
          }
        });
      }

      if (yellowData.length > 0) {
        option.series.push({
          name: "黄色预警线",
          type: "line",
          data: yellowData,
          lineStyle: {
            color: 'yellow',
            type: 'dashed'
          },
          itemStyle: {
            color: 'yellow',
          }
        });
      }

      if (orangeData.length > 0) {
        option.series.push({
          name: "橙色预警线",
          type: "line",
          data: orangeData,
          lineStyle: {
            color: 'orange',
            type: 'dashed'
          },
          itemStyle: {
            color: 'orange',
          }
        });
      }

      if (redData.length > 0) {
        option.series.push({
          name: "红色预警线",
          type: "line",
          data: redData,
          lineStyle: {
            color: 'red',
            type: 'dashed'
          },
          itemStyle: {
            color: 'red',
          }
        });
      }

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    },
  }
}
</script>
<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
  font-size: 14px;
}
</style>