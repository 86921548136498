<template>
  <div class="boddy-wrap">
    <dv-full-screen-container>
      <div class="timeTip">{{ newDate }}{{ newTime }}</div>
      <dv-border-box-11 title="尾矿库监控平台">
        <div class="main-container">
          <div class="row-1">
            <div class="row-1-1">
              <div class="alarm-box-0">
                <dv-border-box-10>
                  <v-sys-status ref="sysStatusRef"></v-sys-status>
                </dv-border-box-10>
              </div>
              <div class="alarm-box-1">
                <dv-border-box-10>
                  <dv-decoration-7
                    style="
                      width: 150px;
                      height: 30px;
                      margin: auto;
                      color: aqua;
                    "
                    >预警统计</dv-decoration-7
                  >
                  <v-alarm-statis ref="alarmStatisView"></v-alarm-statis>
                </dv-border-box-10>
              </div>
              <div class="alarm-box-2">
                <dv-border-box-10>
                  <dv-decoration-7
                    style="
                      width: 150px;
                      height: 30px;
                      margin: auto;
                      color: aqua;
                    "
                    >最新预警</dv-decoration-7
                  >
                  <v-alarm-msg ref="alarmMsgView"></v-alarm-msg>
                </dv-border-box-10>
              </div>
            </div>
            <div class="row-1-2">
              <dv-border-box-10>
                <v-diagram-view
                  v-if="showMap == false"
                  @showDeviceInfo="showDeviceInfo"
                  ref="imgDiagramView"
                ></v-diagram-view>
                <v-map-diagram v-if="showMap" :canEdit="false" :showController="true" @onMarkerClick="onMarkerClick" ref="mapDiagramRef"></v-map-diagram>
              </dv-border-box-10>
            </div>
            <div class="row-1-3">
              <div class="right-1">
                <dv-border-box-10>
                  <dv-decoration-7
                    style="
                      width: 150px;
                      height: 30px;
                      margin: auto;
                      color: aqua;
                    "
                    >项目概况</dv-decoration-7
                  >
                  <v-prject-info ref="projectInfoView"></v-prject-info>
                </dv-border-box-10>
              </div>
              <div class="right-2">
                <dv-border-box-10>
                  <dv-decoration-7
                    style="
                      width: 150px;
                      height: 30px;
                      margin: auto;
                      color: aqua;
                    "
                    >异常设备统计</dv-decoration-7
                  >
                  <v-error-device ref="errorDeviceView"></v-error-device>
                </dv-border-box-10>
              </div>
            </div>
          </div>
          <div class="row-2">
            <div v-has="'sys:mtr:shenya'" class="btm-box1" ref="shenyaRef">
              <dv-border-box-10>
                <dv-decoration-7
                  style="width: 150px; height: 30px; margin: auto; color: aqua"
                  >浸润线监测</dv-decoration-7
                >
                <dv-scroll-board
                  :config="jinrunxianConfig"
                  style="width: 95%; height: 80%; margin: auto"
                />
              </dv-border-box-10>
            </div>
            <div v-has="'sys:mtr:cexie'" class="btm-box2" ref="cexieRef">
              <dv-border-box-10>
                <dv-decoration-7
                  style="width: 150px; height: 30px; margin: auto; color: aqua"
                  >内部测斜监测</dv-decoration-7
                >
                <dv-scroll-board
                  :config="cexieConfig"
                  style="width: 95%; height: 80%; margin: auto"
                />
              </dv-border-box-10>
            </div>
            <div v-has="'sys:mtr:quanzhan,sys:mtr:gnss'" class="btm-box3" ref="weiyiRef">
              <dv-border-box-10>
                <dv-decoration-7
                  style="width: 150px; height: 30px; margin: auto; color: aqua"
                  >表面位移监测</dv-decoration-7
                >
                <dv-scroll-board
                  :config="weiyiConfig"
                  style="width: 95%; height: 80%; margin: auto"
                />
              </dv-border-box-10>
            </div>
          </div>
        </div>
      </dv-border-box-11>
    </dv-full-screen-container>
    <v-ws :clientTypeParam="3"></v-ws>
    <audio id="alarmAudio" src="../../assets/alarm.wav" muted="muted" />

    <el-dialog
      title="设备信息"
      :visible.sync="deviceDialogVisible"
      width="40%"
      v-if="deviceDialogVisible"
    >
      <el-row>
        <el-col :span="8">
          <img
            :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + deviceInfo.deviceImg"
            height="200"
            width="200"
            border="1"
          />
        </el-col>
        <el-col :span="16">
          <el-form :model="deviceInfo" disabled label-width="120px">
            <el-form-item label="设备名称：" prop="name">
              {{ deviceInfo.name }}
            </el-form-item>
            <el-form-item label="监测类型：" prop="deviceType">
              <span v-if="deviceInfo.deviceType == 10">干滩</span>
              <span v-if="deviceInfo.deviceType == 21">超高</span>
              <span v-if="deviceInfo.deviceType == 20">库水位</span>
              <span v-if="deviceInfo.deviceType == 30">表面位移</span>
              <span v-if="deviceInfo.deviceType == 33">表面位移</span>
              <span v-if="deviceInfo.deviceType == 40">内部测斜</span>
              <span v-if="deviceInfo.deviceType == 50">浸润线</span>
              <span v-if="deviceInfo.deviceType == 60">降雨量</span>
              <span v-if="deviceInfo.deviceType == 91">混浊度</span>
              <span v-if="deviceInfo.deviceType == 22">明渠流量</span>
            </el-form-item>
            <el-form-item label="" prop="remark" label-width="50px">
              {{ deviceInfo.remark }}
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 视频监控对话框 -->
    <el-dialog
      title="视频预览"
      :visible.sync="videoDialogVisible"
      :width="'780px'"
      v-if="videoDialogVisible"
      :before-close="closeVideo">
      <v-video-main :data="currentShowCamera"></v-video-main>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../common/bus";
import vWs from "../common/webSocket.vue";
import vAlarmStatis from "./dataView/AlarmStatis.vue";
import vErrorDevice from "./dataView/ErrorDevice.vue";
import vPrjectInfo from "./dataView/PrjectInfo.vue";
import vAlarmMsg from "./dataView/AlarmMsg.vue";
import vDiagramView from "./dataView/DiagramView.vue";
import vSysStatus from "./dataView/SysStatus.vue";
import vMapDiagram from "./project/MapDiagram.vue";
import vVideoMain from "./video/VideoMain.vue";
const days = ['天', '一', '二', '三', '四', '五', '六']; // 星期数组
var icnow = new Date();      // 初始化时间
var interval;                // 定义全局定时器，用于清除定时器
var mainTimer;
export default {
  components: {
    vAlarmStatis,
    vErrorDevice,
    vPrjectInfo,
    vAlarmMsg,
    vDiagramView,
    vMapDiagram,
    vWs,
    vSysStatus,
    vVideoMain
  },
  data () {
    return {
      jinrunxianConfig: {
        header: ['设备名', '监测值', '时间'],
        data: []
      },
      cexieConfig: {
        header: ['设备名', '结果值(毫米)', '时间'],
        data: []
      },
      weiyiConfig: {
        header: ['设备名', '监测值(毫米)'],
        data: []
      },
      year: icnow.getFullYear(),
      month: icnow.getMonth() + 1,
      date: icnow.getDate(),
      day: days[icnow.getDay()],
      time: "00:00:00",
      deviceDialogVisible: false,
      videoDialogVisible: false,
      deviceInfo: {},
      wyWidth: 330,
      showMap: true,
      projectInfo: {},
      currentShowCamera: {}
    };
  },
  created () {
    this.loadProjectDiagramConfig();
    this.loadJinrunxianData();
    this.loadCexieData();
    this.loadWeiyiData();
    document.addEventListener("keydown", this.handleKeyDown);
    // 1分钟更新数据
    mainTimer = setInterval(() => {
      this.loadData();
    }, 60000);
    interval = setInterval(() => {
      let icnow = new Date();
      this.year = icnow.getFullYear();
      this.month = icnow.getMonth() + 1;
      this.date = icnow.getDate();
      this.day = days[icnow.getDay()];
      this.time = icnow.toTimeString().substring(0, 8);
    }, 1000);

    // 订阅预警
    bus.$on("alarm-msg-event", (msg) => {
      this.loadData();
      if (msg.type === '10010101') {
        // 黄色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010001') {
        // console.log("==============");
        // 蓝色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010201') {
        // console.log("==============");
        // 橙色预警
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      } else if (msg.type === '10010301') {
        // 红色预警
        this.$notify.error({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          duration: msg.data.duration * 1000,
          onClose: function () {
            var audio = document.getElementById("alarmAudio");
            if (!audio.paused) {
              audio.pause();
              // console.log("停止播放.");
            }
          }
        });
        this.alarm();
      } else if (msg.type === '20010000') {
        // 通知
        this.$notify({
          title: msg.data.title,
          dangerouslyUseHTMLString: true,
          message: msg.data.content,
          type: 'warning',
          duration: msg.data.duration * 1000
        });
      }
    });

    // 通过权限控制展示模块，滚屏数据
    this.$nextTick(() => {
      let cexie = false;
      let shenya = false;
      let gnss = false;
      let permsStr = localStorage.getItem("user_perms");
      let permsAry = permsStr.split(",");
      let i = 0;
      permsAry.forEach(o => {
        if (o == 'sys:mtr:cexie') {
          cexie = true;
          i++;
        }
        if (o == 'sys:mtr:shenya') {
          shenya = true;
          i++;
        }
        if ((o == 'sys:mtr:gnss' || o == 'sys:mtr:quanzhan') && !gnss) {
          gnss = true;
          i++;
        }
      });
      // 计算滚屏数据宽度
      let widthCss = '0';
      if (i == 1) {
        widthCss = '100%';
        this.wyWidth = 800;
      } else if (i == 2) {
        widthCss = '49.5%';
        this.wyWidth = 500;
      } else {
        widthCss = '33%';
      }
      setTimeout(() => {
        // console.log(this.$refs.alarmStatisView);
        this.$refs.shenyaRef.style.width = widthCss;
        this.$refs.cexieRef.style.width = widthCss;
        this.$refs.weiyiRef.style.width = widthCss;
      }, 0);
    });
  },
  computed: {
    // 当前时间
    newTime: function () {
      return this.time;
    },
    newDate: function () {
      return this.year + '年' + this.month + '月' + this.date + '日 星期' + this.day + ' ';
    }
  },
  methods: {
    loadData () {
      this.loadJinrunxianData();
      this.loadCexieData();
      this.loadWeiyiData();
      this.$refs.sysStatusRef.loadHealthStatus();
      this.$refs.alarmStatisView.getAlarmStatis();
      this.$refs.alarmMsgView.getUnReadList();
      this.$refs.projectInfoView.getProjectSummary();
      this.$refs.errorDeviceView.getExceptionDevice();
      if (this.showMap) {
        this.$refs.mapDiagramRef.setProjectInfo(this.projectInfo);
      } else {
        this.$refs.imgDiagramView.getDiagramInfo();
      }
    },
    async loadProjectDiagramConfig() {
      const { data: res } = await this.$http.get("project/load/for/dataview", {
        showLoading: false
      });
      if (res.code == 0) {
        this.showMap = res.data.diagram == null ? false : res.data.diagram.forceOnBl;
        this.projectInfo = res.data.project;
        if (this.showMap) {
          this.$refs.mapDiagramRef.setProjectInfo(res.data.project);
        }
      }
    },
    async loadJinrunxianData () {
      const { data: res } = await this.$http.get("device/shenya/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          let v = "";
          if (o.slyali === '1') {
            // 压力值
            v = "压力" + this.common.floatFormat(o.currentValue, 2) + "kPa";
          } else {
            // 空管距离
            v = "空管" + this.common.floatFormat(o.currentValue, 2) + "米";
          }
          let i = [o.name, v, o.collectTime];
          d.push(i);
        });
        this.jinrunxianConfig = { header: ['设备名', '监测值', '时间'], data: d, columnWidth: [150, 100, 170] };
      }
    },
    async loadCexieData () {
      const { data: res } = await this.$http.get("device/cexie/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          let i = [o.name, o.currentValue, o.collectTime];
          d.push(i);
        });
        this.cexieConfig = { header: ['设备名', '结果值(毫米)', '时间'], data: d, columnWidth: [150, 120, 170] };
      }
    },
    async loadWeiyiData () {
      const { data: res } = await this.$http.get("device/weiyi/find/latest/value", {
        showLoading: false
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }

        let d = [];
        (res.data).forEach(o => {
          let i = [o.name, o.value];
          d.push(i);
        });
        this.weiyiConfig = { header: ['设备名', '监测值(毫米)'], data: d, columnWidth: [120, this.wyWidth] };
      }
    },
    handleKeyDown (e) {
      var key = window.event.keyCode
        ? window.event.keyCode
        : window.event.which;
      if (key === 121) {
        this.handleFullScreen();
      }
      // console.log(key);
    },
    // 全屏事件
    handleFullScreen () {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    },
    alarm () {
      var audio = document.getElementById("alarmAudio");
      if (audio.paused) {
        audio.play();
        audio.loop = true;
        audio.preload = true;
      } else {
        // console.log("已经在播放.");
      }
    },
    // 展示设备信息
    async showDeviceInfo (data) {
      // console.log(data);
      const { data: res } = await this.$http.get("device/find/simple/info", {
        params: {
          deviceCode: data
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.$message.error('找不到该设备信息.');
          return;
        }

        console.log(res.data);
        this.deviceInfo = res.data;
        if (res.data.deviceType === 95) {
          // 显示摄像头
          this.playCamera(this.deviceInfo.deviceCode);
        } else {
          this.deviceDialogVisible = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    onMarkerClick(data) {
      // 32为全站仪点位，不需要显示详情
      if (data.type != 32) {
        this.showDeviceInfo(data.code);
      }
    },
    // 摄像头相关
    async playCamera(deviceCode) {
      const { data: res } = await this.$http.get("device/camera/pull/stream/" + deviceCode);
      if (res.code == 0) {
        this.currentShowCamera = res.data;
        this.videoDialogVisible = true;
      } else {
        this.$message.error("播放失败");
      }
    },
    async closeStream(deviceCode) {
      const { data: res } = await this.$http.get("device/camera/close/stream/" + deviceCode);
      if (res.code == 0) {
        console.log(res.data);
      }
    },
    closeVideo(done) {
      this.closeStream(this.currentShowCamera.stream_name);
      done();
    }
  },
  destroyed () {
    document.removeEventListener('keydown', this.handleKeyDown);
    bus.$off("alarm-msg-event");
  },
  beforeDestroy () {
    clearInterval(interval);
    clearInterval(mainTimer);
  }
};
</script>

<style lang="less">
body {
  margin: 0;
}
.boddy-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/bg.png);
  background-size: 100%;
}
.row-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 70%;
  width: 100%;
}
.row-2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 30%;
  width: 100%;
}
.row-1-1 {
  float: left;
  width: 25%;
  height: 100%;
}
.alarm-box-0 {
  width: 98%;
  height: 25%;
}
.alarm-box-1 {
  width: 98%;
  height: 40%;
  margin-top: 12px;
}
.alarm-box-2 {
  width: 98%;
  height: 30%;
  margin-top: 12px;
}
.row-1-2 {
  float: left;
  width: 50%;
  height: 99%;
}
.row-1-3 {
  float: right;
  width: 25%;
  height: 100%;
}
.right-1 {
  width: 98%;
  height: 55%;
  margin-left: 6px;
}
.right-2 {
  width: 98%;
  height: 41%;
  margin-top: 15px;
  margin-left: 6px;
}
.btm-box1 {
  float: left;
  width: 33%;
  height: 95%;
  margin-top: 12px;
}
.btm-box2 {
  float: left;
  width: 33%;
  height: 95%;
  margin-top: 12px;
  margin-left: 7px;
}
.btm-box3 {
  float: right;
  width: 33%;
  height: 95%;
  margin-top: 12px;
}
.main-container {
  position: absolute;
  width: 97%;
  height: 92%;
  top: 50px;
  left: 20px;
}
.timeTip {
  position: absolute;
  top: 8px;
  left: 25px;
  width: 300px;
  height: 20px;
  color: aqua;
}
</style>