<template>
  <div class="AddressSelector">
    <el-form>
      <el-row>
        <el-col :span="10">
          <el-form-item
            prop="provinceCode"
            label="地址"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="addressFormData.provinceCode"
              placeholder="请选择省份"
              @change="selectProvinceChange"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.provinceCode"
                :label="item.provinceName"
                :value="item.provinceCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="cityCode" label-width="0">
            <el-select
              v-model="addressFormData.cityCode"
              placeholder="请选择城市"
              @change="selectCityChange"
            >
              <el-option
                v-for="item in cityList"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="areaCode" label-width="0">
            <el-select
              v-model="addressFormData.areaCode"
              placeholder="请选择区"
              @change="selectAreaChange"
            >
              <el-option
                v-for="item in areaList"
                :key="item.areaCode"
                :label="item.areaName"
                :value="item.areaCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    selectedProvinceCode: String,
    selectedProvinceName: String,
    selectedCityCode: String,
    selectedCityName: String,
    selectedAreaCode: String,
    selectedAreaName: String
  },
  inheritAttrs: false,
  data() {
    return {
      formLabelWidth: "120px",
      addressFormData: {
        provinceCode: null,
        provinceName: "",
        cityCode: null,
        cityName: "",
        areaCode: null,
        areaName: "",
      },
      provinceList: [],
      cityList: [],
      areaList: [],
    };
  },
  mounted() {
    // 加载前清空
    this.clearData();
    // 初始加载省份
    this.loadProvinceList();
    this.initData();
  },
  watch: {
    selectedProvinceCode() {
      this.clearData();
      this.initData();
    }
  },
  methods: {
    initData() {
      if (this.selectedProvinceCode != null && this.selectedProvinceCode != '') {
        this.loadProvinceList();
        this.addressFormData.provinceCode = this.selectedProvinceCode;
        this.addressFormData.provinceName = this.selectedProvinceName;
      }
      if (this.selectedCityCode != null && this.selectedCityCode != '') {
        this.loadCityList(this.selectedProvinceCode);
        this.addressFormData.cityCode = this.selectedCityCode;
        this.addressFormData.cityName = this.selectedCityName;
      }
      if (this.selectedAreaCode != null && this.selectedAreaCode != '') {
        this.loadAreaList(this.selectedCityCode);
        this.addressFormData.areaCode = this.selectedAreaCode;
        this.addressFormData.areaName = this.selectedAreaName;
      }
    },
    clearData() {
      this.addressFormData.provinceCode = null;
      this.addressFormData.provinceName = null;
      this.addressFormData.cityCode = null;
      this.addressFormData.cityName = null;
      this.addressFormData.areaCode = null;
      this.addressFormData.areaName = null;
    },
    async loadProvinceList() {
        const { data: prs } = await this.$http.get('address/all/provinces');
        if (prs.code == 0) {
          this.provinceList = prs.data;
        }
    },
    async loadCityList(pcode) {
        const { data: res } = await this.$http.get('address/find/city/' + pcode);
        if (res.code == 0) {
          this.cityList = res.data;
        }
    },
    async loadAreaList(ccode) {
        const { data: ars } = await this.$http.get('address/find/area/' + ccode);
        if (ars.code == 0) {
          this.areaList = ars.data;
        }
    },
    selectProvinceChange(pcode) {
        this.addressFormData.cityName = "";
        this.addressFormData.cityCode = "";
        this.addressFormData.areaName = "";
        this.addressFormData.areaCode = "";
        this.loadCityList(pcode);

        // 获取省份信息
        let obj = {};
        obj = this.provinceList.find(item => {
          return item.provinceCode === pcode;
        });
        this.addressFormData.provinceName = obj.provinceName;
        this.handleSelect(this.addressFormData);
    },
    selectCityChange(ccode) {
        this.addressFormData.areaName = "";
        this.addressFormData.areaCode = "";
        this.loadAreaList(ccode);
        // 获取城市信息
        let obj = {};
        obj = this.cityList.find(item => {
          return item.cityCode === ccode;
        });
        this.addressFormData.cityName = obj.cityName;
        this.handleSelect(this.addressFormData);
    },
    selectAreaChange(acode) {
        let obj = {};
        obj = this.areaList.find(item => {
          return item.areaCode === acode;
        });
        this.addressFormData.areaName = obj.areaName;
        this.handleSelect(this.addressFormData);
    },
    handleSelect(data) {
      this.$emit('addressChange', data)
    }
  },
};
</script>

<style lang="less" scoped>
</style>