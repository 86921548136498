<template>
  <div class="module-item project">
    <SvgIcon iconClass="project_overview_bkg"/>
    <h1 class="project-detail">项目概况</h1>
    <div class="list-item">
      <div class="item-title">项目名称</div>
      <div class="pitem-value">{{ data.projectName }}</div>
    </div>
    <div class="list-item" v-has="'sys:mtr:gantan'">
      <div class="item-title">干滩长度<span style="font-size: 14px;">(米)</span></div>
      <div class="item-value">{{ data.gantan }}</div>
      <ul class="item-level">
        <li class="max-h">
          <i class="el-icon-top"></i>
          <span>&nbsp;今日最高&nbsp;</span>
          <span>{{ this.common.floatFormat(data.gantanH, 2) }}</span>
        </li>
        <li class="min-h">
          <i class="el-icon-bottom"></i>
          <span>&nbsp;今日最低&nbsp;</span>
          <span>{{ this.common.floatFormat(data.gantanL, 2)}}</span>
        </li>
      </ul>
    </div>
    <div class="list-item" v-has="'sys:mtr:gantan'">
      <div class="item-title">干滩坡度</div>
      <div class="item-value">{{ data.pb }}</div>
    </div>
    <div class="list-item" v-has="'sys:mtr:wuwei'">
      <div class="item-title">库水位<span style="font-size: 14px;">(米)</span></div>
      <div class="item-value">{{ data.shuiwei }}</div>
      <ul class="item-level">
        <li class="max-h">
          <i class="el-icon-top"></i>
          <span>&nbsp;今日最高&nbsp;</span>
          <span>{{ this.common.floatFormat(data.shuiweiH, 2)}}</span>
        </li>
        <li class="min-h">
          <i class="el-icon-bottom"></i>
          <span>&nbsp;今日最低&nbsp;</span>
          <span>{{ this.common.floatFormat(data.shuiweiL, 2)}}</span>
        </li>
      </ul>
    </div>
    <div class="list-item" v-has="'sys:mtr:chaogao'">
      <div class="item-title">安全超高<span style="font-size: 14px;">(米)</span></div>
      <div class="item-value">{{ data.chaogao }}</div>
      <ul class="item-level">
        <li class="max-h">
          <i class="el-icon-top"></i>
          <span>&nbsp;今日最高&nbsp;</span>
          <span>{{ this.common.floatFormat(data.chaogaoH, 2)}}</span>
        </li>
        <li class="min-h">
          <i class="el-icon-bottom"></i>
          <span>&nbsp;今日最低&nbsp;</span>
          <span>{{ this.common.floatFormat(data.chaogaoL, 2)}}</span>
        </li>
      </ul>
    </div>
    <div class="list-item" v-has="'sys:mtr:yuliang'">
      <div class="item-title">降雨·当日<span style="font-size: 14px;">(毫米)</span></div>
      <div class="item-value">{{ data.ylNumDay }}</div>
    </div>
    <div class="list-item" v-has="'sys:mtr:yuliang'">
      <div class="item-title">降雨·最新<span style="font-size: 14px;">(毫米)</span></div>
      <div class="item-value">{{ data.ylNum }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "projectModule",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
ul,
li {
  list-style: none;
}
.module-item {
  height: 430px;
  width: 560px;
  text-align: left;
  background-size: 100% 100%;
  margin-bottom: 38px;
  position: relative;
  svg {
    position: absolute;
    width:100%;
    height: 430px;
    z-index: -1;
    right: 0;
    top:0;
  }
  .project-detail {
    padding-left: 67px;
    line-height: 62px;
    font-size: 24px;
    margin-bottom: 15px;
    color: #fff;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 550px;
      height: 1px;
      background-color: rgba(42, 154, 255, 0.28);
      position: absolute;
      bottom: 0;
      left:8px;
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 130px;
    margin-bottom: 12px;
    .item-title {
      color: #fff;
      width: 130px;
      margin-right: 25px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 4px;
        background: #fff;
        position: absolute;
        left: -16px;
        top: 11px;
      }
    }
    .pitem-value {
      color: #ffdd5f;
    }
    .item-value {
      color: #ffdd5f;
      width: 60px;
    }
    .item-level {
      font-size: 14px;
      margin-left: 50px;
      .max-h {
        color: #ff3c5d;
        margin-bottom: 5px;
      }
      .min-h {
        color: #27ffbc;
      }
    }
  }
}
</style>