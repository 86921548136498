<template>
  <div class="module-item early-warning">
    <SvgIcon iconClass="early_warning" />
    <div class="module-title warning">
      <span>预警统计</span>
      <SvgIcon iconClass="monitoring_module_b_title" />
    </div>
    <div class="top">
      <div :class="data.undealTotal > 0 ? 'stay' : 'normal'">
        <h1 @click="handleClickNumber('stay')" :class="data.undealTotal > 0 ? 'blink' : ''">{{ data.undealTotal }}</h1>
        <p>待处理</p>
      </div>
      <div :class="data.tdUndealTotal > 0 ? 'newly' : 'normal'">
        <h1 @click="handleClickNumber('newly')" :class="data.tdUndealTotal > 0 ? 'blink' : ''">{{ data.tdUndealTotal }}</h1>
        <p>今日新增</p>
      </div>
    </div>
    <ul>
      <li class="blue">
        <div @click="handleClickNumber('blueWarning')" :class="data.blueTotal > 0 ? 'blink' : ''">
          {{ data.blueTotal }}
        </div>
        <p>蓝色预警</p>
      </li>
      <li :class="data.yellowTotal > 0 ? 'yellow' : 'blue'">
        <div @click="handleClickNumber('yellowWarning')" :class="data.yellowTotal > 0 ? 'blink' : ''">
          {{ data.yellowTotal }}
        </div>
        <p>黄色预警</p>
      </li>
      <li :class="data.orangeTotal > 0 ? 'orange' : 'blue'">
        <div @click="handleClickNumber('orangeWarning')" :class="data.orangeTotal > 0 ? 'blink' : ''">
          {{ data.orangeTotal }}
        </div>
        <p>橙色预警</p>
      </li>
      <li :class="data.redTotal > 0 ? 'red' : 'blue'">
        <div @click="handleClickNumber('redWarning')" :class="data.redTotal > 0 ? 'blink' : ''">
          {{ data.redTotal }}
        </div>
        <p>红色预警</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "EarlyWarning",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClickNumber(type) {
      this.$emit("on-click", type);
    },
  },
};
</script>
<style lang="less" scoped>
.module-item {
  box-sizing: border-box;
  padding-top: 38px;
  position: relative;
  width: 471px;
  height: 250px;
  margin: 40px 0;
  svg {
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .module-title {
    position: absolute;
    top: -23px;
    left: 97px;
    width: 248px;
    height: 50px;
    text-align: center;
    svg {
      position: absolute;
      height: 50px;
      z-index: 0;
    }
    span {
      font-size: 23px;
      line-height: 50px;
      color: #ffffff;
      position: relative;
      padding-left: 30px;
      z-index: 2;
      &::before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        background: url(~@/assets/images/icon_earlywarnin.png) center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -3px;
        top: -2px;
      }
    }
  }
  ul,
  li {
    list-style: none;
  }
  .top,
  ul {
    display: flex;
  }
  .top {
    margin-bottom: 15px;
    justify-content: space-around;
    position: relative;
    padding-right: 40px;
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 48px;
      background: rgba(42, 154, 255, 0.28);
      position: absolute;
      top: 20px;
    }
    p {
      font-size: 20px;
      color: #fff;
    }
    h1 {
      font-size: 50px;
      cursor: pointer;
    }
    .stay {
      h1 {
        color: #ffdd5f;
        text-align: center;
      }
    }
    .newly {
      h1 {
        color: #ff3c5d;
        text-align: center;
      }
    }
    .normal {
      h1 {
        color: #ffffff;
        text-align: center;
      }
    }
  }
  ul {
    padding-top: 15px;
    padding-left: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      background-color: rgba(42, 154, 255, 0.28);
      width: 448px;
      top: 0;
      left: 4px;
    }
    li {
      margin: 0 8px;
      div {
        font-size: 30px;
        margin-bottom: 8px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        position: relative;
        padding-left: 16px;
        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
      &.blue {
        color: #00c2ff;
        text-align: center;
        p::before {
          background-color: #00c2ff;
        }
      }
      &.yellow {
        color: #ffdd5f;
        text-align: center;
        p::before {
          background-color: #ffdd5f;
        }
      }
      &.orange {
        color: #ff6541;
        text-align: center;
        p::before {
          background-color: #ff6541;
        }
      }
      &.red {
        color: #ff3c5d;
        text-align: center;
        p::before {
          background-color: #ff3c5d;
        }
      }
    }
  }
}

/* 定义keyframe动画，命名为blink */
@keyframes blink{
  0%{opacity: 1;}
  100%{opacity: 0;} 
}
/* 添加兼容性前缀 */
@-webkit-keyframes blink {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
@-moz-keyframes blink {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
@-ms-keyframes blink {
    0% {opacity: 1; } 
    100% { opacity: 0;}
}
@-o-keyframes blink {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
/* 定义blink类*/
.blink{
    animation: blink 1s linear infinite;  
    /* 其它浏览器兼容性前缀 */
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -ms-animation: blink 1s linear infinite;
    -o-animation: blink 1s linear infinite;
}
</style>