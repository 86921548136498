<template>
    <div>
      <el-table :data="dataInfoList" border>
          <el-table-column label="设备名称" prop="name"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
                <span v-if="scope.row.wwType === 1">水位计</span>
                <span v-else-if="scope.row.wwType === 2">库水位</span>
                <span v-else>高程</span>
            </template>
          </el-table-column>
          <el-table-column label="量程（米）" prop="liangcheng"></el-table-column>
          <el-table-column label="仪器高程（米）" prop="yqgaocheng"></el-table-column>
          <el-table-column label="监测值（米）" prop="currentValue"></el-table-column>
          <el-table-column label="蓝色预警">
              <template slot-scope="scope">
                  <span v-if="scope.row.alarms === null || scope.row.alarms.blueAlarmValue === null">未设置</span>
                  <span v-else>
                      {{
                      common.alarmTypeToString(
                      scope.row.alarms.blueAlarmValue,
                      scope.row.alarms.blueComparisonOperator
                      )
                      }}（米）
                  </span>
              </template>
          </el-table-column>
          <el-table-column label="黄色预警">
              <template slot-scope="scope">
                  <span v-if="scope.row.alarms === null || scope.row.alarms.yellowAlarmValue === null">未设置</span>
                  <span v-else>
                      {{
                      common.alarmTypeToString(
                      scope.row.alarms.yellowAlarmValue,
                      scope.row.alarms.yellowComparisonOperator
                      )
                      }}（米）
                  </span>
              </template>
          </el-table-column>
          <el-table-column label="橙色预警">
              <template slot-scope="scope">
                  <span v-if="scope.row.alarms === null || scope.row.alarms.orangeAlarmValue === null">未设置</span>
                  <span v-else>
                      {{
                      common.alarmTypeToString(
                      scope.row.alarms.orangeAlarmValue,
                      scope.row.alarms.orangeComparisonOperator
                      )
                      }}（米）
                  </span>
              </template>
          </el-table-column>
          <el-table-column label="红色预警">
              <template slot-scope="scope">
                  <span v-if="scope.row.alarms === null || scope.row.alarms.redAlarmValue === null">未设置</span>
                  <span v-else>
                      {{
                      common.alarmTypeToString(
                      scope.row.alarms.redAlarmValue,
                      scope.row.alarms.redComparisonOperator
                      )
                      }}（米）
                  </span>
              </template>
          </el-table-column>
          <el-table-column label="设备状态" width="80px">
              <template slot-scope="scope">
                  <el-tag v-if="common.outOfTime(scope.row.collectTime)" type="danger">异常</el-tag>
                  <el-tag v-else type="success">正常</el-tag>
              </template>
          </el-table-column>
          <el-table-column label="监测时间" prop="collectTime" width="160px"></el-table-column>
        </el-table>
    </div>
  </template>
  
  <script>
  export default {
      props: ["data"],
      data() {
          return {
              queryParams: {
                  projectCode: "",
                  pageNo: 1,
                  pageSize: 100,
              },
              dataInfoList: []
          }
      },
      created() {
  
      },
      mounted() {
          this.getDataList();
      },
      methods: {
          async getDataList() {
              this.queryParams.projectCode = this.data.projectCode;
              const { data: res } = await this.$http.get("device/wuwei/find/withalarm/page", {
                  params: this.queryParams
              });
              if (res.code == 0) {
                  this.dataInfoList = res.data.list;
              } else {
                  this.$message.error(res.msg);
              }
          },
      }
  }
  </script>
  
  <style>
  
  </style>