<template>
    <div>
        <el-table :data="dataInfoList" border>
            <el-table-column label="设备名称" prop="name"></el-table-column>
            <el-table-column label="今日总量（毫米）" prop="remark"></el-table-column>
            <el-table-column label="最新（毫米）" prop="currentValue"></el-table-column>
            <el-table-column label="蓝色预警">
                <template slot-scope="scope">
                    <span v-if="scope.row.alarms == null || scope.row.alarms.blueAlarmValue == null">未设置</span>
                    <span v-else>
                        {{ scope.row.alarms.blueRange }}小时内{{
                                common.alarmTypeToString(
                                    scope.row.alarms.blueAlarmValue,
                                    scope.row.alarms.blueComparisonOperator
                                )
                        }}（毫米）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="黄色预警">
                <template slot-scope="scope">
                    <span v-if="scope.row.alarms == null || scope.row.alarms.yellowAlarmValue == null">未设置</span>
                    <span v-else>
                        {{ scope.row.alarms.yellowRange }}小时内{{
                                common.alarmTypeToString(
                                    scope.row.alarms.yellowAlarmValue,
                                    scope.row.alarms.yellowComparisonOperator
                                )
                        }}（毫米）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="橙色预警">
                <template slot-scope="scope">
                    <span v-if="scope.row.alarms == null || scope.row.alarms.orangeAlarmValue == null">未设置</span>
                    <span v-else>
                        {{ scope.row.alarms.orangeRange }}小时内{{
                                common.alarmTypeToString(
                                    scope.row.alarms.orangeAlarmValue,
                                    scope.row.alarms.orangeComparisonOperator
                                )
                        }}（毫米）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="红色预警">
                <template slot-scope="scope">
                    <span v-if="scope.row.alarms == null || scope.row.alarms.redAlarmValue == null">未设置</span>
                    <span v-else>
                        {{ scope.row.alarms.redRange }}小时内{{
                                common.alarmTypeToString(
                                    scope.row.alarms.redAlarmValue,
                                    scope.row.alarms.redComparisonOperator
                                )
                        }}（毫米）
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="设备状态" width="80px">
                <template slot-scope="scope">
                    <el-tag v-if="common.outOfTime(scope.row.collectTime)" type="danger">异常</el-tag>
                    <el-tag v-else type="success">正常</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="监测时间" prop="collectTime" width="160px"></el-table-column>
        </el-table>
    </div>
</template>
  
<script>
export default {
    props: ["data"],
    data() {
        return {
            queryParams: {
                projectCode: "",
                pageNo: 1,
                pageSize: 10,
            },
            dataInfoList: []
        }
    },
    created() {

    },
    mounted() {
        this.getDataList();
    },
    methods: {
        async getDataList() {
            this.queryParams.projectCode = this.data.projectCode;
            const { data: res } = await this.$http.get("device/yuliang/find/withalarm/page", {
                params: this.queryParams
            });
            if (res.code == 0) {
                let that = this;
                this.dataInfoList = res.data.list;
                this.dataInfoList.forEach(d => {
                    that.getTodayTotal(d);
                });
            } else {
                this.$message.error(res.msg);
            }
        },
        async getTodayTotal(d) {
            const { data: res } = await this.$http.get("device/yuliang/find/totay/total", {
                params: {
                    projectCode: d.projectCode,
                    deviceCode: d.deviceCode
                }
            });
            if (res.code == 0) {
                d.remark = '' + res.data;
            }
        }
    }
}
</script>
  
<style>

</style>