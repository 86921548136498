<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="10">
        <div class="device-img">
          <img
            class="image-size"
            :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + deviceInfo.deviceImg"
          />
        </div>
      </el-col>
      <el-col :span="14">
        <div class="device-info">
          <el-row>
            <div class="dev-title">
              {{deviceInfo.dwName}}
            </div>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">设备类型：</div>
                <div class="d-value">全站仪点位</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">监测时间：</div>
                <div class="d-value" style="font-size: 14px;">{{deviceInfo.collectTime}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px; width: 50%;">东坐标Y：</div>
                <div class="d-value" style="font-size: 14px; width: 50%;">{{this.common.floatFormat(deviceInfo.codtY, 3)}}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px; width: 50%;">北坐标X：</div>
                <div class="d-value" style="font-size: 14px; width: 50%;">{{this.common.floatFormat(deviceInfo.codtX, 3)}}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px;">高程：</div>
                <div class="d-value" style="font-size: 14px;">{{this.common.floatFormat(deviceInfo.gaocheng, 3)}}m</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label">△X：</div>
                <div class="d-value">{{this.common.floatFormat(currentData.gapx, 3)}}mm</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label">△Y：</div>
                <div class="d-value">{{this.common.floatFormat(currentData.gapy, 3)}}mm</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label">△Z：</div>
                <div class="d-value">{{this.common.floatFormat(currentData.gapz, 3)}}mm</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px; width: 50%;">位移矢量：</div>
                <div class="d-value" style="font-size: 14px; width: 50%;">{{this.common.weiyiSl(currentData.gapx, currentData.gapy)}}mm</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px; width: 50%;">水平方向：</div>
                <div class="d-value" style="font-size: 14px; width: 50%;">{{this.common.weiyisp(currentData.gapx, currentData.gapy)}}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dev-text">
                <div class="d-label" style="font-size: 14px; width: 50%;">垂直方向：</div>
                <div class="d-value" style="font-size: 14px; width: 50%;">{{this.common.weiyicz(currentData.gapz)}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-1">蓝色预警：
                △X{{
                this.common.alarmTypeToString(
                alarmConfig.vxblueAlarmValue,
                alarmConfig.vxblueComparisonOperator
                )
                }}（mm）,
                △Y{{
                this.common.alarmTypeToString(
                alarmConfig.vyblueAlarmValue,
                alarmConfig.vyblueComparisonOperator
                )
                }}（mm）,
                △Z{{
                this.common.alarmTypeToString(
                alarmConfig.vzblueAlarmValue,
                alarmConfig.vzblueComparisonOperator
                )
                }}（mm）
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-2">黄色预警：
                △X{{
                this.common.alarmTypeToString(
                alarmConfig.vxyellowAlarmValue,
                alarmConfig.vxyellowComparisonOperator
                )
                }}（mm）,
                △Y{{
                this.common.alarmTypeToString(
                alarmConfig.vyyellowAlarmValue,
                alarmConfig.vyyellowComparisonOperator
                )
                }}（mm）,
                △Z{{
                this.common.alarmTypeToString(
                alarmConfig.vzyellowAlarmValue,
                alarmConfig.vzyellowComparisonOperator
                )
                }}（mm）
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-3">橙色预警：
                △X{{
                this.common.alarmTypeToString(
                alarmConfig.vxorangeAlarmValue,
                alarmConfig.vxorangeComparisonOperator
                )
                }}（mm）,
                △Y{{
                this.common.alarmTypeToString(
                alarmConfig.vyorangeAlarmValue,
                alarmConfig.vyorangeComparisonOperator
                )
                }}（mm）,
                △Z{{
                this.common.alarmTypeToString(
                alarmConfig.vzorangeAlarmValue,
                alarmConfig.vzorangeComparisonOperator
                )
                }}（mm）
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-4">红色预警：
                △X{{
                this.common.alarmTypeToString(
                alarmConfig.vxredAlarmValue,
                alarmConfig.vxredComparisonOperator
                )
                }}（mm）,
                △Y{{
                this.common.alarmTypeToString(
                alarmConfig.vyredAlarmValue,
                alarmConfig.vyredComparisonOperator
                )
                }}（mm）,
                △Z{{
                this.common.alarmTypeToString(
                alarmConfig.vzredAlarmValue,
                alarmConfig.vzredComparisonOperator
                )
                }}（mm）
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="detail-button">
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-pie-chart" round @click="showMonitorData">实时监测</el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-bell" round @click="showAlarmData">监测预警<span v-if="alarmNumber > 0" style="color: red;">({{alarmNumber}}条)</span></el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-document-copy" round @click="showHistoryData">历史数据</el-button>
              </el-col>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <!-- 显示实时监测 -->
    <el-dialog
      :title="monitorTitle"
      :visible.sync="showMonitorDataVisible"
      v-if="showMonitorDataVisible"
      width="100%"
      :fullscreen="true"
      append-to-body>
      <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showMonitorDataVisible = false">返回数据大屏</el-button>
      <v-quanzhan-rt :hiddenPath="true" ref="quanzhanRtRef"></v-quanzhan-rt>
    </el-dialog>


    <!-- 显示预警信息 -->
    <el-dialog
      :title="alarmTitle"
      :visible.sync="showAlarmDataVisible"
      v-if="showAlarmDataVisible"
      width="60%"
      append-to-body>
      <div style="height: 600px; overflow-y: auto;overflow-x: hidden;">
        <v-device-alarm-info :deviceInfo="deviceInfo"></v-device-alarm-info>
      </div>
    </el-dialog>


    <!-- 显示历史数据 -->
    <el-dialog
      :title="historyTitle"
      :visible.sync="showHistoryDataVisible"
      v-if="showHistoryDataVisible"
      width="100%"
      :fullscreen="true"
      append-to-body>
      <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showHistoryDataVisible = false">返回数据大屏</el-button>
      <v-quanzhan-ht :hiddenPath="true"></v-quanzhan-ht>
    </el-dialog>
  </div>
</template>

<script>
import vDeviceAlarmInfo from '../DeviceAlarmInfo.vue';
import vQuanzhanHt from '../../../datacenter/history/QuanzhanHt.vue';
import vQuanzhanRt from '../../../datacenter/realtime/QuanzhanRt.vue';
export default {
  components: {
    vDeviceAlarmInfo,
    vQuanzhanHt,
    vQuanzhanRt
  },
  props:['data'],
  data() {
    return {
      deviceInfo: {},
      alarmConfig: {},
      currentData: {},
      showMonitorDataVisible: false,
      showAlarmDataVisible: false,
      showHistoryDataVisible: false,
      alarmTitle: '',
      alarmNumber: 0,
      historyTitle: '',
      monitorTitle: ''
    }
  },
  created() {
    this.loadData(this.data);
  },
  watch: {
    data(value) {
      this.loadData(value);
    }
  },
  methods: {
    async loadData(data) {
      this.loadDeviceInfo(data);
      this.loadAlarmConfig(data);
    },
    async loadDeviceInfo(data) {
      const { data: res } = await this.$http.get("device/qz/dw/find/bycode/" + data.code);
      if (res.code == 0) {
        this.deviceInfo = res.data;
        this.deviceInfo.deviceCode = res.data.dwCode;
        this.loadLatestData(data);
        this.getAlarmNumber();
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadAlarmConfig (data) {
      const { data: res } = await this.$http.get("alarm/find/gnss/config", {
        params: {
          deviceCode: data.code
        }
      });
      if (res.code == 0) {
        this.alarmConfig = res.data;
      }
    },
    async loadLatestData(data) {
      const { data: res } = await this.$http.get("device/qz/dw/find/latest/bycode", {
        params: {
          deviceCode: data.code
        }
      });
      if (res.code == 0) {
        if(res.data == null) {
          this.clearData();
        } else {
          this.currentData = res.data;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    clearData() {
      this.currentData = {
        gapx: 0,
        gapy: 0,
        gapz: 0,
        aclx: 0,
        acly: 0,
        aclz: 0
      };
    },
    // 获取尚未处理的预警数量
    async getAlarmNumber() {
      const { data: res } = await this.$http.post("alarm/find/records", {
        projectCode: this.deviceInfo.projectCode,
        deviceCode: this.deviceInfo.dwCode,
        alarmStatus: 0,
        pageNo: 1,
        pageSize: 10
      });
      if (res.code == 0) {
        this.alarmNumber = res.data.total;
      }
    },
    showMonitorData() {
      this.monitorTitle = '[' + this.deviceInfo.dwName + ']实时监测';
      this.showMonitorDataVisible = true;
      setTimeout(() => {
        this.$refs.quanzhanRtRef.defaultLoadData(this.deviceInfo);
      }, 0);
    },
    showAlarmData() {
      this.alarmTitle = '[' + this.deviceInfo.dwName + ']预警信息';
      this.showAlarmDataVisible = true;
    },
    showHistoryData() {
      this.historyTitle = '[' + this.deviceInfo.dwName + ']历史数据查询';
      this.showHistoryDataVisible = true;
    }
  }
}
</script>

<style scoped>
.device-img {
  width: 100%;  
  height: 440px;
}

.image-size {
  width: 100%;
  height: 440px;
  object-fit: fill;
}

.device-info {
  width: 100%;
  height: 440px;
}

.dev-title {
  width: 100%;
  height: 80px;
  font-size: 36px;
  font-weight: bold;
  line-height: 80px;
  color: #ffdd5f;
  margin-left: 15px;
}

.dev-text {
  width: 100%;
  height: 40px;
  color: white;
}
.d-label {
  float: left;
  width: 35%;
  height: 40px;
  text-align: right;
  font-size: 16px;
  line-height: 40px;
}
.d-value {
  float: left;
  width: 65%;
  height: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 40px;
  overflow: hidden;
}
.alarm-tag {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: white;
  font-size: 14px;
}
.alarm-info-1 {
  width: 100%;
  height: 40px;
  background-color: #00c2ff;
  border-color: #00c2ff;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-2 {
  width: 100%;
  height: 40px;
  background-color: #ffdd5f;
  border-color: #ffdd5f;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-3 {
  width: 100%;
  height: 40px;
  background-color: #ff6541;
  border-color: #ff6541;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-4 {
  width: 100%;
  height: 40px;
  background-color: #ff3c5d;
  border-color: #ff3c5d;
  opacity: 0.8;
  text-indent: 10px;
}
.detail-button {
  width: 100%;
  height: 40px;
  margin-top: 10px;
}
</style>