<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>智能巡检</el-breadcrumb-item>
      <el-breadcrumb-item>类型管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-card>
        <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @change="getTableDataList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
      </el-card>
    </el-row>
    

    <el-row :gutter="5">
      <el-col :span="8">
        <el-card>
          <div slot="header">
            <span>巡检类型</span>
          </div>

          <!-- 表格数据 -->
          <div class="dataTable" ref="tableBox">
          <el-table :data="tableInfoList" border stripe @row-click="selectDzRow" v-if="tableHeight"
          :max-height="tableHeight + 'px'">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="类型名称" prop="checkTypeName"></el-table-column>
            <!-- <el-table-column label="备注" prop="remark"></el-table-column> -->
            <el-table-column label="操作" width="60px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="详情"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-warning-outline"
                    @click="showEditDialog(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
            style="right:0;bottom:0;position:absolute;"
          >
          </el-pagination>
          </div>

          <!-- 编辑巡检类型 -->
          <el-dialog
            title="巡检类型"
            :visible.sync="editDialogVisible"
            width="50%"
            v-if="editDialogVisible"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef" disabled>
              <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="dataFormObj.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" label-width="120px" prop="checkTypeName">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.checkTypeName"
                  clearable
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input
                  type="textarea"
                  v-model="dataFormObj.remark"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div slot="header">
            <span>巡检项</span>
          </div>
          <div class="select-gnss">
            <el-table :data="checkItemList" border stripe v-if="tableHeight"
              :max-height="tableHeight + 'px'">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="巡检项名" prop="itemName" width="180px"></el-table-column>
              <el-table-column label="巡检内容" prop="checkContent"></el-table-column>
              <el-table-column label="操作" width="60px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="详情" placement="top" :enterable="false">
                    <el-button type="primary" icon="el-icon-warning-outline" @click="showItemEditDialog(scope.row.id)"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            
            <!-- 分页组件 -->
            <el-pagination
              @size-change="handleItemSizeChange"
              @current-change="handleItemCurrentChange"
              :current-page="queryItemParams.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryItemParams.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="totalItem"
              style="right:0;bottom:0;position:absolute;"
            >
            </el-pagination>
          </div>



          <!-- 编辑巡检项 -->
          <el-dialog
            title="巡检项"
            :visible.sync="editItemDialogVisible"
            width="50%"
            v-if="editItemDialogVisible"
          >
            <el-form :model="itemDataForm" :rules="itemFormRules" ref="editItemInfoRef">
              <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="itemDataForm.projectCode"
                  placeholder="请选择项目"
                  disabled
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="巡检项名" label-width="120px" prop="itemName">
                <el-input
                  autocomplete="off"
                  v-model="itemDataForm.itemName"
                  disabled
                  placeholder="最多输入50个字"
                ></el-input>
              </el-form-item>
              <el-row :gutter="5">
                <el-col :span="11">
                  <el-form-item label="经度" label-width="120px" prop="itemLng">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLng"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="纬度" label-width="60px" prop="itemLat">
                    <el-input
                      autocomplete="off"
                      v-model="itemDataForm.itemLat"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button @click="editLngLatShowDialog" type="primary" class="el-icon-location-information">查看坐标</el-button>
                </el-col>
              </el-row>
              <el-form-item label="巡检内容" label-width="120px" prop="checkContent">
                <el-input
                  type="textarea"
                  v-model="itemDataForm.checkContent"
                  disabled
                ></el-input>
              </el-form-item>
            </el-form>
          </el-dialog>

          <el-dialog
            title="选择坐标"
            :visible.sync="showMapDialogVisible"
            v-if="showMapDialogVisible"
            width="60%">
            <v-coordinator :defaultPoit="markerPoit" :defaultAddress="mapAddress" :canEdit="mapCanEdit" @onLocation="mapLocation"></v-coordinator>
            <span slot="footer" class="dialog-footer">
              <el-button @click="showMapDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="showMapDialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import vCoordinator from "@/components/coordinatSelector/Coordinator.vue";
  export default {
    components: {
      vCoordinator
    },
    data() {
      return {
        addDialogVisible: false,
        editDialogVisible: false,
        addItemDialogVisible: false,
        editItemDialogVisible: false,
        tableInfoList: [],
        queryParams: {
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        queryItemParams: {
          typeCode: "",
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        totalItem: 0,
        formRules: {
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
          checkTypeName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        },
        dataFormObj: {},
        projectInfoList: [],
        currentSelectRow: {},
        tableHeight:0,
        checkItemList: [],
        itemDataForm: {},
        itemFormRules: {
          projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
          itemName: [{ required: true, message: "请输入名称", trigger: "blur" }],
          checkContent: [{ required: true, message: "请输入巡检内容", trigger: "blur" }],
          itemLng: [{ required: true, message: "请选择坐标", trigger: "blur" }],
          itemLat: [{ required: true, message: "请选择坐标", trigger: "blur" }],
        },
        showMapDialogVisible: false,
        mapCanEdit: false,
        mapAddress: "",
        markerPoit: {
          lng: "116.404",
          lat: "39.915",
        },
        // 是否新增方式打开地图
        showMapIsAdd: true,
      }
    },
    created() {
      this.getAllProject();
    },
    mounted(){
      this.tableHeight = this.$refs.tableBox.clientHeight - 40;
    },
    methods: {
      async getAllProject() {
        const { data: res } = await this.$http.get("project/find/all");
        if (res.code == 0) {
          this.projectInfoList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
      handleSizeChange(newSize) {
        this.queryParams.pageSize = newSize;
        this.getTableDataList();
      },
      handleCurrentChange(newPage) {
        this.queryParams.pageNo = newPage;
        this.getTableDataList();
      },
      handleItemSizeChange(newSize) {
        this.queryItemParams.pageSize = newSize;
        this.getCheckItemList();
      },
      handleItemCurrentChange(newPage) {
        this.queryItemParams.pageNo = newPage;
        this.getCheckItemList();
      },
      async getTableDataList(projectCode) {
        this.queryParams.projectCode = projectCode;
        this.queryItemParams.projectCode = projectCode;
        const { data: res } = await this.$http.get("check/type/find/byPage", {
          params: this.queryParams
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.tableInfoList = [];
            return;
          }
          this.tableInfoList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      async showEditDialog(id) {
        this.dataFormObj = {};
        const { data: res } = await this.$http.get("check/type/find/one/" + id);
        if (res.code == 0) {
          this.dataFormObj = res.data;
          this.editDialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      },
      async editInfoSubmit() {
        this.$refs.editInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "check/type/edit",
              this.dataFormObj
            );
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.getTableDataList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.editDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      selectDzRow(row, column, event) {
        this.currentSelectRow = row;
        this.getCheckItemList();
      },
      async getCheckItemList() {
        this.queryItemParams.typeCode = this.currentSelectRow.checkTypeCode;
        const { data: res } = await this.$http.get("check/item/find/byPage", {
          params: this.queryItemParams
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.checkItemList = [];
            return;
          }
          this.checkItemList = res.data.list;
          this.totalItem = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      async showItemEditDialog(id) {
        this.itemDataForm = {};
        const { data: res } = await this.$http.get("check/item/find/one/" + id);
        if (res.code == 0) {
          this.itemDataForm = res.data;
          this.editItemDialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      },
      async editItemInfoSubmit() {
        this.$refs.editItemInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "check/item/edit",
              this.itemDataForm
            );
            if (res.code == 0) {
              this.$message.success("修改成功");
              this.getCheckItemList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.editItemDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      editLngLatShowDialog() {
        this.mapCanEdit = false;
        this.showMapIsAdd = false;
        this.markerPoit = {lng:this.itemDataForm.itemLng, lat:this.itemDataForm.itemLat};
        this.showMapDialogVisible = true;
      },
      mapLocation(data) {
        this.itemDataForm.itemLng = '' + data.point.lng;
        this.itemDataForm.itemLat = '' + data.point.lat;
      },
      getProject(code) {
        var pro = null;
        this.projectInfoList.forEach(p => {
          if (code === p.projectCode) {
            pro = p;
          }
        });
        return pro;
      }
    }
  }
  </script>
  
<style lang="less" scoped>
    .dataTable {
      width: 100%;
      height: calc(100vh - 340px);
      position: relative;
    }
  
    .select-gnss {
      height: calc(100vh - 340px);
      position: relative;
    }
  </style>