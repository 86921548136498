<template>
  <div ref="containerRef" style="height: 100%; width: 100%"></div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      diagramChart: null,
    };
  },
  created () {

  },
  methods: {
    async loadData (projectCode, duanmianCode) {
      const { data: res } = await this.$http.get("device/shenya/show/duanmian/diagram/yl", {
        params: {
          projectCode: projectCode,
          duanmianCode: duanmianCode,
        }
      });
      if (res.code == 0) {
        this.drawData(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData (dataList) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(this.$refs.containerRef);
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      let deviceDataMap = {};
      let dataShowItem = {};
      let records = dataList.records;
      let times = dataList.times;
      let devices = [];
      for (const key in records) {
        devices.push(key);
        dataShowItem[key] = [];

        // 拿出每个设备的数据
        let ds = records[key];
        // 循环处理数据，以时间为key的map
        let dm = {};
        ds.forEach(d => {
          dm[d.collectTime] = d;
        });
        // 放到设备和数据关联的map中
        deviceDataMap[key] = dm;
      }

      //console.log(deviceDataMap);
      // 根据标准时间点，将所有数据取出，如果没有该时间点的数据，则获取上一个时间点数据
      let devTime = "";
      let timesArray = [];
      for (let i = (times.length - 1); i < times.length && i >= 0; i--) {
        const t = times[i];
        for (const key in deviceDataMap) {
          // 从最终显示的map中拿出数据列表
          let showDataList = dataShowItem[key];
          // 拿出每个设备的数据map
          let dm = deviceDataMap[key];
          // 判断该时间点是否有数据
          let d = dm[t];
          if (d == null) {
            // 该时间点没有数据
            if (showDataList.length == 0) {
              // 之前也没数据，则0
              showDataList.push('0');
            } else {
              showDataList.push(showDataList[showDataList.length - 1]);
            }
          } else {
            showDataList.push(d.currentValue);
          }

          // 放回map中
          dataShowItem[key] = showDataList;
        }
        devTime = t;
        timesArray.push(t);
      }

      // 修正第一条数据为0的情况
      for (const key in dataShowItem) {
        let list = dataShowItem[key];
        if (list[0] === '0') {
            list[0] = this.getAveraValue(list);
        }
      }

      // Y轴类型
      let yAxisType = 'value';
      let option = {
        title: {
          text: "渗流压力实时监测曲线图（单位：kPa）",
          textStyle: {
            fontSize: 16,
          },
          subtext: "采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "50",
          data: devices,
        },
        grid: {
          top: "100",
          bottom: "30",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timesArray,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} kPa'
          },
        },
        series: [
        ],
      };

      for (const key in dataShowItem) {
        option.series.push({
          name: key,
          type: "line",
          data: dataShowItem[key],
        });
      }

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    },
    getAveraValue(list) {
      var total = 0.0;
      let size = list.length;
      list.forEach(d => {
        total = total + this.common.floatFormat(d, 2)
      });
      return this.common.floatFormat(total / size, 6);
    }
  },
};
</script>

<style lang="less" scoped>
</style>