<template>
  <div id="container" style="height: 100%; width: 100%" ref="ctnRef"></div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      // 画布信息
      imgWidth: 670,
      imgHeight: 300,
      leftX: 20,
      bottomY: 0,

      // 水位预警线
      shuiweiYl: 0,
      shuiweiOr: 0,
      shuiweiRd: 0,
      // 水位高度
      shuiwei: 0,
      // 滩顶高程
      tanding: 0,
      // 滩顶高程画布高度
      tandingHt: 0,
      cexieChart: null,
    };
  },
  methods: {
    drawDiagram(deviceList, shuiweiData) {
      if (this.cexieChart == null) {
        this.cexieChart = this.$echarts.init(document.getElementById("container"));
      }

      this.shuiweiYl = this.common.floatFormat(shuiweiData.alarmYlSw, 2);
      this.shuiweiOr = this.common.floatFormat(shuiweiData.alarmOrSw, 2);
      this.shuiweiRd = this.common.floatFormat(shuiweiData.alarmRdSw, 2);
      this.shuiwei = this.common.floatFormat(shuiweiData.realSw, 2);
      this.tanding = this.common.floatFormat(shuiweiData.tdcg, 2);

      this.tandingHt = this.imgHeight - (this.imgHeight * 10) / 50;
      // 等比例的水位高度
      let showShuiwei = this.shuiwei * ((this.imgHeight * 20) / 50) / this.tanding;
      let shuiweiBm = ((this.imgHeight * 20) / 50);
      let option = {
        legend: {},
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: [],
        yAxis: [],
        series: [],
        graphic: [
          {
            type: "polygon",
            left: this.leftX,
            bottom: this.bottomY,
            z: 50,
            shape: {
              points: [
                [0, this.imgHeight],
                [this.imgWidth, this.imgHeight],
                [this.imgWidth, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 60) / 100, (this.imgHeight * 15) / 50],
                [(this.imgWidth * 55) / 100, (this.imgHeight * 5) / 50],
                [(this.imgWidth * 50) / 100, (this.imgHeight * 5) / 50],
                [(this.imgWidth * 40) / 100, (this.imgHeight * 20) / 50],
                [(this.imgWidth * 35) / 100, (this.imgHeight * 20) / 50],
                [(this.imgWidth * 25) / 100, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 20) / 100, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 10) / 100, (this.imgHeight * 40) / 50],
              ],
            },
            style: {
              fill: "rgb(180, 180, 180)",
              lineWidth: 1,
              stroke: "black",
            },
          },
          {
            type: "polygon",
            left: this.leftX,
            bottom: this.bottomY,
            z: 51,
            shape: {
              points: [
                [0, this.imgHeight],
                [this.imgWidth, this.imgHeight],
                [this.imgWidth, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 60) / 100, (this.imgHeight * 15) / 50],
                [(this.imgWidth * 46.7) / 100, (this.imgHeight * 10) / 50],
                [(this.imgWidth * 40) / 100, (this.imgHeight * 20) / 50],
                [(this.imgWidth * 35) / 100, (this.imgHeight * 20) / 50],
                [(this.imgWidth * 25) / 100, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 20) / 100, (this.imgHeight * 30) / 50],
                [(this.imgWidth * 10) / 100, (this.imgHeight * 40) / 50],
              ],
            },
            style: {
              fill: "rgb(180, 180, 180)",
              lineWidth: 1,
              stroke: "black",
            },
          },
          {
            type: "text",
            left: "center",
            top: 5,
            z: 100,
            style: {
              fill: "black",
              text: "剖面示意图",
              font: "14px Microsoft YaHei",
            },
          },
          {
            type: "text",
            left: (this.imgWidth * 85) / 100,
            bottom: showShuiwei + shuiweiBm,
            z: 100,
            style: {
              fill: "black",
              text: "库水位：" + this.shuiwei + "米",
              font: "12px Microsoft YaHei",
            },
          },
          {
            type: "text",
            left: (this.imgWidth * 35) / 100,
            bottom: (this.imgHeight * 40) / 50,
            z: 100,
            style: {
              fill: "black",
              text: "滩顶高程：" + this.tanding + "米",
              font: "12px Microsoft YaHei",
            },
          },
          {
            // 初级坝
            type: "sector",
            left: 10,
            bottom: this.bottomY,
            z: 100,
            shape: {
              r: this.imgWidth / 10,
              r0: 0,
              startAngle: 0,
              endAngle: Math.PI,
              clockwise: false,
            },
          },
          {
            // 水面
            type: "rect",
            left: this.imgWidth / 2 + this.leftX,
            bottom: shuiweiBm,
            z: 1,
            shape: {
              width: this.imgWidth / 2,
              height: showShuiwei,
            },
            style: {
              fill: "rgb(62, 200, 255)",
            },
          },
          {
            // 水面警戒线，黄色
            type: "rect",
            left: this.imgWidth / 2 + this.leftX,
            bottom: this.bottomY,
            z: 2,
            shape: {
              width: this.imgWidth / 2,
              height: this.shuiweiYl * this.tandingHt / this.tanding,
            },
            style: {
              fill: "rgba(255, 255, 255, 0)",
              lineWidth: 1,
              stroke: "yellow",
            },
          },
          {
            // 水面警戒线，橙色
            type: "rect",
            left: this.imgWidth / 2 + this.leftX,
            bottom: this.bottomY,
            z: 3,
            shape: {
              width: this.imgWidth / 2,
              height: this.shuiweiOr * this.tandingHt / this.tanding,
            },
            style: {
              fill: "rgba(255, 255, 255, 0)",
              lineWidth: 1,
              stroke: "orange",
            },
          },
          {
            // 水面警戒线，红色
            type: "rect",
            left: this.imgWidth / 2 + this.leftX,
            bottom: this.bottomY,
            z: 4,
            shape: {
              width: this.imgWidth / 2,
              height: this.shuiweiRd * this.tandingHt / this.tanding,
            },
            style: {
              fill: "rgba(255, 255, 255, 0)",
              lineWidth: 1,
              stroke: "red",
            },
          },
        ],
      };

      if (deviceList != null && deviceList.length > 0) {
        let offset = this.tandingHt / (deviceList.length + 1);
        for (let i = 0; i < deviceList.length; i++) {
          let item = {
            type: "circle",
            left: this.imgWidth / 2,
            bottom: (deviceList.length - i) * offset - 10,
            z: 101,
            shape: {
              r: 4
            },
            style: {
              fill: "yellow",
            }
          };
          let label = {
            type: "text",
            left: this.imgWidth / 2 + 10,
            bottom: (deviceList.length - i) * offset - 12,
            z: 101,
            style: {
              fill: "black",
              text: deviceList[i].name + "," + deviceList[i].currentValue,
              font: "10px Microsoft YaHei",
            }
          };
          option.graphic.push(item);
          option.graphic.push(label);
        }
      }

      this.cexieChart.clear();
      this.cexieChart.setOption(option);
    },
  },
};
</script>