<template>
    <div>
      <el-row :gutter="10">
        <el-col :span="18">
          <div class="content-main">
            <div
                  ref="chaogaoChart"
                  style="width: 100%; height: 100%; margin-left: 0px;"
                ></div>
          </div>
        </el-col>
        <el-col :span="6">
          <el-row>
            <div class="dev-label">设备类型：</div>
          </el-row>
          <el-row>
            <div class="dev-value">超高监测</div>
          </el-row>
          <el-row>
            <div class="dev-label">设备名称：</div>
          </el-row>
          <el-row>
            <div class="dev-value">{{deviceInfo.name}}</div>
          </el-row>
          <el-row>
            <div class="dev-label">监测时间：</div>
          </el-row>
          <el-row>
            <div class="dev-label-s">{{deviceInfo.collectTime}}</div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-pie-chart" round @click="showMonitorData">监测详情</el-button>
            </div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-bell" round @click="showAlarmData">监测预警<span v-if="alarmNumber > 0" style="color: red;">({{alarmNumber}}条)</span></el-button>
            </div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-document-copy" round @click="showHistoryData">历史数据</el-button>
            </div>
          </el-row>
        </el-col>
      </el-row>
  
      <!-- 显示实时监测 -->
      <el-dialog
        :title="monitorTitle"
        :visible.sync="showMonitorDataVisible"
        v-if="showMonitorDataVisible"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showMonitorDataVisible = false">返回数据大屏</el-button>
        <v-chaogao-rt :hiddenPath="true" ref="chaogaoRtRef"></v-chaogao-rt>
      </el-dialog>
  
  
      <!-- 显示预警信息 -->
      <el-dialog
        :title="alarmTitle"
        :visible.sync="showAlarmDataVisible"
        v-if="showAlarmDataVisible"
        width="70%"
        append-to-body>
        <div style="height: 600px; overflow-y: auto;overflow-x: hidden;">
          <v-device-alarm-info :deviceInfo="deviceInfo" :group-type="40"></v-device-alarm-info>
        </div>
      </el-dialog>
  
  
      <!-- 显示历史数据 -->
      <el-dialog
        :title="historyTitle"
        :visible.sync="showHistoryDataVisible"
        v-if="showHistoryDataVisible"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showHistoryDataVisible = false">返回数据大屏</el-button>
        <v-chaogao-ht-main :hiddenPath="true"></v-chaogao-ht-main>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import vDeviceAlarmInfo from '../DeviceAlarmInfo.vue';
  import vChaogaoHtMain from '../../../datacenter/history/ChaogaoHt.vue';
  import vChaogaoRt from '../../../datacenter/realtime/ChaogaoRt.vue';
  export default {
    components: {
      vDeviceAlarmInfo,
      vChaogaoHtMain,
      vChaogaoRt
    },
    props:['data'],
    data() {
      return {
        deviceInfo: {},
        alarmConfig: {},
        showMonitorDataVisible: false,
        showAlarmDataVisible: false,
        showHistoryDataVisible: false,
        alarmTitle: '',
        alarmNumber: 0,
        historyTitle: '',
        monitorTitle: '',
        diagramChart: null
      }
    },
    created() {
      this.loadData(this.data);
    },
    watch: {
      data(value) {
        this.loadData(value);
      }
    },
    methods: {
      async loadData(data) {
        this.loadDeviceInfo(data);
        this.loadAlarmConfig(data);
      },
      async loadDeviceInfo(data) {
        const { data: res } = await this.$http.get("device/chaogao/find/bycode/" + data.code);
        if (res.code == 0) {
          this.deviceInfo = res.data;
          this.getDataList();
          this.getAlarmNumber();
        } else {
          this.$message.error(res.msg);
        }
      },
      async loadAlarmConfig(data) {
        const { data: res } = await this.$http.get("alarm/find/config", {
          params: {
            deviceCode: data.code
          }
        });
        if (res.code == 0 && res.data != null) {
          this.alarmConfig = res.data;
        }
      },
      // 获取尚未处理的预警数量
      async getAlarmNumber() {
        const { data: res } = await this.$http.post("alarm/find/records", {
          projectCode: this.deviceInfo.projectCode,
          deviceCode: this.deviceInfo.deviceCode,
          alarmStatus: 0,
          pageNo: 1,
          pageSize: 10
        });
        if (res.code == 0) {
          this.alarmNumber = res.data.total;
        }
      },
      showMonitorData() {
        this.monitorTitle = '[' + this.deviceInfo.name + ']实时监测';
        this.showMonitorDataVisible = true;
        setTimeout(() => {
          this.$refs.chaogaoRtRef.defaultLoadData(this.deviceInfo);
        }, 0);
      },
      showAlarmData() {
        this.alarmTitle = '[' + this.deviceInfo.name + ']预警信息';
        this.showAlarmDataVisible = true;
      },
      showHistoryData() {
        this.historyTitle = '[' + this.deviceInfo.name + ']历史数据查询';
        this.showHistoryDataVisible = true;
      },
  
      async getDataList () {
        // 获取最新的20条数据
        const { data: res } = await this.$http.post("device/chaogao/history/data/diagram", {
          projectCode: this.deviceInfo.projectCode,
          deviceCode: this.deviceInfo.deviceCode,
          pageNo: 1,
          pageSize: 100
        });
        if (res.code == 0) {
          this.drawData(res.data.list, res.data.alarmConfig);
        } else {
          this.$message.error(res.msg);
        }
      },
      drawData (dataList, alarmConfig) {
        // 基于准备好的dom，初始化echarts实例
        if (this.diagramChart == null) {
          this.diagramChart = this.$echarts.init(this.$refs.chaogaoChart);
        }
  
        if (dataList == null || dataList.length == 0) {
          this.diagramChart.clear();
          return;
        }
  
        let blueData = [];
        let yellowData = [];
        let orangeData = [];
        let redData = [];
  
        // 无限接近0的值
        let valueData = [];
        let timeData = [];
        let devName = "";
        let devTime = "";
        for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
          let v = this.common.floatFormat(dataList[i].currentValue, 4);
          if (v == 0) {
            // 过滤0的值
            continue;
          } else {
            valueData.push(v);
          }
  
          timeData.push(dataList[i].collectTime);
          devName = dataList[i].deviceName;
          devTime = dataList[i].collectTime;
          if (alarmConfig != null) {
            if (alarmConfig.blueAlarmValue != null && alarmConfig.blueAlarmValue != '') {
              blueData.push(this.common.floatFormat(alarmConfig.blueAlarmValue, 2));
            }
            if (alarmConfig.yellowAlarmValue != null && alarmConfig.yellowAlarmValue != '') {
              yellowData.push(this.common.floatFormat(alarmConfig.yellowAlarmValue, 2));
            }
            if (alarmConfig.orangeAlarmValue != null && alarmConfig.orangeAlarmValue != '') {
              orangeData.push(this.common.floatFormat(alarmConfig.orangeAlarmValue, 2));
            }
            if (alarmConfig.redAlarmValue != null && alarmConfig.redAlarmValue != '') {
              redData.push(this.common.floatFormat(alarmConfig.redAlarmValue, 2));
            }
          }
        }
  
        // Y轴类型
        let yAxisType = '';
        if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
          // 有预警线的类型
          // yAxisType = 'log';
          yAxisType = 'value';
        } else {
          yAxisType = 'value';
        }
  
        let option = {
          title: {
            text: "超高实时监测曲线图（单位：m）",
            textStyle: {
              fontSize: 14,
            },
            subtext: "设备：" + devName + ", 采集时间：" + devTime,
            subtextStyle: {
              fontSize: 12,
            }
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [],
          },
          grid: {
            top: "90",
            bottom: "30",
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: timeData,
          },
          yAxis: {
            type: yAxisType,
            axisLabel: {
              formatter: '{value} m'
            },
          },
          series: [
            {
              name: "超高",
              type: "line",
              smooth: true,
              data: valueData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
            },
          ],
        };
  
        if (yellowData.length > 0) {
          option.series.push({
            name: "黄色预警线",
            type: "line",
            data: yellowData,
            lineStyle: {
              color: 'yellow',
              type: 'dashed'
            },
            itemStyle: {
              color: 'yellow',
            }
          });
        }
  
        if (orangeData.length > 0) {
          option.series.push({
            name: "橙色预警线",
            type: "line",
            data: orangeData,
            lineStyle: {
              color: 'orange',
              type: 'dashed'
            },
            itemStyle: {
              color: 'orange',
            }
          });
        }
  
        if (redData.length > 0) {
          option.series.push({
            name: "红色预警线",
            type: "line",
            data: redData,
            lineStyle: {
              color: 'red',
              type: 'dashed'
            },
            itemStyle: {
              color: 'red',
            }
          });
        }
  
        // 绘制图表
        this.diagramChart.clear();
        this.diagramChart.setOption(option);
      },
    }
  }
  </script>
  
  <style scoped>
  .content-main {
    width: 100%;
    height: 400px;
    background-color: white;
  }
  .dev-label {
    width: 100%;
    height: 25px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 25px;
    color: white;
  }
  .dev-label-s {
    width: 100%;
    height: 25px;
    font-size: 16px;
    line-height: 25px;
    color: white;
  }
  .dev-value {
    width: 100%;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    color: white;
    font-weight: bold;
  }
  .btn-style {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    text-align: center;
  }
  </style>