<template>
  <div style="height:100%;width:100%">
    <div v-if="!canEdit" class="normal-image">
      <el-popover placement="right" width="300" trigger="hover">
        <img src="../../../assets/img/bm1.png" height="300" width="300" />
        <img src="../../../assets/img/bm1.png" class="img-size" slot="reference" />
      </el-popover>
      <el-popover placement="right" width="300" trigger="hover">
        <img src="../../../assets/img/cx1.png" height="300" width="300" />
        <img src="../../../assets/img/cx1.png" class="img-size" slot="reference"/>
      </el-popover>
      <el-popover placement="right" width="300" trigger="hover">
        <img src="../../../assets/img/cx2.png" height="300" width="300" />
        <img src="../../../assets/img/cx2.png" class="img-size" slot="reference"/>
      </el-popover>
    </div>
    <div class="power"><span style="background-color: black;opacity: 0.8;">版权所有：云南坤测科技有限公司    电话：0871-68256314</span></div>
    <baidu-map ak=COMMON_CONFIG.BAIDU_MAP_AK :dragging="true" :scroll-wheel-zoom="true" :double-click-zoom="true" :pinch-to-zoom="true" @rightclick="addDevices" @ready="mapReady"
      :center="center" :zoom="zoom" style="height:calc(100%);filter: brightness(1.35);">
      <!--地图视图-->
      <bm-view class="map" ref="mapRootRef"></bm-view>
      <!-- <bm-marker :position="projectPoit" :dragging="false"> </bm-marker> -->
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
    </baidu-map>

    <el-dialog title="添加设备" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <el-row :gutter="5">
        <el-col :span="8">
          <el-select v-model="deviceSelected.type" placeholder="请选择设备类型" @change="getDeviceList">
            <el-option label="干滩" :value="10"></el-option>
            <el-option label="水位计" :value="20"></el-option>
            <el-option label="超高" :value="21"></el-option>
            <el-option label="明渠流量计" :value="22"></el-option>
            <el-option label="全站仪" :value="30"></el-option>
            <el-option label="全站仪点位" :value="32"></el-option>
            <el-option label="GNSS" :value="33"></el-option>
            <el-option label="内部测斜仪" :value="40"></el-option>
            <el-option label="渗压计" :value="50"></el-option>
            <el-option label="雨量计" :value="60"></el-option>
            <el-option label="浊度仪" :value="91"></el-option>
            <el-option label="中控室" :value="98"></el-option>
            <el-option label="水工坝" :value="99"></el-option>
            <el-option label="摄像头" :value="95"></el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <el-select v-model="deviceSelected.code" placeholder="请选择设备" clearable @change="selectDevice">
            <el-option v-for="item in deviceDataList" :key="item.deviceCode" :label="item.name"
              :value="item.deviceCode">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-checkbox v-model="showInGroup">以孔号/断面显示</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitMarker">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import KMarker from "@/utils/Marker";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView"; //地图视图
import BmMarker from "vue-baidu-map/components/overlays/Marker"; //点标注
import BmNavigation from "vue-baidu-map/components/controls/Navigation"; //点标注
import BmMapType from "vue-baidu-map/components/controls/MapType"; //点标注
import BmScale from "vue-baidu-map/components/controls/Scale"; //点标注
import idev10 from "@/assets/img/device/waterlevelgauge_blue.png";
import idev20 from "@/assets/img/device/waterlevelgauge_blue.png";
import idev21 from "@/assets/img/device/waterlevelgauge_blue.png";
import idev22 from "@/assets/img/device/openchannelflowmeter_blue.png";
import idev30 from "@/assets/img/device/totalstation_blue.png";
import idev32 from "@/assets/img/device/totalstationpoint_blue.png";
import idev33 from "@/assets/img/device/gnss_blue.png";
import idev40 from "@/assets/img/device/inclinometer_blue.png";
import idev50 from "@/assets/img/device/osmometer_blue.png";
import idev60 from "@/assets/img/device/raingauge_blue.png";
import idev91 from "@/assets/img/device/turbidimeter_blue.png";
import idev99 from "@/assets/img/device/mainobject_blue.png";
import idev95 from "@/assets/img/device/camera_blue.png";

import idev10red from "@/assets/img/device/waterlevelgauge_red.png";
import idev20red from "@/assets/img/device/waterlevelgauge_red.png";
import idev21red from "@/assets/img/device/waterlevelgauge_red.png";
import idev22red from "@/assets/img/device/openchannelflowmeter_red.png";
import idev30red from "@/assets/img/device/totalstation_red.png";
import idev32red from "@/assets/img/device/totalstationpoint_red.png";
import idev33red from "@/assets/img/device/gnss_red.png";
import idev40red from "@/assets/img/device/inclinometer_red.png";
import idev50red from "@/assets/img/device/osmometer_red.png";
import idev60red from "@/assets/img/device/raingauge_red.png";
import idev91red from "@/assets/img/device/turbidimeter_red.png";
import idev95red from "@/assets/img/device/camera_red.png";
export default {
  components: {
    BaiduMap,
    BmView,
    BmMarker,
    BmNavigation,
    BmMapType,
    BmScale
  },
  props: {
    canEdit: Boolean,
    showController: Boolean,
  },
  inheritAttrs: false,
  data() {
    return {
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfo: null,
      dialogVisible: false,
      center: {
        //经纬度
        lng: 0,
        lat: 0,
      },
      zoom: 17, //地图展示级别
      projectPoit: {
        lng: 0,
        lat: 0,
      },
      dbMap: null,
      deviceIcon: {
        d10: idev10,
        d20: idev20,
        d21: idev21,
        d22: idev22,
        d30: idev30,
        d32: idev32,
        d33: idev33,
        d40: idev40,
        d50: idev50,
        d60: idev60,
        d91: idev91,
        d99: idev99,
        d95: idev95
      },
      deviceAlarmIcon: {
        d10: idev10red,
        d20: idev20red,
        d21: idev21red,
        d22: idev22red,
        d30: idev30red,
        d32: idev32red,
        d33: idev33red,
        d40: idev40red,
        d50: idev50red,
        d60: idev60red,
        d91: idev91red,
        d95: idev95red,
        d99: idev99
      },
      deviceMarker: null,
      showInGroup: false, // 是否以组合的方式展示
      deviceSelected: {
        id: 0,
        type: 10,
        code: null,
        name: '',
        groupShow: 0
      },
      deviceDataList: [], // 设备原始数据
      deviceMapList: [], // 设备地图上的数据
      projectDiagramInfo: {
        forceOnBl: true,
        forceOn: 1
      },
      selectBtnShow: false,
      showDeviceTypes: new Set(),  // 要显示的设备类型
    };
  },
  created() {
    
  },
  methods: {
    setProjectInfo (project) {
      if (project === null) {
        return;
      }

      this.projectInfo = project;

      if (this.dbMap === null) {
        return;
      }

      this.center.lat = project.lat;
      this.center.lng = project.lng;

      this.projectPoit.lat = project.lat;
      this.projectPoit.lng = project.lng;

      this.getDiagramInfoAndAlarm();
    },
    // 新方法，合并获取设备信息和预警信息
    async getDiagramInfoAndAlarm() {
      let requestMerge = new Array();
      let r1 = this.$http.get("project/find/map/diagram", {
        params: {
          projectCode: this.projectInfo.projectCode,
        }
      });
      let r2 = this.$http.get("alarm/undone/deviceCodes", {
        params: {
          projectCode: this.projectInfo.projectCode,
        }
      });
      requestMerge.push(r1);
      requestMerge.push(r2);
      let that = this;
      Promise.all(requestMerge).then(responseList => {
        let rs1 = responseList[0];
        let rs2 = responseList[1];
        if (rs1.data.code == 0 && rs1.data.data != null) {
          that.projectDiagramInfo = rs1.data.data;
          that.handleLoadProjectDiagram(that.projectDiagramInfo);
          if (that.projectDiagramInfo.diagramContent != null && that.projectDiagramInfo.diagramContent != '') {
            var dataList = JSON.parse(that.projectDiagramInfo.diagramContent);
            // that.saveDeviceTypesSet(dataList);
            var rs = that.dataFilter(dataList);
            that.initDeviceMarker(rs.addNode);
            that.deleteMarkerList(rs.delNode);
            that.showDeviceAlarm(rs2.data.data);
            that.saveDeviceTypesSet(dataList);
          }
        }
      });
    },
    // 旧方法，只获取地图设备内容
    async getDiagramInfo () {
      const { data: res } = await this.$http.get("project/find/map/diagram", {
        params: {
          projectCode: this.projectInfo.projectCode,
        }
      });
      if (res.code == 0 && res.data != null) {
        this.projectDiagramInfo = res.data;
        this.handleLoadProjectDiagram(this.projectDiagramInfo);
        if (this.projectDiagramInfo.diagramContent != null && this.projectDiagramInfo.diagramContent != '') {
          var dataList = JSON.parse(this.projectDiagramInfo.diagramContent);
          this.saveDeviceTypesSet(dataList);
          var rs = this.dataFilter(dataList);
          this.initDeviceMarker(rs.addNode);
          this.deleteMarkerList(rs.delNode);
        }
      }
    },
    async getDeviceList() {
      const { data: res } = await this.$http.get("device/find/bytype", {
        params: {
          projectCode: this.projectInfo.projectCode,
          deviceType: this.deviceSelected.type,
        }
      });
      if (res.code == 0) {
        this.deviceDataList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    selectDevice(data) {
      this.deviceDataList.forEach(e => {
        if (e.deviceCode === data) {
          this.deviceSelected.name = e.name;
          this.deviceSelected.code = e.deviceCode;
        }
      });
    },
    addDevices(val) {
      if (!this.canEdit) {
        // 不允许修改
        return;
      }
      // 绘制圆
      var device = new BMap.Marker(val.point, {
          enableDragging: this.canEdit
      });
      
      this.dbMap.addOverlay(device);
      this.deviceMarker = device;
      this.deviceSelected.code = null;
      this.deviceSelected.type = 10;
      this.dialogVisible = true;
    },
    initDeviceMarker(dataList) {
      if (this.dbMap == null) {
        return;
      }
      let that = this;
      dataList.forEach(d => {
        var point = new BMap.Point(d.lng, d.lat);
        var marker = new BMap.Marker(point, {
          enableDragging: that.canEdit
        });
        if (!that.canEdit) {
          marker.addEventListener('click', function () {
            that.handleMarkerClick(d);
          });
        }
        this.dbMap.addOverlay(marker);
        this.createDeviceMarker(marker, d);
      });
      this.handleLoadMarker(this.deviceMapList);
    },
    mapReady({ BMap, map }) {
      map.setMapType(BMAP_HYBRID_MAP);
      this.dbMap = map;
      this.setProjectInfo(this.projectInfo);
      if (this.showController) {
        this.createMapControl(map);
      }
    },
    createDeviceMarker(mk, device) {
      let iconImg = null;
      let alarmIconImg = null;
      let typeName = "";
      switch (device.type) {
        case 10:
          iconImg = this.deviceIcon.d10;
          alarmIconImg = this.deviceAlarmIcon.d10;
          typeName = "干滩";
          break;
        case 20:
          iconImg = this.deviceIcon.d20;
          alarmIconImg = this.deviceAlarmIcon.d20;
          typeName = "水位计";
          break;
        case 21:
          iconImg = this.deviceIcon.d21;
          alarmIconImg = this.deviceAlarmIcon.d21;
          typeName = "超高";
          break;
        case 22:
          iconImg = this.deviceIcon.d22;
          alarmIconImg = this.deviceAlarmIcon.d22;
          typeName = "明渠流量计";
          break;
        case 30:
          iconImg = this.deviceIcon.d30;
          alarmIconImg = this.deviceAlarmIcon.d30;
          typeName = "全站仪";
          break;
        case 32:
          iconImg = this.deviceIcon.d32;
          alarmIconImg = this.deviceAlarmIcon.d32;
          typeName = "全站仪点位";
          break;
        case 33:
          iconImg = this.deviceIcon.d33;
          alarmIconImg = this.deviceAlarmIcon.d33;
          typeName = "GNSS";
          break;
        case 40:
          iconImg = this.deviceIcon.d40;
          alarmIconImg = this.deviceAlarmIcon.d40;
          typeName = "内部测斜仪";
          break;
        case 50:
          iconImg = this.deviceIcon.d50;
          alarmIconImg = this.deviceAlarmIcon.d50;
          typeName = "渗压计";
          break;
        case 60:
          iconImg = this.deviceIcon.d60;
          alarmIconImg = this.deviceAlarmIcon.d60;
          typeName = "雨量计";
          break;
        case 91:
          iconImg = this.deviceIcon.d91;
          alarmIconImg = this.deviceAlarmIcon.d91;
          typeName = "浊度仪";
          break;
        case 98:
          iconImg = this.deviceIcon.d99;
          alarmIconImg = this.deviceAlarmIcon.d99;
          typeName = "中控室";
          break;
        case 99:
          iconImg = this.deviceIcon.d99;
          alarmIconImg = this.deviceAlarmIcon.d99;
          typeName = "水工坝";
          break;
        case 95:
          iconImg = this.deviceIcon.d95;
          alarmIconImg = this.deviceAlarmIcon.d95;
          typeName = "摄像头";
          break;
        default:
          break;
      }
      var icon = new BMap.Icon(iconImg, new BMap.Size(32, 32));
      mk.setIcon(icon);
      mk.setTitle(device.name);
      var html = '<div style="width: fit-content;height: 28px;padding-left: 20px;padding-right: 10px;background-color: rgba(62, 62, 62, 0.6);border-radius: 14px;font-size: 12px;line-height: 14px;color: white;">'+
      '<div style="color: aqua;">'+typeName+'</div>'+
      '<div>'+device.name+'</div>'+
    '</div>';
      var lb = new BMap.Label(html);
      lb.setOffset(new BMap.Size(10, -1));
      lb.setStyle({border: 0, background: "rgba(255,255,255,0)", zIndex:-1});
      lb.setZIndex(mk.zIndex-1);
      mk.setLabel(lb);
      mk._id = device.code;
      mk._type = device.type;
      this.deviceMapList.push(KMarker.createMarker({
        type: device.type,
        code: device.code,
        name: device.name,
        lng: mk.getPosition().lng,
        lat: mk.getPosition().lat,
        marker: mk,
        icon: iconImg,
        alarmIcon: alarmIconImg,
        groupShow: device.groupShow
      }));
    },
    async submitMarker() {
      if (this.deviceSelected.code === null || this.deviceSelected.code === '') {
        this.$message.error("请选择设备！");
        return;
      }

      if (this.markerIsExist(this.deviceSelected.name)) {
        this.$message.error("设备已添加！");
        return;
      }

      // 判断是否以组合的方式展示，等待异步执行完毕
      await this.isShowInGroup(this.deviceSelected);

      this.createDeviceMarker(this.deviceMarker, this.deviceSelected);
      this.showDeviceTypes.add(this.deviceSelected.type);
      this.dialogVisible = false;
      this.handleLoadMarker(this.deviceMapList);
    },
    isShowInGroup(device) {
      return new Promise((resolve, reject) => {
        if (device.type === 40 && this.showInGroup) {
          // 内部位移
          var pm = this.findKonghaoByDevice(device.code);
          pm.then((data) => {
            if (data != null) {
              device.name = data.khName;
              device.groupShow = 1;
            }
            resolve();
          });
        } else if (device.type === 50 && this.showInGroup) {
          // 渗流压力
          var pm = this.findDuanmianByDevice(device.code);
          pm.then((data) => {
            if (data != null) {
              device.name = data.name;
              device.groupShow = 1;
            }
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    async findDuanmianByDevice(deviceCode) {
      const { data: res } = await this.$http.get("device/shenya/find/dm/bydevcode", {
        params: {
          deviceCode: deviceCode
        }
      });
      if (res.code == 0 && res.data != null) {
        return res.data;
      } else {
        return null;
      }
    },
    async findKonghaoByDevice(deviceCode) {
      const { data: res } = await this.$http.get("device/cexie/find/kh/bydevcode", {
        params: {
          deviceCode: deviceCode
        }
      });
      if (res.code == 0 && res.data != null) {
        return res.data;
      } else {
        return null;
      }
    },
    markerIsExist(devName) {
      var r = false;
      this.deviceMapList.forEach(e => {
        if (e.name === devName) {
          r = true;
        }
      });
      return r;
    },
    handleClose(done) {
      if (this.deviceMarker) {
        this.dbMap.removeOverlay(this.deviceMarker);
      }
      done();
    },
    async saveData(pdi) {
      let reqData = [];
      this.deviceMapList.forEach(n => {
        reqData.push(n.toJsonObj());
      });
      // console.log(reqData);
      
      if (pdi.forceOnBl) {
        this.projectDiagramInfo.forceOn = 1;
      } else {
        this.projectDiagramInfo.forceOn = 0;
      }
      this.projectDiagramInfo.projectCode = this.projectInfo.projectCode;
      this.projectDiagramInfo.diagramContent = JSON.stringify(reqData);
      const { data: res } = await this.$http.post("project/save/map/diagram", this.projectDiagramInfo);
      if (res.code == 0) {
        this.$message.success("保存成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleLoadMarker(data) {
      this.$emit("onLoadMarker", data);
    },
    handleLoadProjectDiagram(data) {
      this.$emit("onLoadProjectDiagram", data);
    },
    handleMarkerClick(data) {
      if (data.type === 98 || data.type === 99) {
        return;
      }
      // var m = this.getMarkerByCode(data.code);
      // m.showAlarmFun();
      this.$emit("onMarkerClick", data);
    },
    handleDeviceTypeChange(data) {
      this.$emit("onLoadDeviceType", data);
    },
    deleteMarkerList(markers) {
      markers.forEach(m => {
        this.delete2Diagram(m);
      });
    },
    delete2Diagram(data) {
      // 使用数组filter过滤元素
      let that = this;
      this.deviceMapList = this.deviceMapList.filter(n => {
        if (n.id === data.id) {
          n.removeMarkerFromMap(that.dbMap);
          return false;
        } else {
          return true;
        }
      });
      this.handleLoadMarker(this.deviceMapList);
    },
    // 将加载回来的数据去重展示
    dataFilter (remoteData) {
      var remoteMap = new Map();
      remoteData.forEach(rd => {
        remoteMap.set(rd.code, rd);
      });

      var localMap = new Map();
      this.deviceMapList.forEach(d => {
        localMap.set(d.code, d);
      });

      var addList = []; // 要添加的列表
      var deleteList = []; // 要删除的列表
      this.deviceMapList.forEach(d => {
        // 寻找删除的节点
        var n = remoteMap.get(d.code);
        if (n === null || n === undefined) {
          deleteList.push(d);
        }
      });

      remoteData.forEach(rd => {
        // 寻找增加的节点
        var n = localMap.get(rd.code);
        if (n === null || n === undefined) {
          addList.push(rd);
        }
      });

      return {
        addNode: addList,
        delNode: deleteList
      };
    },
    // 获取所有设备类型
    saveDeviceTypesSet(datalist) {
      let that = this;
      datalist.forEach(d => {
        that.showDeviceTypes.add(d.type);
      });
      this.handleDeviceTypeChange(this.showDeviceTypes);
    },
    createMapControl (map) {
      let that = this;
      MapShowControl.prototype = new BMap.Control();
      MapShowControl.prototype.initialize = function (map) {
        //创建一个dom元素
        var div = document.createElement('div');
        var bt1 = document.createElement('button');
        div.style.cssText = "width: 170px;";
        var csss = "font-size: 12px;color: white;border-radius:10px;border: 0px;opacity: 0.8;line-height: 25px;margin-left: 5px;float: left;font-size: 14px;text-align: center;cursor: pointer;width: 80px;height: 25px;background-color: #409EFF;";
        bt1.style.cssText = csss;
        bt1.innerHTML = "选择设备";
        //添加文字说明
        div.appendChild(bt1);
        bt1.onclick = function (e) {
          that.showTypeSelect(e, div);
        }
        // 添加DOM元素到地图中
        map.getContainer().appendChild(div);
        // 将DOM元素返回
        return div;
      }
      //创建控件元素
      var c = new MapShowControl((this.$refs.mapRootRef.$el.offsetWidth / 2) - 60);
      //添加到地图中
      map.addControl(c);
    },
    showTypeSelect(e, d) {
      if (!this.selectBtnShow) {
        var top = e.target.offsetTop;
        var left = e.target.offsetLeft;
        var div = document.createElement('div');
        div.classList.add("select-type-panel");
        div.style.cssText = "opacity: 0.8;padding: 4px;background-color: white;";
        // div.style.width = "100px";
        // div.style.height = "200px";
        div.style.position = "absolute";
        div.style.top = "" + (top + 30) + "px";
        this.createDeviceTypeCheckbox(div);
        d.appendChild(div);
      } else {
        var panel = d.querySelectorAll(".select-type-panel");
        d.removeChild(panel[0]);
      }
      this.selectBtnShow = !this.selectBtnShow;
    },
    createDeviceTypeCheckbox(p) {
      var typeSet = new Set();
      this.deviceMapList.forEach(d => {
        typeSet.add(d.type);
      });

      var ul = document.createElement('ul');
      ul.style.cssText = "list-style-type: none;";
      p.appendChild(ul);

      for(let type of typeSet.keys()) {
        switch (type) {
          case 10:
          this.createHtmlCheckbox("干滩", type, ul);
            break;
          case 20:
          this.createHtmlCheckbox("水位计", type, ul);
            break;
          case 21:
          this.createHtmlCheckbox("超高", type, ul);
            break;
          case 22:
          this.createHtmlCheckbox("明渠流量计", type, ul);
            break;
          case 30:
          this.createHtmlCheckbox("全站仪", type, ul);
            break;
          case 32:
          this.createHtmlCheckbox("全站仪点位", type, ul);
            break;
          case 33:
          this.createHtmlCheckbox("GNSS", type, ul);
            break;
          case 40:
          this.createHtmlCheckbox("内部测斜仪", type, ul);
            break;
          case 50:
          this.createHtmlCheckbox("渗压计", type, ul);
            break;
          case 60:
          this.createHtmlCheckbox("雨量计", type, ul);
            break;
          case 91:
          this.createHtmlCheckbox("浊度仪", type, ul);
            break;
          case 95:
          this.createHtmlCheckbox("摄像头", type, ul);
            break;
          default:
            break;
        }
      }
    },
    createHtmlCheckbox(name, type, ul) {
      var li = document.createElement('li');
      li.style.cssText = "font-size: 14px;";
      var label = document.createElement('label');
      var input = document.createElement('input');
      input.type = "checkbox";
      input.value = type;
      if(this.showDeviceTypes.has(type)) {
        input.checked = true;
      } else {
        input.checked = false;
      }
      li.appendChild(label);
      label.appendChild(input);
      label.appendChild(document.createTextNode(name));
      ul.appendChild(li);

      let that = this;
      input.onclick = function (e) {
        that.deviceCheckBoxChange(e, type);
      }
    },
    deviceCheckBoxChange(e, type) {
      // console.log(e.target.checked);
      if(e.target.checked) {
        this.showDeviceTypes.add(type);
        this.showMarkerByType(type);
      } else {
        this.showDeviceTypes.delete(type);
        this.hideMarkerByType(type);
      }
      // this.showDeviceTypes.delete(type);
    },
    showMarkerByType(type) {
      this.deviceMapList.forEach(e => {
        if (e.type === type) {
          // console.log('显示了,' + e.name);
          e.marker.show();
        }
      });
    },
    hideMarkerByType(type) {
      this.deviceMapList.forEach(e => {
        if (e.type === type) {
          // console.log('隐藏了,' + e.name);
          e.marker.hide();
        }
      });
    },
    hideMarkerLabel() {
      this.deviceMapList.forEach(e => {
        e.marker.getLabel().hide();
      });
    },
    showMarkerLabel() {
      this.deviceMapList.forEach(e => {
        e.marker.getLabel().show();
      });
    },
    getMarkerByCode(code) {
      var m = null;
      this.deviceMapList.forEach(e => {
        if(e.code === code) {
          m = e;
        }
      });
      return m;
    },
    // 销毁所有marker
    clearMarkers() {
      this.deviceMapList.forEach(e => {
        e.destroyMarker();
      });
    },
    // 标记设备预警高亮
    showDeviceAlarm(codes) {
      if(codes == null || codes.length == 0){
        return;
      }
      // console.log(codes);
      this.deviceMapList.forEach(e => {
        // console.log(e.code);
        if(codes.includes(e.code)) {
          // 有预警
          e.showAlarmFun();
        } else {
          // 无预警
          e.hideAlarm();
        }
      });
    },
  },
  beforeDestroy() {
    this.clearMarkers();
  }
};
function MapShowControl (w) {
  this.defaultAnchor = BMAP_ANCHOR_TOP_LEFT;
  this.defaultOffset = new BMap.Size(w, 20)
}
</script>
   
<style scoped>
.map {
      width: 100%;
      height:calc(100%);
    }
.power {
  position: absolute;
  bottom: 0;
  line-height: 20px;
  width: 100%;
  height: 20px;
  z-index: 100;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.normal-image {
  position: absolute;
  bottom: 10px;
  width: 500px;
  height: 150px;
  z-index: 100;
  text-align: center;
  right: 40px;
  border-radius: 20px;
  background-color: white;
  opacity: 0.7;
}
.img-size {
  width: 150px;
  height: 150px;
}
</style>