<template>
    <div>
        <el-tabs type="border-card">
            <el-tab-pane label="实时监测值">
                <v-cexie-mt :data="data"></v-cexie-mt>
            </el-tab-pane>
            <el-tab-pane label="实时示意图">
                <v-cexie-rt :hiddenPath="true"></v-cexie-rt>
            </el-tab-pane>
            <el-tab-pane label="历史数据">
                <v-cexie-ht></v-cexie-ht>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import vCexieMt from '../projectRealTime/CexieMt.vue';
import vCexieRt from '../../datacenter/realtime/CexieRt.vue';
import vCexieHt from '../../datacenter/history/CexieHt.vue';
export default {
    components: {
        vCexieMt,
        vCexieRt,
        vCexieHt
    },
    props: {
        data: Object
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style>

</style>