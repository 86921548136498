<template>
    <div id="rainChart" class="chart"></div>
  </template>
  
  <script>
  import * as echarts from "echarts";
  export default {
    data() {
      return {
        option: {
          title: {
            show: true,
            text: '总雨量：0mm',
            textStyle: {
              color: '#ffffff',
              fontSize: 12,
              fontWeight: 'normal'
            },
            right: 0,
            top: 40,
          },
          xAxis: {
            type: "category",
            name: "时段",
            boundaryGap: false,
            nameTextStyle: {
              color: "#fff", // 坐标轴名称的颜色
              fontSize: "14",
            },
            data: [],
            axisLine: {
              show: true,
              symbol: ["none", "arrow"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
              symbolSize: [8, 12],
              lineStyle: {
                color: "#5DF4FF", // 坐标轴线线的颜色
                width: "1", // 坐标轴线线宽
                type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
              },
            },
            axisTick: {
              show: true, // 是否显示坐标轴刻度
              inside: true, // 坐标轴刻度是否朝内，默认朝外
              length: 3, // 坐标轴刻度的长度
              lineStyle: {
                color: "#5DF4FF", // 刻度线的颜色
                width: 1, // 坐标轴刻度线宽
                type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
              },
            },
            axisLabel: {
              show: true, // 是否显示刻度标签
              // formatter 刻度标签的内容格式器，支持字符串模板和回调函数两种形式
              color: "#fff", // 刻度标签文字的颜色
              fontSize: "14", // 文字字体大小
            },
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            show: true,
            name: "mm",
            nameTextStyle: {
              color: "#fff", // 坐标轴名称的颜色
              fontSize: "14",
            },
            axisLabel: {
              show: true,
              color: "#fff", // 刻度标签文字的颜色
              fontSize: "14", // 文字字体大小
            },
            axisLine: {
              show: true,
              symbol: ["none", "arrow"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
              symbolSize: [8, 12],
              lineStyle: {
                color: "#5DF4FF", // 坐标轴线线的颜色
                width: "1", // 坐标轴线线宽
                type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              inside: true, // 坐标轴刻度是否朝内，默认朝外
              length: 3, // 坐标轴刻度的长度
              lineStyle: {
                color: "#5DF4FF", // 刻度线的颜色
                width: 1, // 坐标轴刻度线宽
                type: "solid", // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
              },
            },
            type: "value",
          },
          series: [
            {
              data: [],
              type: "line",
              lineStyle: {
                width: 3,
                normal: { color: "#52D8FF" },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.62,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#28B8FE",
                  },
                  {
                    offset: 1,
                    color: "rgba(111,252,239,0)",
                  },
                ]),
              },
              smooth: true,
            },
          ],
        },
        chart: null,
        yuliangDatas: [],
        total: 0,
      };
    },
    mounted() {
      this.getYuliangDataList();
    },
    methods: {
      initChart() {
        this.option.title.text = '总雨量：' + this.common.floatFormat(this.total, 2) + 'mm';
        this.option.xAxis.data = this.yuliangDatas.times;
        this.option.series[0].data = this.yuliangDatas.values || [];
        var chartDom = document.getElementById("rainChart");
        if(this.chart == null) {
          this.chart = echarts.init(chartDom);
        }
      //   this.chart.clear();
        this.chart.setOption(this.option);
      },
      async getYuliangDataList () {
        const { data: res } = await this.$http.get("device/yuliang/hours/data/view", {
          showLoading: false
        });
        if (res.code == 0) {
          this.createYuliangData(res.data);
        } else {
          this.$message.error(res.msg);
        }
      },
      createYuliangData(dataList) {
        if(dataList == null || dataList.length == 0) {
          return;
        }
        this.total = 0;
        var values = [];
        var times = [];
        dataList.forEach(d => {
          let v = this.common.floatFormat(d.totalValue, 3);
          this.total += v;
          values.push(v);
          times.push(this.gethourFromData(d.timeText));
        });
        this.yuliangDatas = {
          values: values,
          times: times
        };
  
        this.initChart();
      },
      gethourFromData(time) {
        var t = time.split(" ");
        return this.common.floatFormat(t[1], 0) + '时';
      },
    },
  };
  </script>
  
  <style>
  .chart {
      width: 86%;
      height: 320px;
      margin-left: 60px;
  }
  </style>