<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>增值服务</el-breadcrumb-item>
      <el-breadcrumb-item>服务订阅</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span class="span-title">项目列表</span>
          </div>
          <el-tree
            :data="projectList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadServices"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header">
            <span class="span-title">服务订阅</span>
          </div>
          <div style="height: calc(100vh - 260px);overflow-y: auto;">
            <el-card v-for="item in serviceInfoList" :key="item.id" class="service-info" shadow="hover">
              <el-row :gutter="10">
                <el-col :span="20">
                  <div class="title">{{item.serviceName}}<span style="font-size: 12px; color: #909399" v-if="item.sub">(有效期：{{item.expiryDateStr}})</span></div>
                  <div class="descri">{{item.remark}}</div>
                </el-col>
                <el-col :span="4">
                  <el-button v-if="!item.sub" type="success" icon="el-icon-check" @click="subService(item)">订阅服务</el-button>
                  <el-button v-else type="danger" icon="el-icon-delete" @click="unsubService(item)">取消订阅</el-button>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 订阅服务 -->
    <el-dialog title="订阅服务" :visible.sync="addServiceDialogShowFlag" width="40%" @close="addDialogClosed">
      <el-form :model="addServiceForm" :rules="addRules" ref="addServiceRef">
        <el-form-item label="订阅项目" label-width="120px">
            <el-input
              autocomplete="off"
              v-model="currentSelectProject.projectName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="订阅服务" label-width="120px">
            <el-input
              autocomplete="off"
              v-model="currentSelectService.serviceName"
              disabled
            ></el-input>
          </el-form-item>
        <el-form-item label="订阅期限" label-width="120px" prop="timeRange">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="addServiceForm.timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialogShowFlag = false">取 消</el-button>
        <el-button type="primary" @click="addServiceInfoSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        timeRange: [
          { required: true, message: "请选择服务期限", trigger: "blur" }
        ],
      },
      addServiceDialogShowFlag: false,
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      queryParams: {
        pageNo: 1,
        pageSize: 900,
      },
      currentSelectProject: {
        projectName: ""
      },
      currentSelectService: {
        serviceName: ""
      },
      projectList: [],
      serviceInfoList: [],
      addServiceForm: {
        timeRange: []
      },
    }
  },
  created() {
    this.getProjectList();
  },
  methods: {
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data == null) {
          this.projectList = [];
          return;
        }
        this.projectList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadServices(p) {
      this.currentSelectProject = p;
      let requestMerge = new Array();
      let r1 = this.$http.get("added/services/find/service/byPage", {
        params: this.queryParams,
      });

      let r2 = this.$http.get("added/services/find/project/sub", {
        params: {
          projectCode: p.projectCode
        },
      });
      requestMerge.push(r1);
      requestMerge.push(r2);
      let that = this;
      Promise.all(requestMerge).then(responseList => {
        let rs1 = responseList[0];
        let rs2 = responseList[1];
        if (rs1.data.code == 0) {
          this.findSubService(rs1.data.data.list, rs2.data.data);
        } else {
          that.$message.error(rs1.data.msg);
        }
      });
    },
    findSubService (allServices, subServices) {
      var codeSet = new Set();
      var subMap = new Map();
      if (subServices != null && subServices.length > 0) {
        subServices.forEach(s => {
          codeSet.add(s.serviceCode);
          subMap.set(s.serviceCode, s);
        });
      }

      if (allServices != null && allServices.length > 0) {
        allServices.forEach(s => {
          if (codeSet.has(s.serviceCode)) {
            var subinfo = subMap.get(s.serviceCode);
            // 已订阅
            s.sub = true;
            s.expiryDateStr = subinfo == null ? "" : subinfo.expiryDateStr;
          } else {
            // 未订阅
            s.sub = false;
          }
        });
      }

      this.serviceInfoList = allServices;
    },
    addDialogClosed() {
      this.$refs.addServiceRef.resetFields();
    },
    subService(service) {
      this.currentSelectService = service;
      this.addServiceDialogShowFlag = true;
    },
    async unsubService(service) {
      const confirmResult = await this.$confirm("是否确认取消订阅该服务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消操作");
      }
      const { data: res } = await this.$http.get("added/services/remove/subref/bycode", {
        params: {
          projectCode: this.currentSelectProject.projectCode,
          serviceCode: service.serviceCode
        },
      });
      if (res.code == 0) {
        this.$message.success("取消服务成功！");
        this.loadServices(this.currentSelectProject);
      } else {
        return this.$message.error("取消服务失败！");
      }
    },
    addServiceInfoSubmit() {
      if (this.addServiceForm.timeRange != null && this.addServiceForm.timeRange.length > 0) {
        this.addServiceForm.beginTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.addServiceForm.timeRange[0]);
        this.addServiceForm.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.addServiceForm.timeRange[1]);
      } else {
        this.addServiceForm.beginTime = null;
        this.addServiceForm.endTime = null;
      }

      this.addServiceForm.projectCode = this.currentSelectProject.projectCode;
      this.addServiceForm.serviceCode = this.currentSelectService.serviceCode;

      this.$refs.addServiceRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "added/services/newSub",
            this.addServiceForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.loadServices(this.currentSelectProject);
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addServiceDialogShowFlag = false;
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.service-info {
  margin-top: 10px;
  height: 80px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #409EFF;
    width: 100%;
  }
  .descri {
    font-size: 14px;
    color: #909399;
    width: 100%;
    overflow: hidden;
  }
}
</style>