<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>坝体管理</el-breadcrumb-item>
      <el-breadcrumb-item>坝体列表</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getBarList"
            @change="getBarList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddDialog"
            icon="el-icon-plus"
            >添加坝体</el-button
          >
        </el-col> -->
      </el-row>
      <!-- 表格数据 -->
      <el-table :data="barInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="坝体名称" prop="name"></el-table-column>
        <el-table-column label="排序" prop="sortNum"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <!-- <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>


      <!-- 添加坝体 -->
      <el-dialog
        title="添加坝体"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <el-form :model="barInfoForm" :rules="barLevelRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="barInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坝体名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="barInfoForm.name"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序号" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="barOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="barInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑坝体 -->
      <el-dialog
        title="编辑坝体"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <el-form :model="barInfoForm" :rules="barLevelRules" ref="editInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="barInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="坝体名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="barInfoForm.name"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序号" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="barOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="barInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      barLevelRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      barInfoList: [],
      projectInfoList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      barInfoForm: {},
      barOrder: 1,
      fwjDisabled: false,
    }
  },
  created() {
    this.getAllProject();
  },
  methods: {
    decrease() {
      if (this.barOrder > 1) {
        this.barOrder--;
      }
    },
    increase() {
      this.barOrder++;
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getBarList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getBarList();
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getBarList() {
      const { data: res } = await this.$http.get("barlevel/find/byPage", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.barInfoList = [];
          this.total = 0;
          return;
        }

        this.barInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.barInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.barInfoForm.sortNum = this.barOrder;
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "barlevel/add",
            this.barInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getBarList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("barlevel/find/one/" + id);
      if (res.code == 0) {
        this.barInfoForm = res.data;
        this.barOrder = res.data.sortNum;
        if (res.data.dmType == 2) {
          this.fwjDisabled = false;
        } else {
          this.fwjDisabled = true;
        }
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.barInfoForm.sortNum = this.barOrder;
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "barlevel/edit",
            this.barInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getBarList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>

</style>