import { render, staticRenderFns } from "./DuanmianInfo.vue?vue&type=template&id=2081e9d0&scoped=true&"
import script from "./DuanmianInfo.vue?vue&type=script&lang=js&"
export * from "./DuanmianInfo.vue?vue&type=script&lang=js&"
import style0 from "./DuanmianInfo.vue?vue&type=style&index=0&id=2081e9d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2081e9d0",
  null
  
)

export default component.exports