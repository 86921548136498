<template>
  <section class="box-wrap">
    <div class="box-title">
      <span class="title">{{title}}</span>
      <SvgIcon iconClass="monitoring_module_b_title"/>
    </div>
    <div class="box-content">
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  name:'BoxPane',
  props:{
    title:{
      type:String,
      default:''
    }
  },
  data(){
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.box-wrap {
  position: relative;
  box-sizing: border-box;
  padding:2px;
  background-image: linear-gradient(197deg, rgba(62, 201, 255, 1), rgba(55, 190, 255, 1), rgba(38, 165, 255, 0.16), rgba(29, 152, 255, 1));
  background-image: -webkit-linear-gradient(197deg, rgba(62, 201, 255, 1), rgba(55, 190, 255, 0), rgba(38, 165, 255, 0), rgba(29, 152, 255, 1));
  background-image: -moz-linear-gradient(197deg, rgba(62, 201, 255, 1), rgba(55, 190, 255, 0), rgba(38, 165, 255, 0), rgba(29, 152, 255, 1));
  border-radius: 12px;
  .box-content {
    width: 100%;
    height: 100%;
    min-height: 100px;
    background: rgba(0,47,132,0.8);
    border-radius: 10px;
    padding-top: 37px;
    box-sizing: border-box;
  }
  .box-title {
    position: absolute;
    width: 248px;
    height: 50px;
    top: -23px;
    left: 50%;
    margin-left:-124px;
    svg {
      height: 50px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .title {
      position: absolute;
      text-align: center;
      width: 100%;
      top: 9px;
      left: 0;
      color: #ffffff;
      line-height: 32px;
      font-size: 23px;
      z-index: 2;
    }
  }
}
</style>