<template>
  <vue-seamless-scroll :data="unReadInfoList" class="seamless-warp">
    <ul class="item">
      <li v-for="item in unReadInfoList" class="itemLi">
        <span class="itemContent" v-html="item.msgContent"></span>
      </li>
    </ul>
  </vue-seamless-scroll>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      unReadInfoList: [],
    };
  },
  created () {
    this.getUnReadList();
  },
  methods: {
    async getUnReadList () {
      const { data: res } = await this.$http.get("alarm/find/topn/undeal", {
        showLoading: false
      });
      if (res.code == 0) {
        this.unReadInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.seamless-warp {
  height: 85%;
  width: 90%;
  margin: auto;
  overflow: hidden;
}
.itemContent {
  color: white;
}
.itemLi {
  background-color: rgba(128, 128, 128, 0.418);
  margin-top: 3px;
}
</style>