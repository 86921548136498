<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>历史数据</el-breadcrumb-item>
      <el-breadcrumb-item>干滩数据</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            v-model="queryParams.deviceCode"
            placeholder="请选择设备"
            clearable
            @clear="getDataList"
            @change="getDataList"
          >
            <el-option
              v-for="item in deviceInfoList"
              :key="item.deviceCode"
              :label="item.name"
              :value="item.deviceCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="14">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate"
          >
          </el-date-picker>
          <el-button
            @click="exportDevices"
            style="margin-left: 10px"
            type="success"
            v-has="'data:history:gantan:export'"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="dataInfoList" border :row-class-name="tableRowClassName">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="设备名称" prop="deviceName"></el-table-column>
        <el-table-column label="主测点" prop="isMain" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isMain === 0" type="info">否</el-tag>
            <el-tag v-else-if="scope.row.isMain === 1" type="success"
              >是</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="监测结果（米）"
          prop="currentValue"
          width="110px"
        ></el-table-column>
        <!-- <el-table-column label="坡比1" prop="pobi1"></el-table-column>
        <el-table-column label="坡比2" prop="pobi2"></el-table-column> -->
        <el-table-column label="坡度" prop="mpobi"></el-table-column>
        <el-table-column label="超高（米）" prop="cgValue"></el-table-column>
        <el-table-column
          label="监测时间"
          prop="collectTime"
          width="140px"
        ></el-table-column>
        <el-table-column
          label="库水位设备名"
          prop="swDevName"
          width="140px"
        ></el-table-column>
        <el-table-column
          label="库水位值（米）"
          prop="swDevValue"
          width="110px"
        ></el-table-column>
        <!-- <el-table-column label="滩顶高程设备名" prop="gcDevName" width="140px"></el-table-column>
        <el-table-column label="滩顶高程值（米）" prop="gcDevValue" width="120px"></el-table-column>
        <el-table-column label="从高程1" prop="subGc1Name" width="120px"></el-table-column>
        <el-table-column label="从高程1值（米）" prop="gc1Value" width="110px"></el-table-column>
        <el-table-column label="L1（米）" prop="l1"></el-table-column>
        <el-table-column label="从高程2" prop="subGc2Name" width="120px"></el-table-column>
        <el-table-column label="从高程2值（米）" prop="gc2Value" width="115px"></el-table-column>
        <el-table-column label="L2（米）" prop="l2"></el-table-column> -->
        <el-table-column label="预警">
          <template slot-scope="scope">
            <span v-if="scope.row.alarmLv == 1">黄色预警</span>
            <span v-else-if="scope.row.alarmLv == 0">蓝色预警</span>
            <span v-else-if="scope.row.alarmLv == 2">橙色预警</span>
            <span v-else-if="scope.row.alarmLv == 3">红色预警</span>
            <span v-else></span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%"
      >
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    hiddenPath: Boolean
  },
  data () {
    return {
      showDownloadVisible: false,
      queryParams: {
        projectCode: "",
        deviceCode: "",
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
      },
      total: 0,
      projectInfoList: [],
      deviceInfoList: [],
      dataInfoList: [],
      timeRange: [],
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getDataList();
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDataList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDataList();
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDataList () {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("device/gantan/history/data", this.queryParams);
      if (res.code == 0) {
        if (res.data != null) {
          this.dataInfoList = res.data.list;
          this.total = res.data.total;
        } else {
          this.dataInfoList = [];
          this.total = 0;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/gantan/find/page", {
        params: {
          pageNo: 1,
          pageSize: 900,
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data != null) {
          this.deviceInfoList = res.data.list;
          this.getDataList();
        } else {
          this.deviceInfoList = [];
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async exportDevices () {
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.post("file/gantan/history/data/export", this.queryParams);
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.alarmLv == 1) {
        return 'yellow-row';
      } else if (row.alarmLv == 2) {
        return 'orange-row';
      } else if (row.alarmLv == 3) {
        return 'red-row';
      } else if (row.alarmLv == 0) {
        return 'blue-row';
      } else if (row.alarmLv == 99) {
        // 异常数据
        return 'gray-row';
      }
      return '';
    }
  }
}
</script>

<style>
.el-table .blue-row {
  background: rgb(88, 194, 255);
}
.el-table .yellow-row {
  background: rgb(246, 255, 113);
}

.el-table .orange-row {
  background: #ffd359;
}

.el-table .red-row {
  background: #ff6262;
}

.el-table .gray-row {
  background: #cfcfcf;
}
</style>