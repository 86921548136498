<template>
    <div>
      <v-tab-device-info :data="data"></v-tab-device-info>
    </div>
  </template>
  
  <script>
  import vTabDeviceInfo from "./TabDeviceInfo.vue";
  export default {
    props:['data'],
    components: {
      vTabDeviceInfo
    },
    data() {
      return {
  
      }
    },
    created() {
    },
    methods: {
  
    }
  }
  </script>
  
  <style>
  
  </style>