<template>
  <div>
    <vkong-hao-info :data="data"></vkong-hao-info>
  </div>
</template>

<script>
import vTabDeviceInfo from "./TabDeviceInfo.vue";
import vkongHaoInfo from "./kongHaoInfo.vue";
export default {
  props:['data'],
  components: {
    vTabDeviceInfo,
    vkongHaoInfo
  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style>

</style>