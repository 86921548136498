<template>
    <div>
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
            <el-tab-pane label="实时监测(小时)" name="hour">
                <v-yl-hours :data="projectInfo" ref="ylhourRef"></v-yl-hours>
            </el-tab-pane>
            <el-tab-pane label="雨量环比(日)" name="day">
                <v-yl-day :data="projectInfo" ref="yldayRef"></v-yl-day>
            </el-tab-pane>
            <el-tab-pane label="雨量环比(年月)" name="month">
                <v-yl-month :data="projectInfo" ref="ylmonthRef"></v-yl-month>
            </el-tab-pane>
            <el-tab-pane label="历史数据" name="history">
                <v-yl-history :data="projectInfo"></v-yl-history>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import vYlHours from "./YlHours.vue";
import vYlDay from "./YlDay.vue";
import vYlMonth from "./YlMonth.vue";
import vYlHistory from "./YlHistory.vue";
export default {
    components: {
        vYlHours,
        vYlDay,
        vYlMonth,
        vYlHistory
    },
    props:{
        projectInfo: Object
    },
    data() {
        return {
            activeName: 'hour'
        }
    },
    created() {

    },
    methods: {
        handleClick(tab, event) {
            if (tab.name === 'hour') {
                this.$refs.ylhourRef.getDeviceList();
            } else if (tab.name === 'day') {
                this.$refs.yldayRef.getDeviceList();
            } else if (tab.name === 'month') {
                this.$refs.ylmonthRef.getDeviceList();
            }
        }
    }
}
</script>

<style>

</style>