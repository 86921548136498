<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="10">
        <div class="device-img">
          <img
            class="image-size"
            :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + deviceInfo.deviceImg"
          />
        </div>
      </el-col>
      <el-col :span="14">
        <div class="device-info">
          <el-row>
            <div class="dev-title">
              {{deviceInfo.name}}
            </div>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">设备类型：</div>
                <div class="d-value">浊度仪</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">输入类型：</div>
                <div class="d-value">
                  <span v-if="deviceInfo.inType == 1">-15mV~+15mV</span>
                  <span v-else-if="deviceInfo.inType == 2">-50mV~+50mV</span>
                  <span v-else-if="deviceInfo.inType == 3">-100mV~+100mV</span>
                  <span v-else-if="deviceInfo.inType == 4">-500mV~+500mV</span>
                  <span v-else-if="deviceInfo.inType == 5">-1V~+1V</span>
                  <span v-else-if="deviceInfo.inType == 6">-5V~+5V/-10V~+10V</span>
                  <span v-else-if="deviceInfo.inType == 7">-20mA~+20mA备用</span>
                  <span v-else-if="deviceInfo.inType == 8">4~20mA备用</span>
                  <span v-else-if="deviceInfo.inType == 9">-20mA~+20mA外置电阻</span>
                  <span v-else-if="deviceInfo.inType == 10">4~20mA外置电阻</span>
                  <span v-else-if="deviceInfo.inType == 11">J型</span>
                  <span v-else-if="deviceInfo.inType == 12">K型</span>
                  <span v-else-if="deviceInfo.inType == 13">T型</span>
                  <span v-else-if="deviceInfo.inType == 14">E型</span>
                  <span v-else-if="deviceInfo.inType == 15">R型</span>
                  <span v-else-if="deviceInfo.inType == 16">S型</span>
                  <span v-else-if="deviceInfo.inType == 17">B型</span>
                  <span v-else-if="deviceInfo.inType == 18">钨铼WEB3/25</span>
                  <span v-else-if="deviceInfo.inType == 19">钨铼WEB3/26</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">初始值：</div>
                <div class="d-value">{{deviceInfo.initValue}}</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label"></div>
                <div class="d-value"></div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">监测值：</div>
                <div class="d-value">{{deviceInfo.currentValue}}NTU</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="dev-text">
                <div class="d-label">监测时间：</div>
                <div class="d-value" style="font-size: 14px;">{{deviceInfo.collectTime}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-1">蓝色预警：
                <span v-if="alarmConfig.blueAlarmValue !== null">
                监测值{{
                common.alarmTypeToString(
                alarmConfig.blueAlarmValue,
                alarmConfig.blueComparisonOperator
                )
                }}NTU
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-2">黄色预警：
                <span v-if="alarmConfig.yellowAlarmValue !== null">
                监测值{{
                common.alarmTypeToString(
                alarmConfig.yellowAlarmValue,
                alarmConfig.yellowComparisonOperator
                )
                }}NTU
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-3">橙色预警：
                <span v-if="alarmConfig.orangeAlarmValue !== null">
                监测值{{
                common.alarmTypeToString(
                alarmConfig.orangeAlarmValue,
                alarmConfig.orangeComparisonOperator
                )
                }}NTU
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="alarm-tag">
              <div class="alarm-info-4">红色预警：
                <span v-if="alarmConfig.redAlarmValue !== null">
                监测值{{
                common.alarmTypeToString(
                alarmConfig.redAlarmValue,
                alarmConfig.redComparisonOperator
                )
                }}NTU
                </span>
                <span v-else>未设置</span>
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="detail-button">
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-pie-chart" round @click="showMonitorData">实时监测</el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-bell" round @click="showAlarmData">监测预警<span v-if="alarmNumber > 0" style="color: red;">({{alarmNumber}}条)</span></el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 150px;" icon="el-icon-document-copy" round @click="showHistoryData">历史数据</el-button>
              </el-col>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <!-- 显示实时监测 -->
    <el-dialog
      :title="monitorTitle"
      :visible.sync="showMonitorDataVisible"
      v-if="showMonitorDataVisible"
      width="100%"
      :fullscreen="true"
      append-to-body>
      <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showMonitorDataVisible = false">返回数据大屏</el-button>
      <v-zhuodu-rt :hiddenPath="true" ref="shenyaRtRef"></v-zhuodu-rt>
    </el-dialog>


    <!-- 显示预警信息 -->
    <el-dialog
      :title="alarmTitle"
      :visible.sync="showAlarmDataVisible"
      v-if="showAlarmDataVisible"
      width="60%"
      append-to-body>
      <div style="height: 600px; overflow-y: auto;overflow-x: hidden;">
        <v-device-alarm-info :deviceInfo="deviceInfo"></v-device-alarm-info>
      </div>
    </el-dialog>


    <!-- 显示历史数据 -->
    <el-dialog
      :title="historyTitle"
      :visible.sync="showHistoryDataVisible"
      v-if="showHistoryDataVisible"
      width="100%"
      :fullscreen="true"
      append-to-body>
      <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showHistoryDataVisible = false">返回数据大屏</el-button>
      <v-zhuodu-ht :hiddenPath="true"></v-zhuodu-ht>
    </el-dialog>
  </div>
</template>

<script>
import vDeviceAlarmInfo from '../DeviceAlarmInfo.vue';
import vZhuoduHt from '../../../datacenter/history/ZhuoduHt.vue';
import vZhuoduRt from '../../../datacenter/realtime/ZhuoduRt.vue';
export default {
  components: {
    vDeviceAlarmInfo,
    vZhuoduHt,
    vZhuoduRt
  },
  props:['data'],
  data() {
    return {
      deviceInfo: {},
      alarmConfig: {},
      showMonitorDataVisible: false,
      showAlarmDataVisible: false,
      showHistoryDataVisible: false,
      alarmTitle: '',
      alarmNumber: 0,
      historyTitle: '',
      monitorTitle: ''
    }
  },
  created() {
    this.loadData(this.data);
  },
  watch: {
    data(value) {
      this.loadData(value);
    }
  },
  methods: {
    async loadData(data) {
      this.loadDeviceInfo(data);
      this.loadAlarmConfig(data);
    },
    async loadDeviceInfo(data) {
      const { data: res } = await this.$http.get("device/zhuodu/find/bycode/" + data.code);
      if (res.code == 0) {
        this.deviceInfo = res.data;
        this.getAlarmNumber();
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadAlarmConfig(data) {
      const { data: res } = await this.$http.get("alarm/find/config", {
        params: {
          deviceCode: data.code
        }
      });
      if (res.code == 0 && res.data != null) {
        this.alarmConfig = res.data;
      }
    },
    // 获取尚未处理的预警数量
    async getAlarmNumber() {
      const { data: res } = await this.$http.post("alarm/find/records", {
        projectCode: this.deviceInfo.projectCode,
        deviceCode: this.deviceInfo.deviceCode,
        alarmStatus: 0,
        pageNo: 1,
        pageSize: 10
      });
      if (res.code == 0) {
        this.alarmNumber = res.data.total;
      }
    },
    showMonitorData() {
      this.monitorTitle = '[' + this.deviceInfo.name + ']实时监测';
      this.showMonitorDataVisible = true;
      setTimeout(() => {
        this.$refs.shenyaRtRef.defaultLoadData(this.deviceInfo);
      }, 0);
    },
    showAlarmData() {
      this.alarmTitle = '[' + this.deviceInfo.name + ']预警信息';
      this.showAlarmDataVisible = true;
    },
    showHistoryData() {
      this.historyTitle = '[' + this.deviceInfo.name + ']历史数据查询';
      this.showHistoryDataVisible = true;
    }
  }
}
</script>

<style scoped>
.device-img {
  width: 100%;  
  height: 440px;
}

.image-size {
  width: 100%;
  height: 440px;
  object-fit: fill;
}

.device-info {
  width: 100%;
  height: 440px;
}

.dev-title {
  width: 100%;
  height: 80px;
  font-size: 36px;
  font-weight: bold;
  line-height: 80px;
  color: #ffdd5f;
  margin-left: 15px;
}

.dev-text {
  width: 100%;
  height: 40px;
  color: white;
}
.d-label {
  float: left;
  width: 35%;
  height: 40px;
  text-align: right;
  font-size: 16px;
  line-height: 40px;
}
.d-value {
  float: left;
  width: 65%;
  height: 40px;
  text-align: left;
  font-size: 18px;
  line-height: 40px;
}
.alarm-tag {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 14px;
}
.alarm-info-1 {
  width: 100%;
  height: 50px;
  background-color: #00c2ff;
  border-color: #00c2ff;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-2 {
  width: 100%;
  height: 50px;
  background-color: #ffdd5f;
  border-color: #ffdd5f;
  color: gray;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-3 {
  width: 100%;
  height: 50px;
  background-color: #ff6541;
  border-color: #ff6541;
  opacity: 0.8;
  text-indent: 10px;
}
.alarm-info-4 {
  width: 100%;
  height: 50px;
  background-color: #ff3c5d;
  border-color: #ff3c5d;
  opacity: 0.8;
  text-indent: 10px;
}
.detail-button {
  width: 100%;
  height: 40px;
  margin-top: 10px;
}
</style>