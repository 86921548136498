<template>
    <div>
        <el-row :gutter="4">
          <el-col :span="3">
            <el-select
              v-model="queryParams.deviceType"
              placeholder="请选择类型"
              clearable
              @clear="getAlarmList"
              @change="getAlarmList"
            >
              <el-option
                v-has="'sys:mtr:gantan'"
                label="干滩"
                :value="10"
              ></el-option>
              <el-option
                v-has="'sys:mtr:chaogao'"
                label="超高"
                :value="21"
              ></el-option>
              <el-option
                v-has="'sys:mtr:wuwei'"
                label="库水位"
                :value="20"
              ></el-option>
              <el-option
                v-has="'sys:mtr:quanzhan'"
                label="全站仪"
                :value="30"
              ></el-option>
              <el-option
                v-has="'sys:mtr:gnss'"
                label="GNSS"
                :value="33"
              ></el-option>
              <el-option
                v-has="'sys:mtr:cexie'"
                label="内部测斜"
                :value="40"
              ></el-option>
              <el-option
                v-has="'sys:mtr:shenya'"
                label="浸润线"
                :value="50"
              ></el-option>
              <el-option
                v-has="'sys:mtr:yuliang'"
                label="降雨量"
                :value="60"
              ></el-option>
              <el-option
                v-has="'sys:mtr:zhuodu'"
                label="混浊度"
                :value="91"
              ></el-option>
              <el-option
                v-has="'sys:mtr:mingqu'"
                label="明渠流量"
                :value="22"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              v-model="queryParams.alarmLv"
              placeholder="请选择等级"
              clearable
              @clear="getAlarmList"
              @change="getAlarmList"
            >
              <el-option label="蓝色预警" :value="0"></el-option>
              <el-option label="黄色预警" :value="1"></el-option>
              <el-option label="橙色预警" :value="2"></el-option>
              <el-option label="红色预警" :value="3"></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              v-model="queryParams.alarmStatus"
              placeholder="请选择状态"
              clearable
              @clear="getAlarmList"
              @change="getAlarmList"
            >
              <el-option label="未处理" :value="0"></el-option>
              <el-option label="已处理" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="15">
            <el-date-picker
              format="yyyy-MM-dd HH:mm:ss"
              v-model="timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="triggerDate"
            >
            </el-date-picker>
            <el-button
              @click="exportDevices"
              style="margin-left: 10px"
              type="success"
              v-has="'sys:alarm:export'"
              icon="el-icon-download"
              >导出</el-button
            >
          </el-col>
        </el-row>
  
        <!-- 表格数据 -->
        <el-table :data="alarmInfoList" border stripe max-height="500px">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="设备类型" prop="deviceType" width="90px">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 10">干滩</span>
              <span v-else-if="scope.row.deviceType == 21">超高</span>
              <span v-else-if="scope.row.deviceType == 20">库水位</span>
              <span v-else-if="scope.row.deviceType == 30">全站仪位移</span>
              <span v-else-if="scope.row.deviceType == 33">GNSS位移</span>
              <span v-else-if="scope.row.deviceType == 40">内部测斜</span>
              <span v-else-if="scope.row.deviceType == 50">浸润线</span>
              <span v-else-if="scope.row.deviceType == 60">降雨量</span>
              <span v-else-if="scope.row.deviceType == 91">混浊度</span>
              <span v-else-if="scope.row.deviceType == 22">明渠流量</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column label="设备名称" prop="deviceName"></el-table-column>
          <el-table-column label="预警等级" prop="alarmLv" width="90px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.alarmLv == 0">蓝色预警</el-tag>
              <el-tag v-else-if="scope.row.alarmLv == 1" class="yellowClass"
                >黄色预警</el-tag
              >
              <el-tag v-else-if="scope.row.alarmLv == 2" type="warning"
                >橙色预警</el-tag
              >
              <el-tag v-else type="danger">红色预警</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="实际值/参考值" prop="alarmValue">
            <template slot-scope="scope">
              {{ scope.row.realValue }}
              <span v-if="scope.row.comparisonOperator == 1">=</span>
              <span v-else-if="scope.row.comparisonOperator == 2">></span>
              <span v-else-if="scope.row.comparisonOperator == 3">&lt;</span>
              <span v-else-if="scope.row.comparisonOperator == 4">≥</span>
              <span v-else-if="scope.row.comparisonOperator == 5">≤</span>
              <span v-else>&lt;></span>
              {{ scope.row.alarmValue }}
            </template>
          </el-table-column>
          <el-table-column
            label="触发时间"
            prop="alarmTime"
            width="150px"
          ></el-table-column>
          <el-table-column label="处理状态" prop="alarmStatus" width="80px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.alarmStatus == 1">已处理</el-tag>
              <el-tag v-else type="danger">未处理</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="处理意见" prop="opReason"></el-table-column>
          <el-table-column
            label="处理时间"
            prop="opTime"
            width="150px"
          ></el-table-column>
          <el-table-column label="操作" width="140px">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="处理预警"
                placement="top"
                :enterable="false"
                v-if="scope.row.alarmStatus == 0"
              >
                <el-button
                  type="success"
                  icon="el-icon-setting"
                  @click="dealAlarm(scope.row)"
                  v-has="'sys:alarm:deal'"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除预警"
                placement="top"
                :enterable="false"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="deleteAlarm(scope.row)"
                  v-has="'sys:alarm:deal'"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
  
        <!-- 添加处理意见 -->
        <el-dialog
          title="预警处理意见"
          :visible.sync="addDialogVisible"
          width="50%"
          @close="addDialogClosed"
          append-to-body
        >
          <div>
            <el-form :model="alarmInfoForm" :rules="formRules" ref="addInfoRef">
              <el-form-item label="处理意见" label-width="120px" prop="opReason">
                <el-input
                  type="textarea"
                  v-model="alarmInfoForm.opReason"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
          </span>
        </el-dialog>
  
        <el-dialog
          title="导出提示"
          :visible.sync="showDownloadVisible"
          width="30%"
          append-to-body
        >
          <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showDownloadVisible = false">取 消</el-button>
            <el-button type="primary" @click="showDownloadVisible = false"
              >确 定</el-button
            >
          </span>
        </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props:{
        projectInfo: Object,
        searchType: String
    },
    data () {
      return {
        formRules: {
          opReason: [{ required: true, message: "请输入处理意见", trigger: "blur" }],
        },
        queryParams: {
          projectCode: '',
          deviceType: null,
          alarmLv: null,
          alarmStatus: null,
          pageNo: 1,
          pageSize: 10,
          startTime: null,
          endTime: null,
        },
        total: 0,
        projectInfoList: [],
        alarmInfoList: [],
        alarmInfoForm: {
          opReason: '',
        },
        showDownloadVisible: false,
        addDialogVisible: false,
        currentAlarmId: null,
        currentAlarmCode: null,
        timeRange: [],
      }
    },
    created () {
    },
    mounted(){
        this.queryParams.projectCode = this.projectInfo.projectCode;
        if (this.searchType === 0) {
            this.getAlarmList();
        } else {
            this.defaultShowAlarm(this.searchType);
        }
    },
    methods: {
      triggerDate (date) {
        if (!this.common.triggerDate(date)) {
          this.$message.error('查询时间不能超过30天！');
        } else {
          this.getAlarmList();
        }
      },
      async getAlarmList () {
        if (this.timeRange != null && this.timeRange.length > 0) {
          this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
          this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
        } else {
          this.queryParams.startTime = null;
          this.queryParams.endTime = null;
        }
  
        const { data: res } = await this.$http.post("alarm/find/records", this.queryParams);
        if (res.code == 0) {
          this.alarmInfoList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      handleSizeChange (newSize) {
        this.queryParams.pageSize = newSize;
        this.getAlarmList();
      },
      handleCurrentChange (newPage) {
        this.queryParams.pageNo = newPage;
        this.getAlarmList();
      },
      dealAlarm (row) {
        this.addDialogVisible = true;
        this.currentAlarmId = row.id;
        this.currentAlarmCode = row.alarmCode;
      },
      async addInfoSubmit () {
        this.alarmInfoForm.id = this.currentAlarmId;
        this.alarmInfoForm.alarmCode = this.currentAlarmCode;
        this.$refs.addInfoRef.validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http.post(
              "alarm/deal/alarm",
              this.alarmInfoForm
            );
            if (res.code == 0) {
              this.$message.success("处理成功");
              this.getAlarmList();
            } else {
              this.$message.error(res.msg);
              return false;
            }
            this.addDialogVisible = false;
          } else {
            return false;
          }
        });
      },
      addDialogClosed () {
        this.$refs.addInfoRef.resetFields();
      },
      async exportDevices () {
        if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
          this.$message.error("请选择项目.");
          return;
        }
        const { data: res } = await this.$http.post("file/alarm/export", this.queryParams);
        if (res.code == 0) {
          this.showDownloadVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      },
      // 从route跳转过来默认检索
      defaultShowAlarm (type) {
        // console.log('有默认检索' + type);
        switch (type) {
          case "1":
            // 未处理预警
            {
              this.queryParams.alarmStatus = 0;
              this.getAlarmList();
            }
            break;
          case "2":
            // 未处理黄色预警
            {
              this.queryParams.alarmStatus = 0;
              this.queryParams.alarmLv = 1;
              if (this.$route.query.deviceType != null) {
                this.queryParams.deviceType = parseInt(this.$route.query.deviceType);
              }
              this.getAlarmList();
            }
            break;
          case "3":
            // 未处理橙色预警
            {
              this.queryParams.alarmStatus = 0;
              this.queryParams.alarmLv = 2;
              if (this.$route.query.deviceType != null) {
                this.queryParams.deviceType = parseInt(this.$route.query.deviceType);
              }
              this.getAlarmList();
            }
            break;
          case "4":
            // 未处理红色预警
            {
              this.queryParams.alarmStatus = 0;
              this.queryParams.alarmLv = 3;
              if (this.$route.query.deviceType != null) {
                this.queryParams.deviceType = parseInt(this.$route.query.deviceType);
              }
              this.getAlarmList();
            }
            break;
          case "5":
            // 今日未处理预警
            {
              this.queryParams.alarmStatus = 0;
              this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', new Date()))));
              this.timeRange.push(new Date(Date.parse(this.common.tomorrowDateStr('YYYY-MM-dd HH:mm:ss', new Date()))));
              this.getAlarmList();
            }
            break;
          case "6":
            // 未处理蓝色预警
            {
              this.queryParams.alarmStatus = 0;
              this.queryParams.alarmLv = 0;
              if (this.$route.query.deviceType != null) {
                this.queryParams.deviceType = parseInt(this.$route.query.deviceType);
              }
              this.getAlarmList();
            }
            break;
          default:
            break;
        }
      },
      async deleteAlarm (row) {
        const confirmResult = await this.$confirm("是否确认删除该记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        // 点击确定 返回值为：confirm
        // 点击取消 返回值为： cancel
        if (confirmResult !== "confirm") {
          return this.$message.info("已取消删除");
        }
        const { data: res } = await this.$http.delete("alarm/remove/" + row.id);
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.getAlarmList();
        } else {
          return this.$message.error(res.msg);
        }
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
  .yellowClass {
    background-color: rgb(255, 254, 191);
    border-color: rgb(230, 215, 18);
    color: rgb(177, 165, 0);
  }
  </style>