<template>
  <div class="module-item device">
    <SvgIcon iconClass="device_tatistics"/>
    <div class="device-title">
      <span>降雨量及干滩</span>
      <SvgIcon iconClass="monitoring_module_b_title"/>
    </div>
    <el-carousel class="yl-main-content" direction="vertical" :autoplay="true" :interval="30000">
      <el-carousel-item key="yl">
        <v-yuliang-chart @click.native="showYlDetail" ref="ylChartRef"></v-yuliang-chart>
      </el-carousel-item>
      <el-carousel-item key="gt">
        <v-gantan-chart ref="gtChartRef"></v-gantan-chart>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import vYuliangChart from './YuliangChart.vue'
import vGantanChart from './GantanChart.vue'
export default {
  components: {
    vYuliangChart,
    vGantanChart
  },
  data() {
    return {};
  },
  methods: {
    showYlDetail() {
      this.$emit("showYlDetail", {});
    },
    reloadData() {
      this.$refs.ylChartRef.getYuliangDataList();
      this.$refs.gtChartRef.loadData();
    }
  }
};
</script>
<style lang="less" scoped>
.yl-main-content {
  float: right;
  width: 100%;
  height: 300px;
}
.module-item {
  height: 300px;
  width: 558px;
  position: relative;
  svg {
    width: 100%;
    height: 300px;
    position: absolute;
    right: 0;
    top:0;
    z-index: -1;
  }
  .device-title {
    position: absolute;
    top: -21px;
    right: 130px;
    width: 248px;
    height: 50px;
    text-align: center;
    svg {
      position: absolute;
      height: 50px;
    }
    span {
      font-size: 23px;
      line-height: 50px;
      color: #ffffff;
      position: relative;
      padding-left: 30px;
      &::before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        background: url(~@/assets/images/icon_rain.png) center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -3px;
        top: -1px;
      }
    }
  }
}
</style>