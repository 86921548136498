<template>
  <div id="dashChart" ref="dashChart" style="width: 210px; height: 210px"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name:"DashBord",
  props:{
    axisLineColor:{
      type:Array,
      default() {
        return   [[0.6, "#FF3C5D"],[0.9, "#FFDD5F"], [1, "#27FFBC"]]
      }
    },
    value:{
      type:Number,
      default() {
        return 0
      }
    },
    name:String
  },
  data() {
    return {
      option: {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              lineStyle: {
                width: 20,
                color: [],
              },
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              offsetCenter: [0, 0],
              width:6,
              length:'45%',
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              distance: -30,
              length: 2,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
                width: 1,
              },
            },
            splitLine: {
              distance: -20,
              length: 30,
              lineStyle: {
                color: "#fff",
                width: 0,
              },
            },
            axisLabel: {
              color: "auto",
              distance: 40,
              fontSize: 0,
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 7,
              itemStyle: {
                borderWidth: 7,
                borderColor:''
              },
              offsetCenter: [0, 0],
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}分",
              color: "auto",
              fontSize: 26,
              offsetCenter:[0,'35%']
            },
            title:{
              show:true,
              offsetCenter:[0,'75%'],
              color:'#fff',
              fontSize:16
            },
            data: [
              {
                value: 50,
                name:'',
              },
            ],
          },
        ],
      },
      chart: null,
    };
  },
  watch: {
    value(value) {
      this.initChart()
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initData(){
      this.option.series[0].data[0].value = this.value || 0
      this.option.series[0].data[0].name = this.name || ''
      this.option.series[0].axisLine.lineStyle.color = this.axisLineColor
      this.option.series[0].anchor.itemStyle.borderColor = this.setAnchorColor(this.value)
    },
    initChart() {
      this.initData()
      const chartDom = this.$refs.dashChart;
      if (this.chart == null) {
        this.chart = echarts.init(chartDom);
      }
      // this.chart.clear();
      this.chart.setOption(this.option);
    },
    /**设置指针底部圆圈颜色 */
    setAnchorColor(value){
      let color = ''
      const range = [...this.axisLineColor]
      for(let i =0;i<range.length;i++) {
        if(range[i][0]*100>=value){
          color = range[i][1]
          break
        }
      }
      return color
    }
  },
};
</script>