<template>
  <div>
    <el-row>
      <el-col :span="12">
        <router-link
          :to="{ path: '/alarm/list', query: { index: 1 } }"
          target="_blank"
        >
          <div class="total-num">{{ alarmStatistics.undealTotal }}</div>
        </router-link>
        <div class="total-title">待处理预警</div>
      </el-col>
      <el-col :span="12">
        <router-link
          :to="{ path: '/alarm/list', query: { index: 5 } }"
          target="_blank"
        >
          <div class="total-num">{{ alarmStatistics.tdUndealTotal }}</div>
        </router-link>
        <div class="total-title">今日新增</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <router-link :to="{ path: '/alarm/list', query: { index: 6 } }" target="_blank">
          <div class="detail-num" style="color: aqua">
            {{ alarmStatistics.blueTotal }}
          </div>
        </router-link>
        <div class="detail-title" style="color: aqua">蓝色预警</div>
      </el-col>
      <el-col :span="6">
        <router-link :to="{ path: '/alarm/list', query: { index: 2 } }" target="_blank">
          <div class="detail-num" style="color: rgb(255, 238, 0)">
            {{ alarmStatistics.yellowTotal }}
          </div>
        </router-link>
        <div class="detail-title" style="color: rgb(255, 238, 0)">黄色预警</div>
      </el-col>
      <el-col :span="6">
        <router-link :to="{ path: '/alarm/list', query: { index: 3 } }" target="_blank">
          <div class="detail-num" style="color: #ff8a1c">
            {{ alarmStatistics.orangeTotal }}
          </div>
        </router-link>
        <div class="detail-title" style="color: #ff8a1c">橙色预警</div>
      </el-col>
      <el-col :span="6">
        <router-link :to="{ path: '/alarm/list', query: { index: 4 } }" target="_blank">
          <div class="detail-num">{{ alarmStatistics.redTotal }}</div>
        </router-link>
        <div class="detail-title" style="color: red;">红色预警</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      alarmStatistics: {
        undealTotal: 0,
        tdUndealTotal: 0,
        blueTotal: 0,
        yellowTotal: 0,
        orangeTotal: 0,
        redTotal: 0,
      },
    };
  },
  created() {
    this.getAlarmStatis();
  },
  methods: {
    async getAlarmStatis() {
      const { data: res } = await this.$http.get("alarm/alarmRecord/statis", {
        showLoading: false
      });
      if (res.code == 0) {
        this.alarmStatistics = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.total-num {
  font-size: 40px;
  font-weight: bold;
  color: red;
  text-align: center;
  width: 100%;
  height: 60px;
  margin-top: 10px;
}
.total-title {
  font-size: 20px;
  text-align: center;
  color: aqua;
  width: 100%;
  height: 30px;
}
.detail-title {
  font-size: 16px;
  text-align: center;
  color: #909399;
  width: 100%;
  height: 20px;
}
.detail-num {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-align: center;
  width: 100%;
  height: 30px;
}
</style>