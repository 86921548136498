import { render, staticRenderFns } from "./WuweiRt.vue?vue&type=template&id=5717af70&scoped=true&"
import script from "./WuweiRt.vue?vue&type=script&lang=js&"
export * from "./WuweiRt.vue?vue&type=script&lang=js&"
import style0 from "./WuweiRt.vue?vue&type=style&index=0&id=5717af70&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5717af70",
  null
  
)

export default component.exports