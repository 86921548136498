var KMarker = {
  createMarker (options) {
    return new marker(options);
  },
}

// 地图marker类
class marker {
  id = -1;  // 创建时随机产生的id
  type = -1;  // 设备类型
  name = null; // 设备名称
  code = null; // 设备编号
  lng = 0.0;   // 
  lat = 0.0;   // 
  marker = null;  // 设备对应的地图上的marker对象
  timer = null; // 预警时用于显示图标的闪烁
  showAlarm = false;
  icon = null;
  alarmIcon = null;
  groupShow = 0;  // 是否以组合方式展示，0为否，1为是，默认0
  constructor(options = {}) {
    this.id = Math.floor((Math.random() * 100000) + 1);
    this.type = options.type;
    this.code = options.code;
    this.name = options.name;
    this.lng = options.lng;
    this.lat = options.lat;
    this.marker = options.marker;
    this.icon = options.icon;
    this.alarmIcon = options.alarmIcon;
    this.groupShow = options.groupShow;
  }
  destroyMarker () {
    this.hideAlarm();
  }
  removeMarkerFromMap (map) {
    if (this.marker !== null) {
      map.removeOverlay(this.marker);
    }
  }
  showAlarmFun () {
    let that = this;
    if (this.timer !== null) {
      console.log('已经有timer');
      return;
    }
    that.marker.show();
    this.timer = setInterval(() => {
      that.showAlarm = !that.showAlarm;
      if (that.showAlarm) {
        var icon = new BMap.Icon(that.icon, new BMap.Size(32, 32));
        that.marker.setIcon(icon);
        // that.marker.hide();
      } else {
        var icon = new BMap.Icon(that.alarmIcon, new BMap.Size(32, 32));
        that.marker.setIcon(icon);
        this.marker.show();
        // that.marker.show();
      }
    }, 1000);
  }
  hideAlarm () {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    // 显示回正常状态
    this.normalStatus();
  }
  normalStatus () {
    // this.marker.show();
    var icon = new BMap.Icon(this.icon, new BMap.Size(32, 32));
    this.marker.setIcon(icon);
  }
  toJsonObj () {
    return {
      id: this.id,
      type: this.type,
      code: this.code,
      name: this.name,
      lng: this.marker.getPosition().lng,
      lat: this.marker.getPosition().lat,
      groupShow: this.groupShow
    };
  }
}

export default KMarker