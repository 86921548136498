<template>
    <div>
        <el-tabs type="border-card">
            <el-tab-pane label="实时监测值">
                <v-quanzhan-mt :data="data"></v-quanzhan-mt>
            </el-tab-pane>
            <el-tab-pane label="实时示意图">
                <v-quanzhan-rt :hiddenPath="true"></v-quanzhan-rt>
            </el-tab-pane>
            <el-tab-pane label="历史数据">
                <v-quanzhan-ht></v-quanzhan-ht>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import vQuanzhanMt from '../projectRealTime/QuanzhanMt.vue';
import vQuanzhanRt from '../../datacenter/realtime/QuanzhanRt.vue';
import vQuanzhanHt from '../../datacenter/history/QuanzhanHt.vue';
export default {
    components: {
        vQuanzhanMt,
        vQuanzhanRt,
        vQuanzhanHt
    },
    props: {
        data: Object
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style>

</style>