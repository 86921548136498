<template>
  <el-dialog
  class="tab-popup"
  :visible.sync="dialogVisible"
  :show-close="true"
  :before-close="handleClose"
  :modal="false"
  :width="width">
      <BoxPane :title="title">
        <div class="content">
          <slot></slot>
        </div>
      </BoxPane>
</el-dialog>
</template>
<script>
import BoxPane from './boxPane.vue'
export default {
  name:"TabPopup",
  props:{
    title:String,
    visible:Boolean,
    width:{
      type:String,
      default(){
        return '50%'
      }
    }
  },
  components:{
    BoxPane
  },
  data(){
    return {
      dialogVisible:this.visible
    }
  },
  methods:{
    handleClose(){
      this.$emit('close')
    }
  },
  watch:{
    visible(val){
      this.dialogVisible = val
    }
  }
}
</script>
<style lang="less">
.tab-popup {
  z-index: 6000;
  .el-dialog {
    background-color: transparent;
    box-shadow: none;
  }
  .content {
    background-color: transparent;
    padding: 0 15px 20px;
    overflow-y: auto;
  }
  .el-dialog__close {
    position: absolute;
    font-size: 30px;
    top: 45px;
    right: 5px;
    z-index: 999999;
  }
}
</style>