<template>
  <div ref="cexieChartView" style="height: 100%; width: 100%"></div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      cexieChart: null,
    };
  },
  methods: {
    async loadData (deviceList, khcode, projectCode) {
      let requestMerge = new Array();
      let r1 = this.$http.get("kh/find/bycode/" + khcode);
      let r2 = this.$http.get("device/cexie/find/devices/bykh", {
        params: {
          projectCode: projectCode,
          konghao: khcode,
        }
      });
      requestMerge.push(r1);
      requestMerge.push(r2);
      let that = this;
      Promise.all(requestMerge).then(responseList => {
        let rs1 = responseList[0];
        let rs2 = responseList[1];
        if (rs1.data.code == 0) {
          this.draw(deviceList, rs1.data.data, rs2.data.data);
        } else {
          that.$message.error(rs1.data.msg);
        }
      });
    },
    drawDiagram(deviceList, shuiweiData) {
      if (deviceList == null || deviceList.length == 0) {
        return;
      }

      var khcode = deviceList[0].konghao;
      var projectCode = deviceList[0].projectCode;
      this.loadData(deviceList, khcode, projectCode);
    },
    draw(deviceList, konghao, deviceWithAlarm) {
      let that = this;
      if (this.cexieChart == null) {
        this.cexieChart = this.$echarts.init(this.$refs.cexieChartView);
      }

      // console.log(deviceList);
      
      var deviceData = [];
      var monitorValue = [];

      for (let index = deviceList.length - 1; index >= 0; index--) {
        var dev = deviceList[index];
        var jianju = dev.sortNum * this.common.floatFormat(konghao.jianju, 2);
        deviceData.push(jianju);
        monitorValue.push({
          groupId: dev.deviceCode,
          name: dev.name,
          value: this.common.floatFormat(dev.currentValue, 2)
        });
      }

      var option;
      option = {
        title: {
          text: '内部位移实时监测-' + konghao.name,
          textAlign: 'left'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            // console.log(params);
            return that.getAlarmConfigInfo(params[0].data, deviceWithAlarm);
          }
        },
        grid: {
          left: '3%',
          right: '15%',
          bottom: '3%',
          top: '30%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'value',
          name: '变化量（毫米）',
          splitLine: {
            show: false
          },
          axisLine: {
            show: true,
            onZero: true,
            symbol: 'arrow',
            symbolOffset: [-500, 0]
          }
        },
        yAxis: {
          type: 'category',
          axisLine: {
            onZero: true,
            symbol: 'arrow',
            symbolOffset: [-500, 0]
          },
          axisLabel: {
            formatter: '{value} 米'
          },
          splitLine: {
            show: true,
            interval: 5
          },
          boundaryGap: true,
          data: deviceData
        },
        graphic: [
          {
            type: 'group',
            left: '5%',
            top: 40,
            z: 100,
            children: [
              {
                type: 'line',
                rotation: 0,
                z: 100,
                shape: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 380,
                  percent: 0.9
                },
                style: {
                  lineWidth: 1,
                }
              },
              {
                type: 'polygon',
                left: -16,
                top: 0,
                z: 201,
                shape: {
                  points: [[150, 60], [30, 60], [45, 90], [60, 61], [150, 61]]
                },
                style: {
                  fill: 'rgba(255,255,255,1)',
                  stroke: 'rgba(0,0,0,1)',
                  lineWidth: 1
                }
              },
              {
                type: 'text',
                top: 10,
                left: 10,
                z: 200,
                style: {
                  fill: '#000',
                  text: '孔顶高程：' + konghao.kongding + '米\n孔深：' + konghao.kongshen + '米'
                }
              }
            ]
          }
        ],
        series: [
          {
            name: '内部位移变化量',
            type: 'line',
            smooth: true,
            label: {
              show: true,
              formatter: function (p) {
                // console.log(p);
                return p.data.name + '\n' + p.data.value + ' mm';
              }
            },
            data: monitorValue
          }
        ]
      };

      this.cexieChart.clear();
      this.cexieChart.setOption(option);
    },
    getAlarmConfigInfo(data, devList) {
      // console.log(data);
      var d = null;
      devList.forEach(dev => {
        if (dev.deviceCode === data.groupId) {
          d = dev;
        }
      });

      if (d == null) {
        return '';
      }

      var info = '变化量：' + data.value + '毫米<br/>' +
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(25, 165, 255);"></span><span style="color:rgb(25, 165, 255);">蓝色预警：' + this.haveAlarmConfig(d.alarms, 'blue') + '</span><br/>' +
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 184, 36);"></span><span style="color:rgb(255, 184, 36);">黄色预警：' + this.haveAlarmConfig(d.alarms, 'yellow') + '</span><br/>' +
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 112, 65);"></span><span style="color:rgb(255, 112, 65);">橙色预警：' + this.haveAlarmConfig(d.alarms, 'orange') + '</span><br/>' +
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:rgb(255, 77, 107);"></span><span style="color:rgb(255, 77, 107);">红色预警：' + this.haveAlarmConfig(d.alarms, 'red') + '</span>';
      return info;
    },
    haveAlarmConfig(alarms, type) {
      var result = '未设置';
      if (type === 'blue') {
        if (alarms != null && alarms.blueAlarmValue != null) {
          result = alarms.blueAlarmValue + '毫米';
        }
      } else if (type === 'yellow') {
        if (alarms != null && alarms.yellowAlarmValue != null) {
          result = alarms.yellowAlarmValue + '毫米';
        }
      } else if (type === 'orange') {
        if (alarms != null && alarms.orangeAlarmValue != null) {
          result = alarms.orangeAlarmValue + '毫米';
        }
      } else if (type === 'red') {
        if (alarms != null && alarms.redAlarmValue != null) {
          result = alarms.redAlarmValue + '毫米';
        }
      }

      return result;
    },
  },
};
</script>