<template>
  <div class="pie-wrap">
    <div ref="pieChart" style="width: 130px; height: 100px"></div>
    <div class="total" :style="{color:textColor}" v-if="total">总数：<span :style="{color:color[0]}">{{total}}</span> &nbsp;异常：<span class="error-num">{{error}}</span></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "PieChart",
  props: {
    color: {
      type: Array,
      default() {
        return ["#27FFBC ", "#FF3C5D"];
      },
    },
    title: String,
    titleColor: String,
    textColor: String,
    pieData: [],
  },
  data() {
    return {
      total: 0,
      error: 0,
      option: {
        title: {
          text: "",
          top: "47%",
          left: "center",
          textStyle: {
            fontSize: 12,
            color: this.titleColor,
          },
        },
        color: [],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            center:['50%','55%'],
            label: {
              show: false,
            },
            data: [],
          },
        ],
      },
      chart: null,
    };
  },
  watch: {
    pieData(value) {
      this.initChart()
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initDaTa() {
      this.total = 0;
      this.error = 0;
      this.option.title.text = this.title;
      this.option.color = this.color;
      this.option.series[0]["data"] = this.pieData || [];
      if(this.pieData && this.pieData.length){
        this.pieData.forEach(item => {
          this.total+=item.value
          if(item.name==='异常'){
            this.error+=item.value
          }
        });
      }
    },
    initChart() {
      let that = this;
      this.initDaTa();
      const chartDom = this.$refs.pieChart;
      if (this.chart == null) {
        this.chart = echarts.init(chartDom);
        this.chart.on('click', function (params) {
          // console.log(params);
          that.showDetail(params.data.devType);
        });
      }
      // this.chart.clear();
      this.chart.setOption(this.option);
    },
    showDetail(devType) {
      this.$emit("showPieDetail", devType);
    }
  },
};
</script>
<style lang="less" scoped>
.pie-wrap {
  .total {
    font-size: 10px;
    margin-top: -5px;
    // color:#fff;
  }
}
</style>