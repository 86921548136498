<template>
    <div>
      <el-row>
        <el-col :span="24">
        <el-card>
            <el-divider content-position="left">
                <span style="color: rgb(158 158 158)">降雨量分析（分析时间为T+1，统计24小时前的数据）</span>
            </el-divider>
            <div style="height: 430px; overflow: hidden;">
                <div id="ylChartsDay" style="width: 100%; height: 430px;"></div>
            </div>
        </el-card>
        </el-col>
      </el-row>
    </div>
</template>
  
<script>
  export default {
    props: ["data"],
    data () {
      return {
        queryParams: {
          projectCode: "",
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        projectInfoList: [],
        deviceData: [],
        currentSelectNode: {},
        statisDate: 3,
        statisDatas: [],
        currentProjectInfo: {}
      }
    },
    created () {
    },
    mounted() {
        this.currentProjectInfo = this.data;
        this.queryParams.projectCode = this.data.projectCode;
        // this.getDeviceList();
    },
    methods: {
      async getDeviceList () {
        const { data: res } = await this.$http.get("device/yuliang/find/page", {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: this.queryParams.projectCode
          }
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.deviceData = [];
            return;
          }
          this.deviceData = res.data.list;
          this.loadDeviceStatisData(this.deviceData[0]);
        } else {
          this.$message.error(res.msg);
        }
      },
      loadDeviceStatisData (data) {
        this.currentSelectNode = data;
        this.loadData();
      },
      async loadData () {
        const { data: res } = await this.$http.get("device/yuliang/statis/data", {
          params: {
            deviceCode: this.currentSelectNode.deviceCode,
            dateType: this.statisDate
          }
        });
        if (res.code == 0) {
          if (res.data == null) {
            this.statisDatas = [];
            return;
          }
          this.statisDatas = res.data;
          this.drawData(res.data);
        } else {
          this.$message.error(res.msg);
        }
      },
      drawData (dataList) {
        // 基于准备好的dom，初始化echarts实例
        if (this.diagramChart == null) {
          this.diagramChart = this.$echarts.init(
            document.getElementById("ylChartsDay")
          );
        }
  
        if (dataList == null || dataList.length == 0) {
          this.diagramChart.clear();
          return;
        }
  
        // 无限接近0的值
        let valueData = [];
        let timeData = [];
        let devName = this.currentSelectNode.name;
        let devTime = "";
        for (let i = 0; i < dataList.length; i++) {
          let v = this.common.floatFormat(dataList[i].totalValue, 4);
          if (v == 0) {
            // 过滤0的值
            continue;
          } else {
            valueData.push(v);
          }
  
          timeData.push(dataList[i].timeText);
          devTime = dataList[i].timeText;
        }
  
        // Y轴类型
        let yAxisType = 'value';
        let option = {
          title: {
            text: "降雨量柱状图（单位：mm）",
            textStyle: {
              fontSize: 14,
            },
            subtext: "设备：" + devName,
            subtextStyle: {
              fontSize: 12,
            }
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [],
          },
          grid: {
            top: "90",
            bottom: "80",
            left: "60",
            right: "20"
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            data: timeData,
            axisLabel: {
                rotate: 45
            }
          },
          yAxis: {
            type: yAxisType,
            axisLabel: {
              formatter: '{value} mm'
            },
          },
          series: [
            {
              name: "降雨量",
              type: "bar",
              data: valueData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [
                  { type: 'average', name: '平均值' }
                ]
              }
            },
          ],
        };
  
        // 绘制图表
        // this.diagramChart.clear();
        this.diagramChart.setOption(option);
      },
    }
  }
  </script>
  
<style lang="less" scoped>
</style>