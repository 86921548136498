<template>
    <div>
      <el-row :gutter="10">
        <el-col :span="18">
          <div class="content-main">
            <v-cexie-diagram ref="cxDiagrammap"></v-cexie-diagram>
          </div>
        </el-col>
        <el-col :span="6">
          <el-row>
            <div class="dev-label">设备类型：</div>
          </el-row>
          <el-row>
            <div class="dev-value">固定式测斜仪</div>
          </el-row>
          <el-row>
            <div class="dev-label">孔号：</div>
          </el-row>
          <el-row>
            <div class="dev-value">{{deviceInfo.khName}}</div>
          </el-row>
          <el-row>
            <div class="dev-label" style="margin-top: 30px;">监测时间：</div>
          </el-row>
          <el-row>
            <div class="dev-label-s">{{deviceInfo.collectTime}}</div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-pie-chart" round @click="showMonitorData">监测详情</el-button>
            </div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-bell" round @click="showAlarmData">监测预警<span v-if="alarmNumber > 0" style="color: red;">({{alarmNumber}}条)</span></el-button>
            </div>
          </el-row>
          <el-row>
            <div class="btn-style">
              <el-button style="width: 150px;" icon="el-icon-document-copy" round @click="showHistoryData">历史数据</el-button>
            </div>
          </el-row>
        </el-col>
      </el-row>
  
      <!-- 显示实时监测 -->
      <el-dialog
        :title="monitorTitle"
        :visible.sync="showMonitorDataVisible"
        v-if="showMonitorDataVisible"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showMonitorDataVisible = false">返回数据大屏</el-button>
        <v-cexie-rt :hiddenPath="true" ref="cexieRtRef"></v-cexie-rt>
      </el-dialog>
  
  
      <!-- 显示预警信息 -->
      <el-dialog
        :title="alarmTitle"
        :visible.sync="showAlarmDataVisible"
        v-if="showAlarmDataVisible"
        width="60%"
        append-to-body>
        <div style="height: 600px; overflow-y: auto;overflow-x: hidden;">
            <v-device-alarm-info :deviceInfo="deviceInfo" :group-type="40"></v-device-alarm-info>
        </div>
      </el-dialog>
  
  
      <!-- 显示历史数据 -->
      <el-dialog
        :title="historyTitle"
        :visible.sync="showHistoryDataVisible"
        v-if="showHistoryDataVisible"
        width="100%"
        :fullscreen="true"
        append-to-body>
        <el-button style="margin-bottom: 5px; margin-top: -20px;" type="primary" round icon="el-icon-d-arrow-left" @click="showHistoryDataVisible = false">返回数据大屏</el-button>
        <v-cexie-ht :hiddenPath="true"></v-cexie-ht>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import vCexieDiagram from "../../../datacenter/realtime/CexieDiagramV2.vue";
  import vDeviceAlarmInfo from '../DeviceAlarmInfo.vue';
  import vCexieRt from '../../../datacenter/realtime/CexieRt.vue';
  import vCexieHt from '../../../datacenter/history/CexieHt.vue';
  export default {
    components: {
      vCexieDiagram,
      vDeviceAlarmInfo,
      vCexieHt,
      vCexieRt
    },
    props:['data'],
    data() {
      return {
        deviceInfo: {},
        alarmConfig: {},
        showMonitorDataVisible: false,
        showAlarmDataVisible: false,
        showHistoryDataVisible: false,
        alarmTitle: '',
        alarmNumber: 0,
        historyTitle: '',
        monitorTitle: ''
      }
    },
    created() {
      this.loadData(this.data);
    },
    watch: {
      data(value) {
        this.loadData(value);
      }
    },
    methods: {
      async loadData(data) {
        this.loadDeviceInfo(data);
        this.loadAlarmConfig(data);
      },
      async loadDeviceInfo(data) {
        const { data: res } = await this.$http.get("device/cexie/find/bycode/" + data.code);
        if (res.code == 0) {
          this.deviceInfo = res.data;
          this.loadKonghao(this.deviceInfo.konghao);
          this.getDeviceList(this.deviceInfo.konghao, this.deviceInfo.projectCode);
          this.getAlarmNumber(this.deviceInfo.konghao);
        } else {
          this.$message.error(res.msg);
        }
      },
      async loadAlarmConfig(data) {
        const { data: res } = await this.$http.get("alarm/find/config", {
          params: {
            deviceCode: data.code
          }
        });
        if (res.code == 0 && res.data != null) {
          this.alarmConfig = res.data;
        }
      },
      async getDeviceList (konghao, projectCode) {
        const { data: res } = await this.$http.get(
          "device/cexie/find/devices/bykh",
          {
            params: {
              projectCode: projectCode,
              konghao: konghao,
            },
          }
        );
        if (res.code == 0) {
          this.getProjectShuiwei(res.data, projectCode);
        } else {
          this.$message.error(res.msg);
        }
      },
      async getProjectShuiwei (deviceList, projectCode) {
        const { data: res } = await this.$http.get("device/find/shuiwei", {
          params: {
            projectCode: projectCode,
          },
        });
        if (res.code == 0) {
          this.$refs.cxDiagrammap.drawDiagram(deviceList, res.data);
        } else {
          this.$message.error(res.msg);
        }
      },
      async loadKonghao(khcode) {
        if (khcode == null || khcode == '') {
          return
        }
  
        const { data: res } = await this.$http.get("kh/find/bycode/" + khcode);
        if (res.code == 0) {
          this.deviceInfo.khName = res.data.khName;
        }
      },
      // 获取尚未处理的报警数量
        async getAlarmNumber(khcode) {
        const { data: res } = await this.$http.get("alarm/count/kh/alarm", {
            params: {
            konghao: khcode
            }
        });
        if (res.code == 0) {
            this.alarmNumber = res.data;
        }
        },
      showMonitorData() {
        this.monitorTitle = '[' + this.deviceInfo.khName + ']实时监测';
        this.showMonitorDataVisible = true;
        setTimeout(() => {
          this.$refs.cexieRtRef.defaultLoadData(this.deviceInfo);
        }, 0);
      },
      showAlarmData() {
        this.alarmTitle = '[' + this.deviceInfo.khName + ']预警信息';
        this.showAlarmDataVisible = true;
      },
      showHistoryData() {
        this.historyTitle = '[' + this.deviceInfo.khName + ']历史数据查询';
        this.showHistoryDataVisible = true;
      }
    }
  }
  </script>
  
  <style scoped>
  .content-main {
    width: 100%;
    height: 400px;
    background-color: white;
  }
  .dev-label {
    width: 100%;
    height: 25px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 25px;
    color: white;
  }
  .dev-label-s {
    width: 100%;
    height: 25px;
    font-size: 16px;
    line-height: 25px;
    color: white;
  }
  .dev-value {
    width: 100%;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    color: white;
    font-weight: bold;
  }
  .btn-style {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    text-align: center;
  }
  </style>